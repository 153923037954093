import React, { Fragment } from 'react'
import './profileConsumer.scss'

import Navbar from "../../components/newNavbar/navbar";
import ProfileExib from "../../components/profileExib/profileExib";
import CommentCard from "../../components/commentCard/commentCard";
import Loading from "../../components/loading/loading"
import { UserId } from "../../queries/player"
import { Query } from "react-apollo"
import ErrorBroundray from '../../components/errorBoundary/errorBoundary'

export default class ProfileUser extends React.Component {

    render() {
        return (
            <Fragment>
                <Navbar />
                <div className={'profileUserC col-xs-12'} style={{ maxWidth: "980px" }}>
                    <Query query={UserId} variables={{ id: window.location.pathname.split('/')[(window.location.pathname.split('/').length - 1)] }}>
                        {
                            res => {
                                if (res.loading) return <Loading />
                                if (res.error) return <p>Algo de errado aconteceu. Tente novamente.</p>

                                const player = res.data.user
                                // console.log(player)

                                let clientRate = 0

                                if (player.ranks.length) {
                                    let notes = []
                                    // console.log("cliente", res.data.allRanks)
                                    player.ranks.map(rank => (
                                        rank.typeTo === "CLIENTE" ?
                                            notes.push(rank.media) : null
                                    ))
                                    // console.log(notes)
                                    if (notes.length) clientRate = parseInt(notes.reduce((sum, num) => sum + num) / notes.length)
                                    // console.log(clientRate)
                                }

                                if (player) return <div className="row center-xs">
                                    <ErrorBroundray><ProfileExib name={player.name} imgUrl={player.photo} city={player.address} resume={player.description} rank={clientRate} /></ErrorBroundray>
                                    <div className="col-xs-12" style={{ textAlign: "left" }}>
                                        <h1>Comentários</h1>
                                    </div>
                                    <div className="col-xs-12" style={{ maxWidth: "600px" }}>
                                        {player.commentsTo ? player.commentsTo.map((com, i) => (
                                            com.typeTo === "CLIENTE" ?
                                                <ErrorBroundray><CommentCard comment={com} key={i} /></ErrorBroundray> : null
                                        )) : null}
                                    </div>
                                </div>
                            }
                        }
                    </Query>
                </div>
            </Fragment>
        )
    }
}

// export default ProfileUser
