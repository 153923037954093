import { gql } from "@apollo/client"

export const AllAnuncios = gql`
query AllAnuncios($userId: ID){
  allAnuncios(filter:{
    userId: $userId,
    # active: true
  }){
    id
    description
    skill {
      id
      name
    }
    active
    price
    files
    user{
      id
      name
      photo
      ranks {
          media
          typeTo
      }
      address {
          city
          state
      }
    }
  }
}
`;

export const AllAnunciosChip = gql`
query AllAnuncios($userId: ID){
  allAnuncios(filter:{
    userId: $userId,
    active: true
  }){
    id
    description
    skill {
      name
    }
    active
  }
}
`;


export const Destaques = gql`
{
  allUsers(filter: {
    type: PROFISSIONAL
    # ranks: {
    #   typeTo: PROFISSIONAL
    # }
    anuncios: {
      active: true
    }
  }){
    ranks{
      media
      typeTo
    }
    id
    name
    mediaRank
    photo
    address {
      city
      state
    }
    anuncios {
      id
      description
      price
      active
      files
      skill {
        name
      }
    }
  }
}
`

export const AnunciosSkill = gql`
query AnunciosSkill($skill: String){
      allAnuncios(
        filter:{
        skill: {
          name: $skill
        }
        active: true
        user: {
          status: true
        }
      }
      ){
        id
        description
        skill {
          id
          name
        }
        active
        price
        files
        user{
          id
          name
          avatar
          photo
          ranks {
              media
              typeTo
          }
          address {
              city
              state
          }
          anuncios {
            active
            skills {
              name
            }
          }
        }
      }
    }
`

export const AnunciosSkillID = gql`
query AnunciosSkillID($id: ID){
      allAnuncios(
        filter: {
        skillId: $id
        active: true
        # user: {
        #   status: true
        # }
      }){
        id
        description
        active
        price
        files
        user{
          id
          name
          photo
          ranks {
              media
              typeTo
          }
          address {
              city
              state
          }
          anuncios {
            active
            skill {
              name
            }
          }
        }
      }
    }
`

export const AnunciosSkillIDPages = gql`
query AnunciosSkillIDPages($id: ID, $page: Int){
      allAnunciosPages(
        filter: {
        skillId: $id
        active: true
        # user: {
        #   status: true
        # }
      },
      page: $page){
        page
        pages
        total
        id
        description
        active
        price
        files
        user{
          id
          name
          photo
          ranks {
              media
              typeTo
          }
          address {
              city
              state
          }
          anuncios {
            active
            skill {
              name
            }
          }
        }
      }
    }
`

export const SkillName = gql`
query SkillName($id: ID!){
  skill( id: $id ){
name
}
}
`

export const CreateAnuncio = gql`
mutation CreateAnuncio(
    $description: String,
    $skill: ID!,
    $price: String,
    $state: String,
    $city: String
     ){
       createAnuncio (
         input: {
           description: $description,
    skillId: $skill,
    price: $price
    state: $state
    city: $city
         }
  ){
  id
    user{
      id
      name
      type
    }
    description
    skill{
      name
    }
    active
    price
    files
}
}
`

export const AllAnuncioUser = gql`
query ($id: ID){
  allAnuncios(
    filter:{
    userId: $id
  }){
    id
    description
    skill {
      id
      name
    }
    active
    price
    files
    user{
      id
      name
    }
  }
}
`

export const UpdateAnuncio = gql`
mutation UpdateAnuncio(
  $id: ID!,
    $description: String,
    $price: String,
     ){
       updateAnuncio (
        id: $id,
         input: {
          description: $description,
          price: $price,
         }
  ){
  id
}
}
`

export const StatusAnuncio = gql`
mutation UpdateAnuncio($id: ID!, $active: Boolean){
  updateAnuncio (
      id: $id,
      input: {
        active: $active,
      }
  ){
    id
    active
  }
}
`

export const AllFiles = gql`
query ($id: ID){
  anuncio( id: $id ){
    id
    description
    skill {
      id
      name
    }
    active
    price
    files
  }
}
`

export const UpdateFilesAnuncio = gql`
mutation UpdateFilesAnuncio(
  $id: ID!,
    $files: [String!],
     ){
       updateAnuncio (
        id: $id,
         input: {
          files: $files,
         }
  ){
  id
}
}
`