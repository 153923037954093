import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import "./notification.scss";
import Loading from "../../../components/loading/loading";
import dayjs from "dayjs";
import {
  NotificationUser,
  ReadNotification,
} from "../../../queries/notification";
import { useQuery, useMutation } from "@apollo/client"
import { Report } from "notiflix"

export default () => {
  const cache = JSON.parse(sessionStorage.getItem("OITO"));
  const { data, refetch, loading } = useQuery(NotificationUser, {
    variables: {
      id: cache && cache.user.id,
    },
  });
  const [notificationsList, setNotificationsList] = useState();
  const [readingNotification] = useMutation(ReadNotification);
  const [dropdownNotificationActive, setDropdownProfileActive] = useState()

  useEffect(() => {
    if (!cache) {
      Report.Failure(
        "Você não está logado!",
        "Entre ou cadastre-se. É gratuito!",
        "Fechar",
        () => (window.location.href = "/login")
      );
    }
  }, []);// eslint-disable-line

  useEffect(() => {
    if (data) {
      setNotificationsList(data.user.notifications);
    }
  }, [data]);

  const clickNotification = (id, link) => {
    if (id) {
      readingNotification({
        variables: {
          id: id,
        },
      }).then(() => {
        // console.log(res)
        setDropdownProfileActive(!dropdownNotificationActive);
        refetch();
        window.location.href = link;
      });
    }
  };

  if (loading) return <Loading />

  return (
    <div className="col-xs-12" style={{ maxWidth: "680px" }}>
      <Helmet>
        <title>Oito - Notificações</title>
        <meta
          name="description"
          content="Confira e administre as notificações referentes às negociações e oportunidades em Oito.app."
        />
        <meta
          name="keywords"
          content="Notificações, Profissionais, Oportunidades"
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="Portuguese" />
        <meta name="revisit-after" content="1 days" />
      </Helmet>
      <div className="notificationList row center-xs">
        <div className="col-xs-12">
          <h1 className="title-list">Notificações</h1>
        </div>

        <section className="notification-list col-xs-12 row">
          {notificationsList && notificationsList.length ? (
            notificationsList.map((n, i) => (
                    <div key={i}
                      onClick={() => clickNotification(n.id, n.link)}
                      className={
                        n.status
                          ? "notification-card col-xs-12"
                          : "notification-card col-xs-12 read"
                      }
                    >
                      <h3>{n.title}</h3>
                      <p>{n.description}</p>
                      <div>
                        <span>{dayjs(n.createdAt).format("DD/MM/YYYY")}</span>
                      </div>
                    </div>
                  )
            )) : (
            <article className="msg-not-found">
              <h5>Você ainda não tem nenhuma notificação.</h5>
            </article>
          )}
        </section>
      </div>
    </div>
  );
};
