import React, { Component } from "react";
import './fileInput.scss'

export class FileInput extends Component {
    // constructor(props){
    //     super(props)
    // }

    render() {
        const {
            name,
            filename,
            onchange
        } = this.props;

        return (
            <label className="file">
                <input type="file" id="file" aria-label="File browser" accept="image/x-png,image/jpeg" name={name} onChange={onchange} multiple />
                <span className="file-custom">{filename}</span>
            </label>
        )
    }
}
