import { gql } from "@apollo/client"

export const CreateRank = gql`
mutation ($agilidade: Int, $atendimento: Int, $ferramentas: Int, $solucao: Int, 
$resposta: Int, $custo: Int, $pontualidade: Int, $media: Int!, $toId: ID!, $proposeId:ID!,
$rating: RATING!, $typeTo: TYPE!, $description: String!, $custoC: Int, $pontualidadeC: Int,
$respeitoC: Int, $transparenciaC: Int) {
    createRank ( input: {
      agilidade: $agilidade
      atendimento: $atendimento
      ferramentas: $ferramentas
      solucao: $solucao
      resposta: $resposta
      toId: $toId
      custo: $custo
      pontualidade: $pontualidade
      media: $media
      proposeId: $proposeId
      typeTo: $typeTo
      custoC: $custoC
      pontualidadeC: $pontualidadeC
      respeitoC: $respeitoC
      transparenciaC: $transparenciaC
    }){
      id
      media
    }

    createComment ( input: {
      description: $description
      toId: $toId
      proposeId: $proposeId
      rating: $rating
      typeTo: $typeTo
    }) {
        id
        rating
        description
    }
}`

export const RateRankClient = gql`
query RateRankClient($userId: ID ) {
    allRanks ( filter: {
      toId: $userId
      typeTo: CLIENTE
    }){
      id
      media
      custoC
      pontualidadeC
      respeitoC
      transparenciaC
    }
}`

export const RateRankPro = gql`
query RateRankPro($userId: ID! ) {
    allRanks ( filter: {
      toId: $userId
      typeTo: PROFISSIONAL
    }
    ){
      id
      media
      agilidade
      atendimento
      ferramentas
      solucao
      resposta
      custo
      pontualidade
    }
}`
