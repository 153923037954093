import { gql } from "@apollo/client"

export const Categories = gql`
query {
   allCategories(orderBy: { name: "asc" }){
    id
    name
    file
    skills {
      id
      name
    }
  }
}
`;

export const NewHab = gql`
mutation NewHab($name: String!){
  createHabilidade(input: {
    name: $name
  }){
      name
  }
}
`