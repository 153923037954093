import React, { useState, useEffect, useLayoutEffect } from 'react'
import {
    CountUsers, CountUsersStates,
    CountProposes, AnunciosAll, CountUsersSetDate, CreateSkills, newHabs, CreateCategory
} from '../../queries/dashboard'
import Navbar from '../../components/newNavbar/navbar'
import Modal from '../../components/Modal/modal'
import Chart from "react-google-charts"
import './dashboard.scss'
import * as dayjs from 'dayjs'
import Button from '../../components/newButton/button'
import Input from '../../components/newInput/input'
import Loading from '../../components/loading/loading'
import { Categories } from '../../queries/category'
import IconCtg from '../../components/iconCtg/iconCtg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FileInput } from '../../components/fileInput/fileInput'
import axios from 'axios'
import { useQuery, useMutation } from "@apollo/client"
import { Report, Notify } from "notiflix"

export default () => {
    const cache = JSON.parse(sessionStorage.getItem('OITO'))
    const [editCat, setEditCat] = useState(false)
    const { data: countUsers } = useQuery(CountUsers)
    const { data: countProposes } = useQuery(CountProposes)
    const { data: countUsersStates } = useQuery(CountUsersStates)
    const { data: anunciosAll } = useQuery(AnunciosAll)
    const { data: allHabs } = useQuery(newHabs)
    const { data: categories } = useQuery(Categories)
    // const [getUsersByDate, { data }] = useLazyQuery(CountUsersSetDate, {
    //     onCompleted: (data) => {
    //         // some actions
    //         console.log(data)
    //     }
    // })
    const [createSkill, {loading}] = useMutation(CreateSkills)
    const [totaluser, settotaluser] = useState()
    const [totalpro, settotalpro] = useState()
    const [totalcli, settotalcli] = useState()
    const [dataEstados, setdataEstados] = useState()
    const [proposett, setproposett] = useState()
    const [proposeaberto, setproposeaberto] = useState()
    const [proposeaceito, setproposeaceito] = useState()
    const [proposecalcelado, setproposecalcelado] = useState()
    const [proposefinalizado, setproposefinalizado] = useState()
    const [proposeNO, setproposeNO] = useState()
    const [proposerecusado, setproposerecusado] = useState()
    const [skillsList, setskillsList] = useState()
    const [show, setShow] = useState(false)
    const [showCat, setShowCat] = useState(false)
    const [open, setOpen] = useState(false)
    const [categoria, setCategoria] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()


    const days = [
        dayjs().subtract(7, 'day').hour(20).minute(59).toISOString(),
        dayjs().subtract(6, 'day').hour(20).minute(59).toISOString(),
        dayjs().subtract(5, 'day').hour(20).minute(59).toISOString(),
        dayjs().subtract(4, 'day').hour(20).minute(59).toISOString(),
        dayjs().subtract(3, 'day').hour(20).minute(59).toISOString(),
        dayjs().subtract(2, 'day').hour(20).minute(59).toISOString(),
        dayjs().subtract(1, 'day').hour(20).minute(59).toISOString(),
        dayjs().hour(20).minute(59).toISOString(),
    ]

    useLayoutEffect(() => {
        if (countUsers) {
            settotaluser(countUsers.total.count)
            settotalpro(countUsers.profissional.count)
            settotalcli(countUsers.cliente.count)
        }
    }, [countUsers])

    useEffect(() => {
        if (cache && cache.user) {
            console.log(cache.user.role)
            if (cache.user.role === "USER") {
                window.location.href = "/perfil"
            } else if (cache.user.role === "ADMINA" && !editCat) {
                setEditCat(true)
            }
        } else {
            return Report.Failure('Você não está logado!', 'Entre ou cadastre-se. É gratuito!', 'Fechar', () => window.location.href = '/login')
        }
    }, [])

    useLayoutEffect(() => {
        if (countProposes) {
            setproposett(countProposes.total.count)
            setproposeaberto(countProposes.aberto.count)
            setproposeaceito(countProposes.aceito.count)
            setproposecalcelado(countProposes.cancelado.count)
            setproposefinalizado(countProposes.finalizado.count)
            setproposeNO(countProposes.naorealizado.count)
            setproposerecusado(countProposes.recusado.count)
        }
    }, [countProposes])

    useLayoutEffect(() => {
        if (countUsersStates) {
            setdataEstados([
                ['Estados', 'Usuarios total', 'Profissionais', 'Clientes'],
                ['Acre (AC)', countUsersStates.ACtotal.count, countUsersStates.ACprofissional.count, countUsersStates.ACcliente.count],
                ['Alagoas (AL)', countUsersStates.ALtotal.count, countUsersStates.ALprofissional.count, countUsersStates.ALcliente.count],
                ['Amapá (AP)', countUsersStates.APtotal.count, countUsersStates.APprofissional.count, countUsersStates.APcliente.count],
                ['Amazonas (AM)', countUsersStates.AMtotal.count, countUsersStates.AMprofissional.count, countUsersStates.AMcliente.count],
                ['Bahia (BA)', countUsersStates.BAtotal.count, countUsersStates.BAprofissional.count, countUsersStates.BAcliente.count],
                ['Ceará (CE)', countUsersStates.CEtotal.count, countUsersStates.CEprofissional.count, countUsersStates.CEcliente.count],
                ['Distrito Federal (DF)', countUsersStates.DFtotal.count, countUsersStates.DFprofissional.count, countUsersStates.DFcliente.count],
                ['Espírito Santo (ES)', countUsersStates.EStotal.count, countUsersStates.ESprofissional.count, countUsersStates.EScliente.count],
                ['Goiás (GO)', countUsersStates.GOtotal.count, countUsersStates.GOprofissional.count, countUsersStates.GOcliente.count],
                ['Maranhão (MA)', countUsersStates.MAtotal.count, countUsersStates.MAprofissional.count, countUsersStates.MAcliente.count],
                ['Mato Grosso (MT)', countUsersStates.MTtotal.count, countUsersStates.MTprofissional.count, countUsersStates.MTcliente.count],
                ['Mato Grosso do Sul (MS)', countUsersStates.MStotal.count, countUsersStates.MSprofissional.count, countUsersStates.MScliente.count],
                ['Minas Gerais (MG)', countUsersStates.MGtotal.count, countUsersStates.MGprofissional.count, countUsersStates.MGcliente.count],
                ['Pará (PA)', countUsersStates.PAtotal.count, countUsersStates.PAprofissional.count, countUsersStates.PAcliente.count],
                ['Paraíba (PB)', countUsersStates.PBtotal.count, countUsersStates.PBprofissional.count, countUsersStates.PBcliente.count],
                ['Paraná (PR)', countUsersStates.PRtotal.count, countUsersStates.PRprofissional.count, countUsersStates.PRcliente.count],
                ['Pernambuco (PE)', countUsersStates.PEtotal.count, countUsersStates.PEprofissional.count, countUsersStates.PEcliente.count],
                ['Piauí (PI)', countUsersStates.PItotal.count, countUsersStates.PIprofissional.count, countUsersStates.PIcliente.count],
                ['Rio de Janeiro (RJ)', countUsersStates.RJtotal.count, countUsersStates.RJprofissional.count, countUsersStates.RJcliente.count],
                ['Rio Grande do Norte (RN)', countUsersStates.RNtotal.count, countUsersStates.RNprofissional.count, countUsersStates.RNcliente.count],
                ['Rio Grande do Sul (RS)', countUsersStates.RStotal.count, countUsersStates.RSprofissional.count, countUsersStates.RScliente.count],
                ['Rondônia (RO)', countUsersStates.ROtotal.count, countUsersStates.ROprofissional.count, countUsersStates.ROcliente.count],
                ['Roraima (RR)', countUsersStates.RRtotal.count, countUsersStates.RRprofissional.count, countUsersStates.RRcliente.count],
                ['Santa Catarina (SC)', countUsersStates.SCtotal.count, countUsersStates.SCprofissional.count, countUsersStates.SCcliente.count],
                ['São Paulo (SP)', countUsersStates.SPtotal.count, countUsersStates.SPprofissional.count, countUsersStates.SPcliente.count],
                ['Sergipe (SE)', countUsersStates.SEtotal.count, countUsersStates.SEprofissional.count, countUsersStates.SEcliente.count],
                ['Tocantins (TO)', countUsersStates.TOtotal.count, countUsersStates.TOprofissional.count, countUsersStates.TOcliente.count],
                ['Cadastro incompleto', countUsersStates.NULLtotal.count, countUsersStates.NULLprofissional.count, countUsersStates.NULLcliente.count],
            ])
        }
    }, [countUsersStates])

    useLayoutEffect(() => {
        if (anunciosAll) {

            let list = [['Habilidade', 'Anuncios', 'Propostas']]

            if (anunciosAll && anunciosAll.allSkills) {
                anunciosAll.allSkills.map(skill =>
                    list = [
                        ...new Set([...list, [
                            skill.name,
                            skill.anunciosMeta.count,
                            skill.anuncios.reduce((prev, elem) => prev + elem.proposesMeta.count, 0)]
                        ])
                    ])
                setskillsList(list)
            }
        }
    }, [anunciosAll])



    //     dayData: [["Dia", "Total de usuários", "Cadastros de profissionais"]],
    //     loading: false,
    //     open: false,
    //     showCat: false,
    //     dataEstados: []


    // useLayoutEffect(() => {
    //     days.map(day => {
    //         let dayData = []

    //         getUsersByDate({
    //             variables: {
    //                 date: day
    //             }
    //         })

    //         // console.log(data)
    //         // .then(res => {
    //         //         dayData = [
    //         //             ...new Set([...dayData, [dayjs(day).format("DD/MM/YYYY"), parseInt(res.total.count), parseInt(res.profissionais.count)]])
    //         //         ]
    //         // })

    //         // console.log(dayData)
    //     })
    // }, [])

    const saveSkill = (e) => {
        e.preventDefault()

        const name = e.target.elements.name.value

        if (categoria) {
            createSkill({
                variables: {
                    name: name,
                    category: categoria
                }
            }).then(
                res => {
                    console.log(res)
                    setShow(false)
                    Notify.Success('Nova habilidade cadastrada com sucesso!')
                }
            ).catch(
                err => {
                    console.log(err)
                    setShow(false)
                    Notify.Failure("Algo de errado aconteceu. Tente novamente mais tarde.")
                }
            )
        } else {
            Notify.Failure("Selecione a categoria")
        }

    }

    const createCategory = async (e) => {
        e.preventDefault()

        // CreateCategory({
        //     variables: {
        //         name: this.state.nameCat,
        //         description: this.state.descriptionCat
        //     }
        // }).then(async res => {

        //     if (res.error) {
        //         //mensagem de erro
        //         this.setState({
        //             showAlert: true,
        //             showCat: false,
        //             loading: false,
        //             typeAlert: 'error',
        //             titleAlert: 'Aconteceu algum erro',
        //             messageAlert: `Informe esta menssagem ao desenvolvedor: ${res.erro.message}`,
        //             onClose: () => window.location.reload()
        //         })
        //     }

        //     if (res.data.createCategory) {
        //         //salvar imagem

        //         if (this.state.file) {

        //             const files = this.state.file

        //             // console.log(files)

        //             const file = files[0]

        //             this.setState({ fileName: file.name })


        //             const cache = JSON.parse(sessionStorage.getItem('OITO'))

        //             let data = new FormData();
        //             data.append('data', file)

        //             await axios.post('https://api.graph.cool/file/v1/oitoapi', data, {
        //                 headers: {
        //                     'Content-Type': 'multipart/form-data',
        //                     'Authorization': `Bearer ${cache.token}`
        //                 },
        //                 onUploadProgress: ProgressEvent => {
        //                     this.setState({
        //                         now: parseInt(ProgressEvent.loaded / ProgressEvent.total * 100),
        //                     })
        //                     // console.log("arquivo ", ProgressEvent.loaded / ProgressEvent.total * 100)
        //                 }
        //             }).then(resp => {
        //                 // console.log(resp.data.id)

        //                 const client = new GraphQLClient(endpoint, {
        //                     headers: {
        //                         Authorization: `Bearer ${cache.token}`,
        //                     }
        //                 })

        //                 client.request(`
        //                         mutation{
        //                             updateFile (
        //                            id: "${resp.data.id}",
        //                            categoryId: "${res.data.createCategory.id}"
        //                        ){
        //                        id
        //                          name
        //                      }
        //                      }
        //                         `).then(
        //                     resp2 => {
        //                         console.log(resp2)
        //                         this.setState({
        //                             showAlert: true,
        //                             showCat: false,
        //                             loading: false,
        //                             typeAlert: 'success',
        //                             titleAlert: `Nova categoria cadastrada com sucesso!`,
        //                             messageAlert: `${res.data.createCategory.name} foi criada.`,
        //                             onClose: () => window.location.reload()
        //                         })
        //                     }
        //                 ).catch(err => {
        //                     console.log(err)
        //                     this.setState({
        //                         showAlert: true,
        //                         showCat: false,
        //                         loading: false,
        //                         typeAlert: 'error',
        //                         titleAlert: 'Aconteceu algum erro',
        //                         messageAlert: `Informe esta menssagem ao desenvolvedor: ${err.message}`,
        //                         onClose: () => window.location.reload()
        //                     })
        //                     // this.refetch()
        //                     // this.setState({ loading: false, fileName: 'Escolha um arquivo' })
        //                 })
        //             })

        //             this.setState({ loadingprogress: false, fileName: 'Escolha um arquivo' })

        //         } else {
        //             this.setState({ loadingprogress: false, fileName: 'Escolha um arquivo' })
        //         }

        //         //update da categoria
        //         // client.request(``)
        //     }

        // })
    }

    if (loading) return <Loading />

    return (
        <div className="col-xs-12" id="paineladm">
            <Navbar />

            <div className="row">

                {/* menu */}
                <div className="col-xs-12" id="menu">
                    <div style={!show ? { textDecoration: "underline" } : null}>Dashboard</div>
                    {editCat && <><div onClick={() => setShow(!show)}>Cadastro de habilidades</div>
                        <div onClick={() => setShowCat(!showCat)}>Cadastro de categoria</div></>}
                </div>

                {/* graficos */}
                <div className="col-xs-12" id="graficos">

                    {window.innerWidth > 800 ? <>
                        {/* {this.state.dayData.length > 8 ?
                                <Chart
                                    width={'80vw'}
                                    height={'80vh'}
                                    // chartType="LineChart"
                                    chartType="Bar"
                                    loader={<div>Carregando grafico</div>}
                                    data={this.state.dayData}
                                    options={{
                                        chart: {
                                            title: `Total de Usuários ${this.state.totaluser}. ${parseFloat((this.state.totalpro * 100 / this.state.totaluser).toFixed(2))}% profissionais e ${parseFloat((this.state.totalcli * 100 / this.state.totaluser).toFixed(2))}% de clientes`,
                                        },
                                    }}
                                    // For tests
                                    rootProps={{ 'data-testid': '6' }}
                                /> : <div>Carregando grafico</div>} */}

                        {totaluser ? <Chart
                            width={'25vw'}
                            height={'200px'}
                            chartType="PieChart"
                            loader={<div>Carregando grafico</div>}
                            data={[
                                ['Usuarios', 'Quantidade'],
                                ['Profissional', totalpro],
                                ['Cliente', totalcli],
                            ]}
                            options={{
                                title: `Total de usuarios ${totaluser}`,
                            }}
                            rootProps={{ 'data-testid': '1' }}
                        /> : <div>Carregando grafico</div>}

                        <Chart
                            width={'80vw'}
                            height={'400px'}
                            chartType="Bar"
                            loader={<div>Carregando grafico</div>}
                            data={[
                                ['Status', 'Propostas'],
                                ['Em aberto', proposeaberto],
                                ['Aceitas', proposeaceito],
                                ['Canceladas', proposecalcelado],
                                ['Finalizadas', proposefinalizado],
                                ['Não respondidas', proposeNO],
                                ['Recusadas', proposerecusado],
                            ]}
                            options={{
                                chart: {
                                    title: `Total de propostas ${proposett}`,
                                },
                                chartArea: { width: '50%' },
                            }}
                            rootProps={{ 'data-testid': '2' }}
                        />

                        {dataEstados && dataEstados.length > 1 ? <Chart
                            width={'80vw'}
                            height={'1500px'}
                            chartType="Bar"
                            loader={<div>Carregando grafico</div>}
                            data={dataEstados}
                            options={{
                                chart: {
                                    title: 'Usuarios por estado',
                                    subtitle: 'Valido somente para quem finalizou o cadastro',
                                },
                                chartArea: { width: '50%' },
                                bars: 'horizontal',
                                axes: {
                                    y: {
                                        0: { side: 'right' },
                                    },
                                },
                            }}
                            rootProps={{ 'data-testid': '3' }}
                        /> : <div>Carregando grafico</div>}

                        {skillsList && skillsList.length > 1 ?
                            <Chart
                                width={'80vw'}
                                height={'2600px'}
                                chartType="Bar"
                                loader={<div>Carregando grafico</div>}
                                data={skillsList}
                                options={{
                                    chart: {
                                        title: `Anuncios por habilidade Total: ${skillsList.reduce((prev, elem) => prev + (typeof elem[1] !== 'string' ? elem[1] : null), 0)}`,
                                    },
                                    bars: 'horizontal',
                                    axes: {
                                        y: {
                                            0: { side: 'right' },
                                        },
                                    },
                                }}
                                rootProps={{ 'data-testid': '3' }}
                            />
                            : <div>Carregando grafico</div>}
                    </>
                        : <>
                            {/* //tabelas em telas pequenas */}
                            <table>
                                <thead>
                                    <tr>
                                        <th colSpan="3">Usuários</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Total</th>
                                        <td colSpan="2">{this.state.totaluser}</td>
                                    </tr>
                                    <tr>
                                        <th>Profissionais</th>
                                        <td colSpan="2">{this.state.totalpro} - {parseFloat((this.state.totalpro * 100 / this.state.totaluser).toFixed(2))}%</td>
                                    </tr>
                                    <tr>
                                        <th>Clientes</th>
                                        <td colSpan="2">{this.state.totalcli} - {parseFloat((this.state.totalcli * 100 / this.state.totaluser).toFixed(2))}%</td>
                                    </tr>
                                    {this.state.dayData.map((day, i) =>
                                        <tr key={i}>
                                            <th>{day[0]}</th>
                                            <td>{day[1]}</td>
                                            <td>{day[2]}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <table>
                                <thead>
                                    <tr>
                                        <th colSpan="2">Propostas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Total</th>
                                        <td>{this.state.proposett}</td>
                                    </tr>
                                    <tr>
                                        <th>Em aberto</th>
                                        <td>{this.state.proposeaberto}</td>
                                    </tr>
                                    <tr>
                                        <th>Aceitas</th>
                                        <td>{this.state.proposeaceito}</td>
                                    </tr>
                                    <tr>
                                        <th>Canceladas</th>
                                        <td>{this.state.proposecalcelado}</td>
                                    </tr>
                                    <tr>
                                        <th>Finalizadas</th>
                                        <td>{this.state.proposefinalizado}</td>
                                    </tr>
                                    <tr>
                                        <th>Não respondidas</th>
                                        <td>{this.state.proposeNO}</td>
                                    </tr>
                                    <tr>
                                        <th>Recusadas</th>
                                        <td>{this.state.proposerecusado}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table>
                                <thead>
                                    <tr>
                                        <th colSpan="4">Usuários por estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.dataEstados.length > 0 &&
                                        this.state.dataEstados.map((state, i) =>
                                            <tr key={i}>
                                                <th>{state[0]}</th>
                                                <td>{state[1]}</td>
                                                <td>{state[2]}</td>
                                                <td>{state[3]}</td>
                                            </tr>
                                        )}
                                </tbody>

                            </table>
                            <table>
                                <thead>
                                    <tr>
                                        <th colSpan="3">Anúncios</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {skillsList && skillsList.length > 1 && <>
                                        <tr>
                                            <th>Total</th>
                                            <td colSpan="2">{skillsList.reduce((prev, elem) => prev + (typeof elem[1] !== 'string' ? elem[1] : null), 0)}</td>
                                        </tr>
                                        {skillsList.map((skill, i) =>
                                            <tr key={i}>
                                                <th>{skill[0]}</th>
                                                <td>{skill[1]}</td>
                                                <td>{skill[2]}</td>
                                            </tr>
                                        )}
                                    </>}
                                </tbody>
                            </table>
                        </>}
                </div>
            </div>


            <Modal show={show} title={`Cadastro de habilidade`} onClose={() => setShow(!show)} >

                <form className="row start-xs" onSubmit={(e) => saveSkill(e)}>

                    <div className="col-xs-12">
                        <Input name="name" label="Nome da habilidade" placeholder="Digite o nome da habilidade" type="text" />
                    </div>

                    <div className="col-xs-12 center-xs catg">
                        {categories && categories.allCategories.length &&
                            categories.allCategories.map((p, i) =>
                                // style={this.state.category === p.id ? {color: 'red'} : null}
                                <IconCtg key={i} icon={p.file} label={true} name={p.name} small={true} active={categoria === p.id} onClick={() => setCategoria(p.id)} />
                            )}
                    </div>

                    <div className="col-xs-12 end-xs" style={{ marginBottom: "30px" }}>
                        <div onClick={() => setOpen(!open)}>Sugestões de habilidades <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} size={'1x'} /></div>
                        <div style={open ? { display: 'block' } : { display: "none" }}>
                            {allHabs && allHabs.allHabilidades &&
                                allHabs.allHabilidades.map((hab, i) =>
                                    <div key={i} style={{ borderBottom: "1px solid lightgrey" }}>{hab.name}</div>
                                )}
                        </div>
                    </div>
                    <Button name='Salvar' type='submit' />
                </form>
            </Modal>

            <Modal show={showCat} title={`Cadastro de categoria`} onClose={() => setShowCat(!showCat)} onSubmit={(e) => createCategory(e)} >

                        <form className="row start-xs">

                            <div className="col-xs-12">
                                <Input name="name" label="Nome da categoria" placeholder="Digite o nome da categoria" type="text" />
                            </div>

                            <div className="col-xs-12">
                                <Input name="description" label="Descrição da categoria" placeholder="Digite uma descrição para a categoria" type="text" />
                            </div>


                            <div className="col-xs-12 end-xs" style={{ marginBottom: "30px" }}>
                                <p><small>Para que todas as imagens fiquem propocionais favor seguir estes requisitos: - Imagem em SVG. - Documento transparente com tamanho de 989px de largura por 1149px de altura contendo o - Vetor na cor roxa #4d2565ff no tamanho de 908x908px centralizado no documento.</small></p>
                                {/* <FileInput name={'fileInputs'} onChange={(e) => this.setState({ file: e.target.files })} accept="image/*" id="uploadPortfolio" filename={fileName} /> */}
                            </div>

                            <Button name='Salvar' type='submit' />
                        </form>
                </Modal>

        </div>
    )
}