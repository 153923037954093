/* eslint-disable react/display-name */
import React from 'react'
import { Switch, Route } from 'react-router'
// import { PrivateRoute } from './private-route'

import Home from '../pages/home/home'
import Terms from '../pages/terms/terms'
import Faq from '../pages/faqs/faqs'
import Login from '../pages/login/login'
import Contact from '../pages/contact/contact'
// import NotFound from '../pages/404'
import Announcement from '../pages/announcement/announcement'
import AnnouncementService from '../pages/announcementService/announcementService'
import AboutUs from '../pages/aboutUs/aboutUs'
import Register from '../pages/register/register'
import ProfileUser from '../pages/profileUser/profileUser'
import ProfileConsumer from '../pages/profileConsumer/profileConsumer'
import ProposeDetails from '../pages/profileUserPages/proposeDetail/proposeDetails'
import Avaliacao from '../pages/profileUserPages/avaliationPropose/avaliantionPropose'
import Notifications from '../pages/profileUserPages/notification/notifications'
import Dashboard from '../pages/profileUserPages/dashboard/dashboard'
import Data from '../pages/profileUserPages/dataUser/dataUser'
import Received from '../pages/profileUserPages/recevedProposes/recevedProposes'
import Send from '../pages/profileUserPages/sendPropose/sendProposes'
import Rank from '../pages/profileUserPages/rankUser/rankUser'
import PhoneVerify from '../pages/phoneverify'
import Profile from '../pages/profileSeller/profileSeller'
import Blog from '../pages/blog/blogList'
import Post from '../pages/blog/blogPost'
import Cadastro2 from '../pages/register/completeRegister'
import DashboardADM from '../pages/dashboard/dashboard'
import RegisterAnnouncement from '../pages/profileUserPages/registerAnnouncement/registerAnnouncement'
import AnnouncementSearch from '../pages/announcementSearch/announcementSearch'
// import Sms from '../smssender'

export default () =>
    <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/termosUso' component={Terms} />
        <Route exact path='/faq' component={Faq} />
        <Route exact path='/faleConosco' component={Contact} />
        <Route exact path='/quemSomos' component={AboutUs} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/anuncios' component={Announcement} />
        <Route exact path='/cadastro' component={Register} />
        <Route exact path='/blog' component={Blog} />
        <Route exact path='/painel' component={DashboardADM} />
        <Route exact path='/post/:id' component={Post} />
        <Route exact path='/perfil' render={() => <ProfileUser childrencomponent={<Dashboard />} />} />
        <Route exact path='/perfil/send' render={() => <ProfileUser childrencomponent={<Send />} />} />
        <Route exact path='/perfil/rank' render={() => <ProfileUser childrencomponent={<Rank />} />} />
        <Route exact path='/perfil/received' render={() => <ProfileUser childrencomponent={<Received />} />} />
        <Route exact path='/perfil/proposedetails/:proposeId' render={() => <ProfileUser childrencomponent={<ProposeDetails />} />} />
        <Route exact path='/perfil/avaliacao/:proposeId' render={() => <ProfileUser childrencomponent={<Avaliacao />} />} />
        <Route exact path='/perfil/notifications' render={() => <ProfileUser childrencomponent={<Notifications />} />} />
        <Route exact path='/perfil/data' render={() => <ProfileUser childrencomponent={<Data />} />} />
        <Route exact path='/perfil/completarcadastro' render={() => <ProfileUser childrencomponent={<Cadastro2 />} />} />
        <Route exact path='/profile/:playerId' component={Profile} />
        <Route exact path='/profileClient/:playerId' component={ProfileConsumer} />
        <Route exact path='/verificartelefone' component={PhoneVerify} />
        <Route exact path='/perfil/meuanuncios' render={() => <ProfileUser childrencomponent={<RegisterAnnouncement />} />} />
        <Route exact path='/anuncios/:skill' component={AnnouncementService} />
        <Route exact path='/anuncios/busca/:query' component={AnnouncementSearch} />

        {/* <Route exact path='/sms' component={Sms} /> */}
    </Switch>
