import React, { useEffect, useLayoutEffect } from 'react'
import './chatWindow.scss'
// import { ButtonBack } from '../../../components/newButton/newButton'
import Loading from '../loading/loading'
import ElementLoading from "../elementLoading/elementLoading"
import * as emailjs from 'emailjs-com'
import { CreateChat, AllChats, ReadMessage } from '../../queries/chat'
import { CreateNotification } from "../../queries/notification"
import { websocket } from '../../queries/endpoint'
import ScrollToBottom from 'react-scroll-to-bottom'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useMutation, useQuery } from "@apollo/client"
import { Notify } from "notiflix"
import InputEmoji from "react-input-emoji"

export default (props) => {
    const { propose, blocked, callbackFunctions, otherUser, player } = props
    const { data: listChats, loading, refetch } = useQuery(AllChats, {
        variables: {
            id: propose.id
        }
    })
    const [readMessage] = useMutation(ReadMessage)
    const [createChat, { loading: loadingSendMsg, data: responseSendMsg, error: errSendMsg }] = useMutation(CreateChat)
    const [newNotification] = useMutation(CreateNotification)
    let value = ''

    useEffect(() => {

        //     const webSocket = new WebSocket(websocket, 'graphql-subscriptions')
        //     webSocket.onmessage = (event) => {
        //         const data = JSON.parse(event.data)
        //         console.log("componentWillUpdate", event.data)
        //         switch (data.type) {
        //             case 'init_success': {
        //                 console.log('init_success, the handshake is complete')
        //                 break
        //             }
        //             case 'init_fail': {
        //                 console.log('init_fail returned from WebSocket server:', event)
        //                 break
        //             }
        //             case 'subscription_data': {
        //                 console.log('subscription data has been received', data)
        //                 var allChats = this.state.chatList
        //                 allChats.push(data.payload.data.Chat.node)
        //                 this.setState({ chatList: allChats })
        //                 // if (allChats !== undefined) this.readMessage(allChats)
        //                 // setTimeout(this.readMessage(allChats), 5000)
        //                 break
        //             }
        //             case 'subscription_success': {
        //                 console.log('subscription_success')
        //                 break
        //             }
        //             case 'subscription_fail': {
        //                 console.log('subscription_fail returned from WebSocket server')
        //                 break
        //             }
        //             case 'error': {
        //                 console.log("ERROOO: ", data)
        //                 break
        //             }
        //             default:
        //                 console.log(data.type)
        //                 break
        //         }
        //     }
    }, [])// eslint-disable-line


    const sendEnter = (e) => {

        e.preventDefault()

        sendMenssage(value)

        value = ""
    }

    const sendMenssage = (e) => {

        if (e) {

            createChat({
                variables: {
                    description: e,
                    proposeId: propose.id,
                    status: false,
                    toId: otherUser.id,
                }
            })

            if (errSendMsg) {
                console.log(errSendMsg)
                Notify.Failure("Erro ao enviar a mensagem. Tente mais tarde.")
            }

        } else {
            Notify.Warning("Não é possóvel enviar uma mensagem vazia.")
        }
    }

    const readingMessage = async () =>
        await listChats.allChats.filter(message =>
            !message.status && message.to.id === player.id
        ).map(m =>
            readMessage({
                variables: {
                    id: m.id
                }
            })
        )

    const MessageItem = ({ message }) =>
        (message && message.from.id !== player.id) ?
            <div className="msgReceived col-xs-10">
                <p>{message.description}
                    <span>{dayjs(message.date).format("DD/MM hh:mm")}</span>
                </p>
            </div> :
            <div className="msgSend col-xs-10 col-xs-offset-2">
                <div>{message.description}
                    <span>{dayjs(message.date).format("DD/MM hh:mm")}
                        <FontAwesomeIcon icon={faCheck} style={{ color: message.status ? "#8C71D9" : "#ffffff" }} />
                    </span>
                </div>
            </div>

    const MessageListView = () => {
        if (listChats.allChats.length) {
            readingMessage()
            return <ScrollToBottom className="mensages col-xs-12" id="msgBox">
                {listChats.allChats.map((message, i) => <MessageItem key={i} message={message} />)}
            </ScrollToBottom>
        }
    }

    useLayoutEffect(() => {
        let resposta = []

        if (listChats && listChats.allChats) {
            for (let i = 0; i < listChats.allChats.length; i++) {
                if (i === 0 && listChats.allChats[i].to.id === player.id) {
                    const date1 = dayjs(listChats.allChats[i].date)
                    const date2 = dayjs(propose.date)
                    resposta.push(date1.diff(date2, "minute"))
                } else if (listChats.allChats[i].to.id === player.id) {
                    const date1 = dayjs(listChats.allChats[i].date)
                    const date2 = dayjs(listChats.allChats[i - 1].date)
                    resposta.push(date1.diff(date2, "minute"))
                }
            }

            let md = 0
            let media = 0
            if (resposta !== [] && resposta.length > 0) {
                resposta.map(r =>
                    md = md + r)
                media = md / resposta.length
            }

            callbackFunctions(media)
        }

    }, [])// eslint-disable-line

    useLayoutEffect(() => {

        if (responseSendMsg) {

            refetch()

            newNotification({
                variables: {
                    userId: otherUser.id,
                    title: "Mensagem recebida!",
                    description: `Você recebeu uma nova mensagem da proposta ${propose.anuncio.skill.name}.`,
                    link: `/perfil/proposedetails/${propose.id}`,
                }
            })

            var template_params2 = {
                "email": otherUser.email,
                "title": "Mensagem recebida!",
                "name": otherUser.name,
                "message": `Você recebeu uma mensagem! Acesse a proposta ${propose.anuncio.skill.name} no seu perfil na Oito e responda assim que possível.`
            }

            emailjs.send('noreply', 'default', template_params2, "user_IagwdTtEr3AVRdLhxkyKc")
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text)
                }, function (error) {
                    console.log('FAILED...', error)
                });

            console.log('mensagem enviada')
        }
    }, [responseSendMsg])// eslint-disable-line

    if (loading) return <Loading />

    return (

        <div className="chat-box row">

            {loadingSendMsg ? <ElementLoading /> : !!listChats.allChats.length && <MessageListView />}

            <form className="inputMsg col-xs-12" onSubmit={(e) => sendEnter(e)}>

                <div onClick={(e) => e.preventDefault()}>
                    <InputEmoji
                        value={value}
                        onChange={(e) => value = e}
                        cleanOnEnter
                        onEnter={(e) => sendMenssage(e)}
                        placeholder="Digite sua mensagem"
                        borderColor="none"
                    />
                </div>

                <button type='submit' disabled={blocked}>
                    <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                        <path fill="#fff" d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
                    </svg>
                </button>
            </form>

        </div>
    )
}