import React from 'react'
import './iconCtg.scss'

export default class IconCtg extends React.Component {
    render() {
        const { onClick, icon, name, label, active, small } = this.props;

        return (
            <>
            <div className="iconCtg" onClick={onClick}>
                <div className={active? "active" : icon ? "purpleBg" : "greyBg"}>
                    {icon ? <img src={icon} alt={name} className={small? "small" : null}/> : null}
                </div>
                {label ? <p>{name}</p> : null}
            </div>
            
</>
        )
    }
}
