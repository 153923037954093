import React from "react"
import './buttonText.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export default class BackButton extends React.Component {


    render() {

        return(
            <button className={'button-text'} onClick={() => window.history.go(-1)}>
                <div><FontAwesomeIcon icon={faChevronLeft} size={'1x'}/></div>
                Voltar
            </button>
        )
    }
}
