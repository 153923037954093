import React from "react"
import './avaliationStars.scss'

// import { Link } from 'react-router-dom'

import regularStar from '../../assets/icons/star-regular.svg'
import solidStar from '../../assets/icons/star-solid.svg'

export default class AvaliationStar extends React.Component {
    render() {
        const {
            avaliation
        } = this.props;
        return(
            <div className={'avaliationStars'}>
                {avaliation >= 1 ? <img src={solidStar} alt={'empty star'}/> : <img src={regularStar} alt={'empty star'}/>}
                {avaliation >= 2 ? <img src={solidStar} alt={'empty star'}/> : <img src={regularStar} alt={'empty star'}/>}
                {avaliation >= 3 ? <img src={solidStar} alt={'empty star'}/> : <img src={regularStar} alt={'empty star'}/>}
                {avaliation >= 4 ? <img src={solidStar} alt={'empty star'}/> : <img src={regularStar} alt={'empty star'}/>}
                {avaliation >= 5 ? <img src={solidStar} alt={'empty star'}/> : <img src={regularStar} alt={'empty star'}/>}
            </div>
        )
    }
}
