import React from "react"

import './textInfos.scss'

export default class TextInfos extends React.Component {
    render() {
        const {
            icon,
            title,
            text,
            right
        } = this.props;
        return (
            <div className={right ? 'textInfos col-xs-12 row right middle-xs' : 'textInfos col-xs-12 row middle-xs'}>
                {right ? <>
                    <div className="col-sm-8 col-xs-12">
                        <h1>{title}</h1>
                        <p>{text}</p>
                    </div>
                    <div className="col-sm-4 col-xs-12 first-xs last-sm last-md last-lg">
                        <img src={icon} alt='icone' />
                    </div>
                </>
                    :
                    <>
                        <div className="col-sm-4 col-xs-12">
                            <img src={icon} alt='icone' />
                        </div>
                        <div className="col-sm-8 col-xs-12">
                            <h1>{title}</h1>
                            <p>{text}</p>
                        </div>
                    </>}
            </div>
        )
    }
}
