import React, { Fragment } from "react";
import "./contact.scss";

import { Link } from "react-router-dom";

import Navbar from "../../components/newNavbar/navbar";
import Button from "../../components/newButton/button";
import ButtonText from "../../components/buttonText/buttonText";
import Loading from "../../components/loading/loading";
import Input from "../../components/newInput/input";
import TextInput from "../../components/textInput/textInput";
import Footer from "../../components/newFooter/footer";
import * as emailjs from "emailjs-com";
import { toast } from "react-toastify";
import ErrorBroundray from "../../components/errorBoundary/errorBoundary";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);

    const cache = JSON.parse(sessionStorage.getItem("OITO"));

    this.state = {
      value: "",
      default: "",
      showAlert: false,
      typeAlert: "",
      titleAlert: "",
      messageAlert: "",
      loading: false,
      name: cache ? (cache.user.name ? cache.user.name : "") : "",
      email: cache
        ? cache.user.email
          ? cache.user.email
          : ""
        : "",
      phone: cache
        ? cache.user.phone
          ? cache.user.phone
          : ""
        : "",
      mensage: "",
    };
  }

  handleChange = (event) => {
    const name = event.target.name,
      value = event.target.value;
    this.setState({ [name]: value });
  };

  validateEmail = (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  sendEmail = (e) => {
    this.setState({ showAlert: false, loading: true });
    e.preventDefault();

    let template_params = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      menssage: this.state.mensage,
    };

    if (
      (this.state.name === "" ||
        this.state.email === "" ||
        this.state.phone === "" ||
        this.state.mensage === "") &&
      (this.state.name === null ||
        this.state.email === null ||
        this.state.phone === null ||
        this.state.mensage === null)
    ) {
      this.setState({ loading: false });
      return toast.error("Preencha tudo corretamente!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    if (!this.validateEmail(this.state.email)) {
      this.setState({ loading: false });
      return toast.error("Digite o e-mail corretamente!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    console.log(template_params);

    emailjs
      .send(
        "gmail",
        "faleconosco",
        template_params,
        "user_IagwdTtEr3AVRdLhxkyKc"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          toast.success("Seu e-mail foi enviado com sucesso!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: window.location.reload(),
          });
          this.setState({ loading: false });
        },
        (error) => {
          console.log("FAILED...", error);
          toast.error("Ops! Aconteceu algo de errado", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          this.setState({ loading: false });
        }
      );
  };

  render() {
    return (
      <Fragment>
        {this.state.loading ? <Loading /> : null}
        <ErrorBroundray>
          <Navbar />
        </ErrorBroundray>
        <div className={"contact"}>
          <h1>Entre em Contato</h1>
          <form onSubmit={this.sendEmail} noValidate className="col-xs-12">
            <Input
              placeholder={"Nome Completo"}
              name={"name"}
              type={"text"}
              onChange={this.handleChange}
              defaultValue={this.state.name}
              required={true}
            />
            <Input
              placeholder={"E-mail"}
              name={"email"}
              type={"email"}
              onChange={this.handleChange}
              defaultValue={this.state.email}
              required={true}
            />
            <Input
              placeholder={"Telefone"}
              name={"phone"}
              type={"text"}
              mask="(99) 99999-9999"
              defaultValue={this.state.phone}
              onChange={(e) => this.setState({ phone: e.target.value })}
              required={true}
            />
            <TextInput
              placeholder={"Mensagem"}
              name={"mensage"}
              onChange={this.handleChange}
              required={true}
            />
            
              <Button
                type="submit"
                name={"Enviar"}
                disabled={
                  this.state.email &&
                    this.state.name &&
                    this.state.phone &&
                    this.state.mensage
                    ? false
                    : true
                }
              />

            <Link to={"/"}>
              <ButtonText name={"voltar"} />
            </Link>
          </form>
        </div>
        <ErrorBroundray>
          <Footer />
        </ErrorBroundray>
      </Fragment>
    );
  }
}
