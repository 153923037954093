import React, { useState } from "react"
import './home.scss'

import Navbar from '../../components/newNavbar/navbar'
import Button from '../../components/newButton/button'
import TextInfos from '../../components/textInfos/textInfos'
import Footer from '../../components/newFooter/footer'
import { gql, useQuery } from "@apollo/client"
import { Link } from 'react-router-dom'
import Newsbar from '../../components/Newsbar/newsbar'

import Moc from '../../assets/banner-1-1-moc.png'
import Marketing from '../../assets/Negocie.svg'
import SEO from '../../assets/Procure.svg'
import Promotion from '../../assets/Cadastro.svg'
import Star from '../../assets/Trofeu.svg'
import ErrorBroundray from '../../components/errorBoundary/errorBoundary'
import ErrorScreen from '../../components/errorScreen/errorScreen'

const AllSkills = gql`{
    allSkills{
        name
        id
        category {
          id
          name
        }
      }
}`

export default () => {
    const { data, error } = useQuery(AllSkills)
    const [filter, setFilter] = useState([])

    if (error) return <ErrorScreen cod={error} />


    const filterHabs = (query) => {
        if (data) {
            const habs = data.allSkills
            let filtered = []

            if (query !== undefined && query.length > 2 && habs.length > 0) {
                if (habs.filter(hab => hab.name.toLowerCase().includes(query.toLowerCase())).length) {
                    filtered = habs.filter(hab => hab.name.toLowerCase().includes(query.toLowerCase()))
                }
                setFilter(filtered)
            } else {
                setFilter([])
            }
        }
    }

    const redirectHab = (hab) => {
        window.location.href = "/anuncios/" + hab
    }


    return (
        <>
            <ErrorBroundray><Navbar logoContrastPurple={true} /></ErrorBroundray>
            <div className='home-section-one col-xs-12'>
                <div className='head row center-xs'>
                    <div className='call-to-action col-lg-7 col-md-8 col-xs-12 row'>
                        <div className="col-xs-12">
                            <h4>Precisa de ajuda?</h4>
                            <h1>Joga na<br /><span>Oito!</span></h1>
                        </div>
                        <div className="col-xs-12">
                            <div className='search-input'>
                                <form>
                                    <div>
                                        <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                            <path fill="#5f5f5f" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                                        </svg>
                                    </div>

                                    <input placeholder='Procure um serviço' type="text" onInput={(e) => filterHabs(e.target.value)} />
                                    <button type="submit" onClick={(e) => redirectHab(filter[0], e)} >Buscar</button>
                                </form>
                                <div className={'dropdown-search col-xs-12'}>

                                    {filter.length > 0 &&
                                        <ul>
                                            {filter.map((hab, i) => (
                                                <li key={i} onClick={() => redirectHab(hab.id)} style={{ color: "black", fontSize: "18px" }}>{hab.name}</li>
                                            ))}
                                        </ul>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='img col-lg-5 col-md-4 col-xs-12'>
                        <img src={Moc} alt={'Fundo'} />
                    </div>
                </div>
            </div>
            <div className={'text-area col-xs-12'}>
                <div className={'titles'}>
                    <span>Precisando de ajuda?</span>
                    <h1>Saiba como usar a Oito</h1>
                </div>
                <div className={'cards'}>
                    <TextInfos right={false} icon={Promotion} title={'Ofereça suas habilidades'} text={'Escolha as habilidades que melhor descrevem seus talentos, crie um anúncio e seja encontrado por quem precisa dos seus serviços.'} />
                    <TextInfos right={true} icon={SEO} title={'Procure profissionais'} text={'Pesquise o profissional ou serviço que precisa e encontre perfis diversos com imagens de trabalhos anteriores, avaliações, comentários e diferentes níveis de especialidade.'} />
                    <TextInfos right={false} icon={Marketing} title={'Negocie diretamente e sem taxas'} text={'Na OITO você negocia diretamente com a pessoa e combina com ela valores, locais, tempo de trabalho, especificidades e o que mais desejar. O pagamento é feito de forma direta e de escolha das duas partes, sem que a OITO cobre qualquer quantia.'} />
                </div>
                <div className={'end-text'}>
                    <div><img alt={'Troféu'} src={Star} /></div>
                    <span><b>Venha fazer parte!</b></span>
                    <div><Link to='/cadastro'><Button name='Comece já!' /></Link></div>
                </div>
            </div>
            <ErrorBroundray><Newsbar /></ErrorBroundray>
            <ErrorBroundray><Footer /></ErrorBroundray>
        </>
    )
}