import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import Logo from '../../assets/Logo-Oito-3.png'
import './footer.scss'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSearch } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    return (
        <footer className="footer-full row start-xs">
            <div className='about'>
                <div>
                    <h3>A oito</h3>
                    <ul>
                        <NavLink exact to='/' activeClassName="active-route"><li>Home</li></NavLink>
                        <NavLink exact to='/blog' activeClassName="active-route"><li>Blog</li></NavLink>
                        <NavLink exact to='/quemSomos' activeClassName="active-route"><li>Quem Somos</li></NavLink>
                        <NavLink exact to='/termosUso' activeClassName="active-route"><li>Termos de uso e privacidade de dados</li></NavLink>
                        <NavLink exact to='/faq' activeClassName="active-route"><li>Perguntas Frequentes</li></NavLink>
                        <NavLink exact to='/faleConosco' activeClassName="active-route"><li>Fale Conosco</li></NavLink>
                    </ul>
                </div>
                <div>
                    <h3>Oportunidades</h3>
                    <ul>
                        <Link to={sessionStorage.length ? '/perfil/data' : '/login'}><li>Anuncie seu serviço</li></Link>
                        <Link to='/anuncios'><li>Contratar um profissional</li></Link>
                    </ul>
                </div>
                <div>
                    <img src={Logo} alt='Joga na Oito' />

                    <div className='social-media'>
                        <a href='https://www.instagram.com/oito.app' target='_blank' rel="noopener noreferrer" >
                            <div>
                                <svg style={{ width: '48px', height: '48px' }} viewBox="0 0 24 24">
                                    <path fill="#707070" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
                                </svg>
                            </div>
                        </a>
                        <a href='https://www.facebook.com/oitojobs/' target='_blank' rel="noopener noreferrer">
                            <div>
                                <svg style={{ width: '48px', height: '48px' }} viewBox="0 0 24 24">
                                    <path fill="#707070" d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" />
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className='copyright'>
                <p>
                    2019 © OiTO Atividades de internet S.A. - CNPJ 33.113.323/0001-40 | Rua Al. Joaquim Eugênio,
                    696, 17º andar - Jardins, São Paulo - SP
                </p>
            </div>
        </footer>
    )
}

export default Footer