import React from 'react'
import './modal.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default (props) => {

    const onClose = e => {
        props.onClose && props.onClose(e);
    };

    if (!props.show) {
        return null
    }

    return <div id="Modal">
        <div className="modal-overlay" />
        <div className="modal-content">
            <div className="modal-actions">
                <div>
                    <h3>{props.title}</h3>
                </div>
                <div>
                    {/* <i onClick={this.onClose} className="fas fa-times">X</i> */}
                    <FontAwesomeIcon icon={faTimes} onClick={(e) => onClose(e)} size={'1x'} />
                </div>
            </div>
            <div className="content">{props.children}</div>
        </div>
    </div>
}
