import React from "react"
import './avaliationOito.scss'

// import { Link } from 'react-router-dom'

import empty from '../../assets/icons/oitoempty.svg'
import red from '../../assets/icons/oitonegative.svg'
import yellow from '../../assets/icons/oitoneutral.svg'
import green from '../../assets/icons/oitopositive.svg'

export default class AvaliationOito extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            avaliation: ''
        }
    }

    chooseStar = (e) => {
        // console.log(e);
        this.setState({avaliation: e});

        const data = {
            value: e,
            name: this.props.name
        }
        this.props.callbackFunctions(data);
    };


    render() {

    //     const Oitoempty = <svg viewBox="0 0 28 28" >
    // <path fill='#d6d6d6' d="M99,93.2L99,93.2c-1-11.4-6-22-14.2-30.2c-4.6-4.6-10-8.2-16-10.7c6.5-5.5,10.3-13.6,10.3-22.2
	// C79.1,14,66.1,1,50,1C34,1,20.9,14,20.9,30.1c0,8.6,3.8,16.7,10.3,22.2c-6,2.5-11.3,6.1-16,10.7C7,71.2,2,81.9,1,93.4
	// c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,2.9,2.4,5.3,5.3,5.3c2.6,0,4.9-1.9,5.3-4.5c0,0,0,0,0,0c0-0.2,0-0.5,0.1-0.7
	// c1.8-19.6,18.3-35,38.4-35c20.1,0,36.6,15.4,38.4,35.1c0,0.1,0,0.2,0,0.4c0.3,2.6,2.6,4.7,5.3,4.7c2.9,0,5.3-2.4,5.3-5.3v-0.1
	// C99.1,93.6,99,93.4,99,93.2z M50,47.6c-10,0-18.1-8.1-18.1-18.1S40,11.4,50,11.4c10,0,18.1,8.1,18.1,18.1S60.1,47.6,50,47.6z"/>
    // </svg>


        return(
            <div className={'avaliationOito'}>
                <div style={{display: 'flex', flexWrap: 'wrap'}} onClick={() => this.chooseStar("NEGATIVE")}>
                    <div>{this.state.avaliation === "NEGATIVE" ? <img src={red} alt={'empty star'}/> : <img src={empty} alt={'empty star'}/>}</div>
                    <div><span>negativa</span></div>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap'}} onClick={() => this.chooseStar("NEUTRAL")}>
                    <div>{this.state.avaliation === "NEUTRAL" ? <img src={yellow} alt={'empty star'}/> : <img src={empty} alt={'empty star'}/>}</div>
                    <div><span>indiferente</span></div>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap'}} onClick={() => this.chooseStar("POSITIVE")}>
                    <div>{this.state.avaliation === "POSITIVE" ? <img src={green} alt={'empty star'}/> : <img src={empty} alt={'empty star'}/>}</div>
                    <div><span>positiva</span></div>
                </div>
            </div>
        )
    }
}
