import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './main/App';
import * as serviceWorker from './serviceWorker'

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { endpoint, websocket } from './queries/endpoint'
import * as Sentry from '@sentry/browser'
import 'flexboxgrid'


if (window.location.hostname !== 'localhost') Sentry.init({ dsn: "https://f892bbf9809f4472b8775a170b82652f@sentry.io/1514598" })

// Check compatibility for the browser we're running this in
// if ("serviceWorker" in navigator) {
//   console.log("encontrou o serviceworker")
//   if (navigator.serviceWorker.controller) {
//     console.log("[PWA Builder] active service worker found, no need to register");
//   } else {
//     // Register the service worker
//     navigator.serviceWorker
//       .register("./serviceWorker.js", {
//         scope: "./"
//       })
//       .then(function (reg) {
//         console.log("[PWA Builder] Service worker has been registered for scope: " + reg.scope);
//       }).catch (err => console.log("PWA Build error: ", err))
//   }
// }

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = JSON.parse(sessionStorage.getItem("OITO"))
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.token}` : "",
    }
  }
});

const httpLink = createHttpLink({
  uri: endpoint,
});


  const client = new ApolloClient({
    // networkInterface: webSocket,
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  })

  ReactDOM.render(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
    , document.getElementById('root'));

serviceWorker.register();
