import { gql } from "@apollo/client"

export const CreateChat = gql`
mutation CreateChat($description: String!, $status: Boolean, $proposeId: ID!, $toId: ID!){
  createChat(
    input: {
      description: $description,
      status: $status,
      proposeId: $proposeId,
      toId: $toId,
    }
  ){
    id
    description
    createdAt
    status
    from{
        id
    }
    to{
        id
    }
    propose{
        id
    }
  }
}
`

export const SubscriptionChat = gql`
subscription {
         Chat(filter: {
           mutation_in: [CREATED, UPDATED]
         }){
           mutation
           updatedFields
           node {
             id
             description
             createdAt
             status
             from {
               id
             }
             propose {
               id
             }
           }
         }
       }
`

export const AllChats = gql`
query AllChats($id: ID){
  allChats (filter:{
    proposeId: $id
  }){
    id
    description
    createdAt
    status
    from {
      id
      name
    }
    to {
      id
      name
    }
    propose {
      id
      createdAt
    }
  }
}
`

export const ReadMessage = gql`
mutation ReadMessage($id: ID!){
                        updateChat(
                            id: $id,
                            input: {
                                status: true
                            }
                            ){
                                id
                            }
                        }
`