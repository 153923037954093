import React, { useEffect, useLayoutEffect, useState } from 'react'
import './profileSeller.scss'

import Navbar from "../../components/newNavbar/navbar";
import ProfileExib from "../../components/profileExib/profileExib";
import CommentCard from "../../components/commentCard/commentCard";
import { UserId } from '../../queries/player';
import Loading from '../../components/loading/loading'
import Modal from '../../components/Modal/modal'
import { CreatePropose } from '../../queries/propose'
import { CreateNotification } from '../../queries/notification'
import dayjs from 'dayjs'
import ErrorM from '../../components/ErrorMessange/errormenssage'
import * as emailjs from 'emailjs-com'
import { clientSms, userEmailJS } from '../../queries/endpoint'
import Button from '../../components/newButton/button'
import Input from '../../components/newInput/input'
import Back from '../../components/backButton/backButton'
import PhotoZoom from "../../components/photoZoom/photoZoom"
import ErrorBoundary from '../../components/errorBoundary/errorBoundary'
import { useMutation, useQuery } from "@apollo/client"
import ErrorScreen from '../../components/errorScreen/errorScreen'
import DatePicker, { setDefaultLocale } from "react-datepicker"
import { Report } from "notiflix"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from 'date-fns/locale/pt-BR';
setDefaultLocale('pt-BR', ptBR)

export default () => {
    const cache = JSON.parse(sessionStorage.getItem('OITO'))
    const { loading, data, error } = useQuery(UserId, {
        variables: {
            id: window.location.pathname.split('/')[(window.location.pathname.split('/').length - 1)]
        }
    })
    const [savePropose, { loading: mutationLoading }] = useMutation(CreatePropose)
    const [saveNotification, { loading: loadingsaveNotification }] = useMutation(CreateNotification)
    const tomorrow = new Date(dayjs(new Date()).add(1, 'day'))
    const [user] = useState(cache && cache.user)
    const [player, setPlayer] = useState()
    const [anuncio, setAnuncio] = useState()
    const [openZoom, setOpenZoom] = useState(false)
    const [zoomUrl, setZoomUrl] = useState('')
    const [zoomAlt, setZoomAlt] = useState('')
    const [show, setShow] = useState(false)
    const [proRate, setProRate] = useState(0)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [dateErr, setDateErr] = useState()
    const [location, setLocation] = useState()
    const [description, setDescription] = useState()
    const [descripErr, setDescripErr] = useState()

    useEffect(() => data && setPlayer(data.user), [data])

    const sendSmsNotificaton = (phone, message) => {
        clientSms.sms.enviar(phone, message)
            .then(function (data) {
                // console.log(data)
                console.log("sms enviado")
            })
            .catch(function (error) {
                console.error('Erro: ', error)
            });
    }

    const createNotification = (data) => {
        saveNotification({
            variables: {
                userId: data.id,
                title: data.title,
                description: data.description,
                link: data.link
            }
        }).then(
            () => console.log("notificacao enviada")
        ).catch(
            err => console.log(err)
        )
    }

    const openPropose = () => {

        if (user) {
            if (user.address) setLocation(user.address.city + " - " + user.address.state)

            setShow(true)
        } else {

            const listHistory = {
                count: 0,
                path: window.location.pathname
            }

            sessionStorage.setItem('LastURL', JSON.stringify(listHistory))
            // console.log(sessionStorage.getItem('LastURL'))

            Report.Failure('Você não está logado!', 'Entre ou cadastre-se. É gratuito!', 'Fechar', () => window.location.href = '/login')
        }

    }

    const onVerifyForm = () => {

        if (!startDate) {
            setDateErr(<ErrorM color="danger" content='Você não pode enviar uma proposta com data inicial.' />)

            return false
        }

        if (!description || description.length < 5) {
            setDescripErr(<ErrorM color="danger" content='Descreva rapidamente o que precisa. Ex.: "Você poderia ser o DJ da minha festa?""Você poderia prestar serviço de manobrista no meu evento?"' />)

            return false
        }

        if (player.id === user.id) {
            Report.Failure(
                'Solicitação não enviada!', 
                'Não é possivel enviar uma solicitação para você mesmo.', 
                'Fechar', 
                () => window.location.reload()
                )

            setShow(false)

            return false
        }

        return true
    }

    const sendRegister = () => {
        console.log("criando proposta")

        savePropose({
            variables: {
                anuncioId: anuncio.id,
                dateInit: startDate,
                dateEnd: endDate,
                timeInit: startTime,
                timeEnd: endTime,
                status: "Serviço Aberto para Negociação",
                valueInit: anuncio.price ? anuncio.price : "à combinar",
                userPro: player.id,
                description: description,
                location: location,
            }
        }).then(
            res => {
                // console.log(res)
                if (res.data.createPropose) {

                    const propose = res.data.createPropose

                    setShow(false)

                    createNotification({
                        id: propose.from.id,
                        title: "Proposta enviada",
                        description: "Entre em contato com o vendedor para negociar.",
                        link: `/perfil/proposedetails/${propose.id}`,
                    })

                    createNotification({
                        id: propose.to.id,
                        title: "Proposta recebida",
                        description: "Você recebeu uma solicitação. Entre em contato para fechar negócio.",
                        link: `/perfil/proposedetails/${propose.id}`,
                    })

                    //enviar email
                    var template_params2 = {
                        "email": propose.to.email,
                        "name": propose.to.name,
                    }

                    emailjs.send('noreply', 'env_proposta', template_params2, userEmailJS)
                        .then((response) => {
                            console.log('SUCCESS!', response.status, response.text)
                        }, function (error) {
                            console.log('FAILED...', error)
                        });

                    sendSmsNotificaton(
                        propose.to.phone.replace("+55", "").replace(/\D/g, ""),
                        "Você recebeu uma nova solicitação de serviço na Oito! Acesse oito.app e responda a solicitação o quanto antes. Oito App"
                    )

                    var template_params = {
                        "email": propose.from.email,
                        "name": propose.from.name,
                    }

                    emailjs.send('noreply', 'send_proposta', template_params, userEmailJS)
                        .then((response) => {
                            console.log('SUCCESS!', response.status, response.text)                           
                        }, function (error) {
                            console.log('FAILED...', error);
                        })
                        Report.Success(
                            'Solicitação enviada com sucesso!',
                            'Aguarde o profissional entrar em contato.',
                            'Fechar',
                            () => window.location.href = `/perfil/proposedetails/${propose.id}`
                        )
                }
            }).catch(
                err => {
                    console.log(err)
                    Report.Failure(
                        'Erro ao enviar a solicitação',
                        'Algo aconteceu. Tente novamente mais tarde.',
                        'Fechar',
                        () => window.location.reload()
                    )
                }
            )
    }

    const openImg = (imgUrl, imgAlt) => {
        setOpenZoom(!openZoom)
        setZoomAlt(imgAlt)
        setZoomUrl(imgUrl)
    }

    const filterAnun = (list, skill) => skill &&
        list.filter((anuncio) =>
            anuncio.skill.id === skill &&
            setAnuncio(anuncio))

    const ChoosePage = () => {
        if (anuncio && !anuncio.skill) {
            let list = []

            anuncio.map(com =>
                com.typeTo === "PROFISSIONAL" &&
                list.push(com))

            return list.length ? list.map((com, i) => (
                <CommentCard comment={com} key={i} />)) : <p>Este profissional não possui comentários ainda</p>

        } else if (anuncio && anuncio.skill) {
            return <div className="skill-desc col-xs-12">

                <div className={'infos col-xs-12'}>
                    <div>
                        <h2 style={{ fontWeight: "normal" }}>{anuncio.description}</h2>
                        <p><strong>Preço: </strong>{anuncio.price ? anuncio.price : 'á combinar'}</p>
                    </div>
                    <div>
                        <Button onClick={() => openPropose(anuncio)} name={"Enviar solicitação"} />
                    </div>
                </div>
                <h3>Fotos</h3>
                <div className={'filephotos col-xs-12'}>
                    {anuncio.files ? anuncio.files.length > 0 ? anuncio.files.map((img, i) => (
                        <img src={img} alt={""} style={{ cursor: 'pointer' }} key={i} onClick={() => openImg(img)} />
                    )) : <p>Este profissional ainda não possui fotos</p> : <p>Este profissional ainda não possui fotos</p>}
                </div>
            </div>
        }

        return null
    }

    useLayoutEffect(() => {
        if (player) {
            filterAnun(player.anuncios, window.location.search.split('?')[(window.location.search.split('?').length - 1)])

            if (player.ranks.length) {
                let notes = []
                // console.log("cliente", res.data.allRanks)
                player.ranks.map(rank => (
                    rank.typeTo === "PROFISSIONAL" ?
                        notes.push(rank.media) : null
                ))
                // console.log(notes)
                if (notes.length) setProRate(parseInt(notes.reduce((sum, num) => sum + num) / notes.length))
                // console.log(clientRate)
            }

            let skills = []

            if (player.anuncios) {
                player.anuncios.map(an => (
                    an.active && skills.push(an.skill.name)
                ))
            }
        }
    }, [player])

    if (loading || mutationLoading || loadingsaveNotification) return <Loading />

    if (error) return <ErrorScreen cod={error} />

    return (
        <>
            <Navbar />

            {openZoom && <ErrorBoundary><PhotoZoom onClose={() => setOpenZoom(!openZoom)} imgUrl={zoomUrl} imgAlt={zoomAlt} /></ErrorBoundary>}

            <div className={'profileUser col-xs-12'} style={{ maxWidth: "980px" }}>

                {player && <>
                    <ErrorBoundary>
                        <ProfileExib name={player.name} imgUrl={player.photo} city={player.address} resume={player.description} rank={proRate} rlist={player.ranks} />
                    </ErrorBoundary>

                    <div className={'skills-btn row'}>
                        {player.anuncios && player.anuncios.map((an, i) => (
                            an.active &&
                            <span key={i} className={(anuncio && anuncio.id === an.id) ? "active" : ""} onClick={() => setAnuncio(an)}>
                                {an.skill.name}
                                {!anuncio && setAnuncio(an)}
                            </span>
                        ))}

                        <span className={anuncio && !anuncio.skill ? "active" : null} onClick={() => setAnuncio(player.commentsTo)}>Comentários</span>

                    </div>

                    <Back />

                    <div className="row">
                        <ChoosePage />
                    </div>

                    {!!anuncio && anuncio.skill ? <Modal show={show} title={`Solicitar serviço de ${anuncio?.skill?.name} a ${player.name}`} onClose={() => setShow(!show)} >
                        <p>Preencha os campos abaixo com as informações do serviço que você quer contratar. Lembrando que os campos de descrição e data são obrigatórios!</p>
                        <form className="row start-xs" onSubmit={() =>
                            onVerifyForm() && sendRegister()
                        }>

                            <div className="col-xs-12 col-sm-6" style={{ marginTop: '15px' }}>

                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    selectsStart
                                    startDate={tomorrow}
                                    endDate={endDate}
                                    minDate={tomorrow}
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Selecione uma data inicial"
                                    className="input-date-picker"
                                />

                            </div>

                            <div className="col-xs-12 col-sm-6" style={{ marginTop: '15px' }}>
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate || tomorrow}
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Selecione uma data final"
                                    className="input-date-picker"
                                />


                                {dateErr}
                            </div>
                            <div className="col-xs-12 col-sm-6" style={{ marginTop: '25px' }}>
                                <DatePicker
                                    selected={startTime}
                                    onChange={date => setStartTime(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    placeholderText="Selecione uma hora inicial"
                                    className="input-date-picker"
                                />

                            </div>

                            <div className="col-xs-12 col-sm-6" style={{ marginTop: '15px' }}>
                                <DatePicker
                                    selected={endTime}
                                    onChange={date => setEndTime(date)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    placeholderText="Selecione uma hora final"
                                    className="input-date-picker"
                                />
                                {/* <Input icon="far fa-clock" name="timeEnd" label="Horário de encerramento" placeholder="Horário de encerramento. Ex. 23:00" type="text" onInput={this.setInput} onKeyUp={this.timeValue} value={this.form.timeEnd} maxlength={6} /> */}
                            </div>

                            <div className="col-xs-12" style={{ marginTop: '35px' }}>
                                <Input name="location" label="Local do serviço" placeholder="Local do serviço. Ex. Cidade - Estado" type="text" onInput={(e) => setLocation(e.target.value)} defaultValue={location} />
                            </div>

                            <div className="col-xs-12" style={{ marginTop: '35px' }}>
                                <Input icon="far fa-file-alt" name="description" label="Descrição" placeholder="Breve descrição do serviço." type="text" onInput={(e) => setDescription(e.target.value)} maxlength={254} required />
                                {descripErr}
                            </div>

                            <Button  style={{ margin: '25px auto' }} name='Enviar proposta' type='submit' />

                        </form>
                    </Modal>
                    : null}
                </>}
            </div>
        </>
    )
}
