import React, { Component } from 'react';
import './passwordmeter.scss';
import zxcvbn from 'zxcvbn';

export default class PasswordMeter extends Component {

  createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return 'Senha muito fraca';
      case 1:
        return 'Senha muito fraca';
      case 2:
        return 'Senha fraca';
      case 3:
        return 'Senha boa';
      case 4:
        return 'Senha forte';
      default:
        return 'Senha muito fraca';
    }
  }

  render() {
    const { password } = this.props;

    if (password) {
        const testedResult = zxcvbn(password);
        return (
          <div className="password-strength-meter" style={{position: 'relative', textAlign: 'center'}}>
            <progress
              className={`password-strength-meter-progress strength-${this.createPasswordLabel(testedResult)}`}
              value={testedResult.score}
              max="4"
            />
            <br />

              {password && (
                  <span className="password-strength-meter-label">
                  <strong>Nível de segurança da sua senha:</strong> {this.createPasswordLabel(testedResult)}
                </span>
              )}
          </div>
        )
    } else return null
  }
}
