import React from "react"
import './photoZoom.scss'

// import info from '../../assets/info-circle-solid.svg'

import CloseIcon from '../../assets/close.png'

export default class PhotoZoom extends React.Component {
    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };

    render() {
        const {
            imgUrl,
            imgAlt
        } = this.props;

        return(
            <div className="photoZoom">
                <img className={'icon'} src={CloseIcon} onClick={this.onClose} alt="close icon"/>
                <div/>
                <img className={'imgLoaded'} src={imgUrl} alt={imgAlt}/>
            </div>
        )
    }
}
