import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import { PositiveComment, NeutralComment, NegativeComment, ProComment, ClientComment, PositiveCommentC, NeutralCommentC, NegativeCommentC } from "../../../queries/comments"
import { RateRankClient, RateRankPro } from '../../../queries/rank'
import Loading from '../../../components/loading/loading'
import dayjs from 'dayjs'
// import Rater from 'react-rater'
// import Star from '../../components/Stars/star'
import CommentCard from "../../../components/commentCard/commentCard";
import AvaliationStar from '../../../components/avaliationStars/avaliationStars'
import Tooltip from '../../../components/tooltip/tooltip'
import Button from '../../../components/newButton/button'
import { Reply } from '../../../queries/comments'
import Input from '../../../components/newInput/input'
import './rankUser.scss'
import negative from '../../../assets/icons/oitonegative.svg'
import neutral from '../../../assets/icons/oitoneutral.svg'
import positive from '../../../assets/icons/oitopositive.svg'
import { Report, Notify } from "notiflix"
import { useQuery, useMutation } from "@apollo/client"

export default () => {

    const cache = JSON.parse(sessionStorage.getItem('OITO'))
    const { data: positiveCommentsC, loading: loading1 } = useQuery(PositiveCommentC, {
        variables: {
            userId: cache && cache.user.id
        }
    })
    const { data: neutralCommentsC, loading: loading2 } = useQuery(NeutralCommentC, {
        variables: {
            userId: cache && cache.user.id
        }
    })
    const { data: negativeCommentC, loading: loading3 } = useQuery(NegativeCommentC, {
        variables: {
            userId: cache && cache.user.id
        }
    })
    const { data: positiveComments, loading: loading4 } = useQuery(PositiveComment, {
        variables: {
            userId: cache && cache.user.id
        }
    })
    const { data: neutralComments, loading: loading5 } = useQuery(NeutralComment, {
        variables: {
            userId: cache && cache.user.id
        }
    })
    const { data: negativeComment, loading: loading6 } = useQuery(NegativeComment, {
        variables: {
            userId: cache && cache.user.id
        }
    })
    const { data: rateRankClient, loading: loading7 } = useQuery(RateRankClient, {
        variables: {
            userId: cache && cache.user.id
        }
    })
    const { data: rateRankPro, loading: loading8 } = useQuery(RateRankPro, {
        variables: {
            userId: cache && cache.user.id
        }
    })
    const { data: clientComment, loading: loading9 } = useQuery(ClientComment, {
        variables: {
            userId: cache && cache.user.id
        }
    })
    const { data: proComment, loading: loading10 } = useQuery(ProComment, {
        variables: {
            userId: cache && cache.user.id
        }
    })
    const [replayComment, { loading: loadingMutation }] = useMutation(Reply)
    const [clientRate, setClientRate] = useState(0)
    const [custoCRate, setCustoCRate] = useState(0)
    const [pontualidadeCRate, setPontulidadeCRate] = useState(0)
    const [respeitoCRate, setRespeitoCRate] = useState(0)
    const [transparenciaCRate, setTransparenciaCRate] = useState(0)
    const [proRate, setProRate] = useState(0)
    const [agilidadeRate, setAgilidadeRate] = useState(0)
    const [atendimentoRate, setAtendimentoRate] = useState(0)
    const [custoRate, setCustoRate] = useState(0)
    const [ferramentasRate, setFerramentasRate] = useState(0)
    const [pontualidadeRate, setPontualidadeRate] = useState(0)
    const [respostaRate, setRespostaRate] = useState(0)
    const [solucaoRate, setSolucaoRate] = useState(0)
    const [idComment, setIdComment] = useState()
    const [replyInput, setReplyInput] = useState()

    useEffect(() => {
        if (!cache) {
            Report.Failure('Você não está logado!', 'Entre ou cadastre-se. É gratuito!', 'Fechar', () => window.location.href = '/login')
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (rateRankClient) {
            let media = []
            let custoC = []
            let pontualidadeC = []
            let respeitoC = []
            let transparenciaC = []

            rateRankClient.allRanks.map(rank => {
                media.push(rank.media)
                custoC.push(rank.custoC)
                pontualidadeC.push(rank.pontualidadeC)
                respeitoC.push(rank.respeitoC)
                transparenciaC.push(rank.transparenciaC)
                return null
            })

            setClientRate(media.length && parseInt(media.reduce((sum, num) => sum + num) / media.length))
            setCustoCRate(custoC.length && parseInt(custoC.reduce((sum, num) => sum + num) / custoC.length))
            setPontulidadeCRate(pontualidadeC.length && parseInt(pontualidadeC.reduce((sum, num) => sum + num) / pontualidadeC.length))
            setRespeitoCRate(respeitoC.length && parseInt(respeitoC.reduce((sum, num) => sum + num) / respeitoC.length))
            setTransparenciaCRate(transparenciaC.length && parseInt(transparenciaC.reduce((sum, num) => sum + num) / transparenciaC.length))
        }
    }, [rateRankClient]) // eslint-disable-line

    useEffect(() => {
        if (rateRankPro) {
            let media = []
            let agilidade = []
            let atendimento = []
            let custo = []
            let ferramentas = []
            let pontualidade = []
            let resposta = []
            let solucao = []

            rateRankPro.allRanks.map(rank => {
                media.push(rank.media)
                agilidade.push(rank.agilidade)
                atendimento.push(rank.atendimento)
                custo.push(rank.custo)
                ferramentas.push(rank.ferramentas)
                pontualidade.push(rank.pontualidade)
                resposta.push(rank.resposta)
                solucao.push(rank.solucao)
                return null
            })

            setProRate(media.length && parseInt(media.reduce((sum, num) => sum + num) / media.length))
            setAgilidadeRate(agilidade.length && parseInt(agilidade.reduce((sum, num) => sum + num) / agilidade.length))
            setAtendimentoRate(atendimento.length && parseInt(atendimento.reduce((sum, num) => sum + num) / atendimento.length))
            setCustoRate(custo.length && parseInt(custo.reduce((sum, num) => sum + num) / custo.length))
            setFerramentasRate(ferramentas.length && parseInt(ferramentas.reduce((sum, num) => sum + num) / ferramentas.length))
            setPontualidadeRate(pontualidade.length && parseInt(pontualidade.reduce((sum, num) => sum + num) / pontualidade.length))
            setRespostaRate(resposta.length && parseInt(resposta.reduce((sum, num) => sum + num) / resposta.length))
            setSolucaoRate(solucao.length && parseInt(solucao.reduce((sum, num) => sum + num) / solucao.length))
        }
    }, [rateRankPro]) // eslint-disable-line

    const openField = id => {
        setIdComment(id)
        const element = document.getElementById(id)
        if (element.style.display === "none") {
            element.style.display = "block"
        } else {
            element.style.display = "none"
        }
    }

    const sendReply = e => {
        e.preventDefault()

        replayComment({
            variables: {
                id: idComment,
                reply: replyInput
            }
        }).then(
            res => {
                // console.log(res)
                Notify.Success("Réplica enviada com sucesso.")
                setIdComment()
                replyInput()
                
            }
        ).catch( err => {
            console.log(err)
            Notify.Failure("Erro ao enviar a réplica. Tente novamente mais tarde.")
        })
    }

    if (loading1 ||
        loading2 ||
        loading3 ||
        loading4 ||
        loading5 ||
        loading6 ||
        loading7 ||
        loading8 ||
        loading9 ||
        loading10 ||
        loadingMutation) return <Loading />


    return (
        <>
            <Helmet title='Oito - Desempenho'>
                <meta name="title" content="Oito.app | Confira o ranking de profissionais qualificados" />
                <meta name="description" content="Encontre os profissionais mais qualificados e confiáveis por meio do ranking de avaliações positivas da Oito.app." />
                <meta name="keywords" content="Profissionais, Habilidades, Qualificados" />
                <meta name="robots" content="index, follow" />
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="Portuguese" />
                <meta name="revisit-after" content="1 days" />
            </Helmet>
            <div className="rankUser col-xs-12" style={{ maxWidth: '980px' }}>
                <div className="ranking row center-xs">
                    <div className="RankClient col-xs-12 col-sm-6">
                        <h3>Reputação como cliente</h3>

                        <div className="general-review row center-xs">
                            <div className="col-xs-4">
                                <img src={positive} alt="positive" />
                                <div>
                                    <span className={'numbers'}>{positiveCommentsC && positiveCommentsC.allComments.length}</span>
                                </div>
                                <div className={'tooltip'}>Avaliação positiva</div>
                            </div>

                            <div className="col-xs-4">
                                <div>
                                    <img src={neutral} alt="negative" />
                                </div>
                                <div>
                                    <span className={'numbers'}>{neutralCommentsC && neutralCommentsC.allComments.length}</span>
                                </div>
                                <div className={'tooltip'}>Avaliação neutra</div>
                            </div>

                            <div className="col-xs-4">
                                <div>
                                    <img src={negative} alt="negative" />
                                </div>
                                <div>
                                    <span className={'numbers'}>{negativeCommentC && negativeCommentC.allComments.length}</span>
                                </div>
                                <div className={'tooltip'}>Avaliação negativa</div>
                            </div>
                        </div>

                        <h3><i className="fas fa-chart-line"></i>Desempenho como cliente</h3>
                        {rateRankClient ?
                            <div className="row">
                                <div className="performance-card col-xs-12">
                                    <div className="row middle-xs between-xs" style={{ marginTop: "15px", borderBottom: "1px solid #dadada" }}>
                                        <div className="col-xs-6 col-sm-7 col-md-8">
                                            <div className="row">
                                                <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Média</span>
                                                <Tooltip text={`Média geral. Total de avaliadores: ${rateRankClient.allRanks.length}`} />
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-5 col-md-4">
                                            <div className="row end-xs">
                                                <AvaliationStar avaliation={clientRate} /></div></div>
                                    </div>
                                </div>
                                <div className="performance-card col-xs-12">
                                    <div className="row middle-xs between-xs" style={{ marginTop: "15px", borderBottom: "1px solid #dadada" }}>
                                        <div className="col-xs-6 col-sm-7 col-md-8">
                                            <div className="row">
                                                <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Custo-benefício</span>
                                                <Tooltip text={'O valor pago é justo em relação ao serviço prestado?'} />
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-5 col-md-4">
                                            <div className="row end-xs">
                                                <AvaliationStar avaliation={custoCRate} /></div></div>
                                    </div>
                                </div>
                                <div className="performance-card col-xs-12">
                                    <div className="row middle-xs between-xs" style={{ marginTop: "15px", borderBottom: "1px solid #dadada" }}>
                                        <div className="col-xs-6 col-sm-7 col-md-8">
                                            <div className="row">
                                                <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Pontualidade no pagamento</span>
                                                <Tooltip text={'O cliente que contratou seu serviço pagou de forma pontual?'} />
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-5 col-md-4">
                                            <div className="row end-xs">
                                                <AvaliationStar avaliation={pontualidadeCRate} /></div></div>
                                    </div>
                                </div>
                                <div className="performance-card col-xs-12">
                                    <div className="row middle-xs between-xs" style={{ marginTop: "15px", borderBottom: "1px solid #dadada" }}>
                                        <div className="col-xs-6 col-sm-7 col-md-8">
                                            <div className="row">
                                                <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Respeito no atendimento</span>
                                                <Tooltip text={'O cliente que contratou você foi respeitoso e atencioso durante sua prestação de serviço?'} />
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-5 col-md-4">
                                            <div className="row end-xs">
                                                <AvaliationStar avaliation={respeitoCRate} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="performance-card col-xs-12">
                                    <div className="row middle-xs between-xs" style={{ marginTop: "15px", borderBottom: "1px solid #dadada" }}>
                                        <div className="col-xs-6 col-sm-7 col-md-8">
                                            <div className="row">
                                                <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Transparência no serviço</span>
                                                <Tooltip text={'A descrição do serviço estava coerente com o que fora executado?'} />
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-5 col-md-4">
                                            <div className="row end-xs">
                                                <AvaliationStar avaliation={transparenciaCRate} /></div></div>
                                    </div>
                                </div>
                            </div>
                            : <div id="empty-client"><p>Você não possui avaliação como Cliente. <br /><Link to="/anuncios">Contrate alguém</Link> e facilite sua vida!</p></div>}
                    </div>
                    <div className="RankProfissional col-xs-12 col-sm-6">
                        <h3>Reputação como profissional</h3>

                        <div className="general-review row center-xs">
                            <div className="col-xs-4">
                                <img src={positive} alt="positive" />
                                <div>
                                    <span className={'numbers'}>{positiveComments && positiveComments.allComments.length}</span>
                                </div>
                                <div className={'tooltip'}>Avaliação positiva</div>
                            </div>

                            <div className="col-xs-4">
                                <img src={neutral} alt="neutral" />
                                <div>
                                    <span className={'numbers'}>{neutralComments && neutralComments.allComments.length}</span>
                                </div>
                                <div className={'tooltip'}>Avaliação neutra</div>
                            </div>

                            <div className="col-xs-4">
                                <img src={negative} alt="negative" />
                                <div style={{ flex: '0 0 100%' }}>
                                    <span className={'numbers'}>{negativeComment && negativeComment.allComments.length}</span>
                                </div>
                                <div className={'tooltip'}>Avaliação negativa </div>
                            </div>
                        </div>

                        <h3><i className="fas fa-chart-line"></i>Desempenho como Profissional</h3>

                        {rateRankPro ? <div className="row">
                            <div className="performance-card col-xs-12">
                                <div className="row middle-xs between-xs" style={{ marginTop: "15px", borderBottom: "1px solid #dadada" }}>
                                    <div className="col-xs-6 col-sm-7 col-md-8">
                                        <div className="row">
                                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Média</span>
                                            <Tooltip text={`Média geral. Total de avaliadores: ${rateRankPro.allRanks.length}`} />
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-sm-5 col-md-4">
                                        <div className="row end-xs"><AvaliationStar avaliation={proRate} /></div></div>
                                </div>
                            </div>
                            <div className="performance-card col-xs-12">
                                <div className="row middle-xs between-xs" style={{ marginTop: "15px", borderBottom: "1px solid #dadada" }}>
                                    <div className="col-xs-6 col-sm-7 col-md-8">
                                        <div className="row">
                                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Agilidade no serviço</span>
                                            <Tooltip text={`O serviço foi feito de forma ágil e eficiente?`} />
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-sm-5 col-md-4">
                                        <div className="row end-xs"><AvaliationStar avaliation={agilidadeRate} /></div></div>
                                </div>
                            </div>
                            <div className="performance-card col-xs-12">
                                <div className="row middle-xs between-xs" style={{ marginTop: "15px", borderBottom: "1px solid #dadada" }}>
                                    <div className="col-xs-6 col-sm-7 col-md-8">
                                        <div className="row">
                                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Atendimento</span>
                                            <Tooltip text={`O prestador de serviço atendeu você com respeito, dedicação e transparência?`} />
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-sm-5 col-md-4">
                                        <div className="row end-xs"><AvaliationStar avaliation={atendimentoRate} /></div></div>
                                </div>
                            </div>
                            <div className="performance-card col-xs-12">
                                <div className="row middle-xs between-xs" style={{ marginTop: "15px", borderBottom: "1px solid #dadada" }}>
                                    <div className="col-xs-6 col-sm-7 col-md-8">
                                        <div className="row">
                                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Custo-benefício</span>
                                            <Tooltip text={`Como você avalia a relação de valores e entrega do serviço?`} />
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-sm-5 col-md-4">
                                        <div className="row end-xs"><AvaliationStar avaliation={custoRate} /></div></div>
                                </div>
                            </div>
                            <div className="performance-card col-xs-12">
                                <div className="row middle-xs between-xs" style={{ marginTop: "15px", borderBottom: "1px solid #dadada" }}>
                                    <div className="col-xs-6 col-sm-7 col-md-8">
                                        <div className="row">
                                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Ferramentas necessárias</span>
                                            <Tooltip text={`O prestador de serviço levou todas as ferramentas necessárias para a execução do serviço?`} />
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-sm-5 col-md-4">
                                        <div className="row end-xs"><AvaliationStar avaliation={ferramentasRate} /></div></div>
                                </div>
                            </div>
                            <div className="performance-card col-xs-12">
                                <div className="row middle-xs between-xs" style={{ marginTop: "15px", borderBottom: "1px solid #dadada" }}>
                                    <div className="col-xs-6 col-sm-7 col-md-8">
                                        <div className="row">
                                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Pontualidade</span>
                                            <Tooltip text={`Todos os prazos combinados durante a negociação e execução dos serviços foram cumpridos sem atrasos?`} />
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-sm-5 col-md-4">
                                        <div className="row end-xs"><AvaliationStar avaliation={pontualidadeRate} /></div></div>
                                </div>
                            </div>
                            <div className="performance-card col-xs-12">
                                <div className="row middle-xs between-xs" style={{ marginTop: "15px", borderBottom: "1px solid #dadada" }}>
                                    <div className="col-xs-6 col-sm-7 col-md-8">
                                        <div className="row">
                                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Respostas no chat</span>
                                            <Tooltip text={`O prestador de serviço usou o chat de forma ágil, respeitosa e clara?`} />
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-sm-5 col-md-4">
                                        <div className="row end-xs"><AvaliationStar avaliation={respostaRate} /></div></div>
                                </div>
                            </div>
                            <div className="performance-card col-xs-12">
                                <div className="row middle-xs between-xs" style={{ marginTop: "15px", borderBottom: "1px solid #dadada" }}>
                                    <div className="col-xs-6 col-sm-7 col-md-8">
                                        <div className="row">
                                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Solução do problema</span>
                                            <Tooltip text={`O prestador de serviço contratado solucionou seu problema de forma eficiente?`} />
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-sm-5 col-md-4">
                                        <div className="row end-xs"><AvaliationStar avaliation={solucaoRate} /></div></div>
                                </div>
                            </div>
                        </div>
                            : <div id="empty-client"><p>Você não possui avaliação como Profissional.<br /> <Link to="/perfil/data">Anuncie seus serviços</Link> e faça dinheiro extra.</p></div>}
                    </div>
                </div>

            </div>
            <div className="commentsR col-xs-12" style={{ maxWidth: '980px', marginTop: "30px" }}>
                <div className="row center-xs">
                    <div className="col-xs-12 col-sm-6">
                        <h3>Comentários como Cliente</h3>

                        {clientComment && clientComment.allComments ? clientComment.allComments.map((comment, i) => (
                            <div key={i}>
                                <CommentCard comment={comment} />
                                {(!comment.reply && dayjs(comment.date).add(15, 'day').isAfter(dayjs(Date.now()))) &&
                                    <>
                                        <div onClick={() => openField(comment.id)}>
                                            + Adicionar uma Réplica</div>

                                        <form id={comment.id} style={{ display: "none", textAlign: "center" }} onSubmit={(e) => sendReply(e)}>
                                            <Input name="replyC" placeholder="Digite aqui sua réplica." type="text" onChange={(e) => setReplyInput(e.target.value)} maxLength={140} />
                                            <Button
                                                name='Enviar réplica'
                                                type='submit'
                                            />
                                        </form>
                                    </>}
                            </div>
                        ))
                            : <div><div id="empty-client"><p>Você não possui comentarios como Cliente.<br /> <Link to="/anuncios">Contrate alguém</Link> facilite sua vida!</p></div></div>
                        }
                    </div>
                    <div className="col-xs-12 col-sm-6">

                        <h3>Comentários como Profissional</h3>

                        <div className="card-list">

                            {proComment && proComment.allComments ? proComment.allComments.map((comment, i) => (
                                <div key={i}>
                                    <CommentCard comment={comment} />
                                    {(!comment.reply && dayjs(comment.date).add(15, 'day').isAfter(dayjs(Date.now()))) &&
                                        <>
                                            <div onClick={() => openField(comment.id)}>
                                                + Adicionar uma Réplica</div>


                                            <form id={comment.id} style={{ display: "none", textAlign: "center" }} onSubmit={(e) => sendReply(e)}>

                                                <Input name="replyP" placeholder="Digite aqui sua réplica." type="text" onChange={(e) => setReplyInput(e.target.value)} maxLength={140} />
                                                <Button
                                                    name='Enviar réplica'
                                                    type='submit'
                                                />
                                            </form>
                                        </>}
                                </div>
                            ))
                                : <div id="empty-client"><p>Você não possui comentários como Profissional.<br /> <Link to="/perfil/data">Anuncie seus serviços</Link> e faça um dinheiro extra.</p></div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}