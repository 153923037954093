import React from "react"
import './faqs.scss'

import { Link } from 'react-router-dom'

import Navbar from '../../components/newNavbar/navbar'
import ButtonText from '../../components/buttonText/buttonText'
import Footer from '../../components/newFooter/footer'
import ErrorBroundray from '../../components/errorBoundary/errorBoundary'

export default () => <>
    <ErrorBroundray><Navbar /></ErrorBroundray>
    <article className='faq col-xs-12'>
        <h1>Perguntas feitas frequentemente</h1>
        <section>
            <h3>1. O que é a Oito?</h3>
            <p>A Oito é uma plataforma que conecta pessoas e oportunidades, em que profissionais autônomos divulgam seus serviços e encontram clientes que procuram soluções - tudo de um jeito rápido, seguro e proveitoso para os dois lados.</p>
        </section>

        <section>
            <h3>2. Como faço para usar a Oito?</h3>
            <p>Para usar nossa plataforma, você precisa se cadastrar em nosso site como Prestador de Serviços ou como Cliente.
            Caso queira ofertar serviços (Prestador de Serviços), basta adicionar a área de atuação e as habilidades ao seu perfil.
                            Caso esteja procurando serviços (Cliente), basta procurar o profissional que deseja e iniciar a Solicitação de Serviço.</p>
            <iframe className="tutorial-video" src="https://www.youtube.com/embed/fxbmaHIuujA" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} title="Como faço para usar a Oito?" />
            {/* <video src={"https://www.youtube.com/embed/fxbmaHIuujA"} controls={true} className="tutorial-video" /> */}
        </section>

        <section>
            <h3>3. Existe alguma cobrança?</h3>
            <p>
                A OITO é totalmente gratuita.
                Não existe cobrança na hora do cadastro ou taxas sobre a negociação.
                Você, Prestador de Serviços, recebe o valor integral combinado com o Cliente, pois a negociação é direta.
                        </p>
        </section>

        <section>
            <h3>4. Como encontro um profissional e solicito um serviço?</h3>
            <p>
                Assim que você faz seu cadastro já pode iniciar sua busca por profissionais anunciantes.
                Basta digitar na barra de pesquisa o serviço que você está procurando e a OITO apresentará os perfis que atendem ao seu pedido, destacando as habilidades de cada um e a avaliação dada pelos clientes anteriores.
                        </p>
            {/* video */}
            <iframe className="tutorial-video" src="https://www.youtube.com/embed/TJG3ezPNWYY" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} title="Como encontro um profissional e solicito um serviço?" />
            {/* <video src={Tutorial1} controls={true} className="tutorial-video" /> */}
            <p>Confira o tutorial para entender melhor o passo a passo.</p>
        </section>

        <section id="anuncio">
            <h3>5. Como anunciar os meus serviços?</h3>

            <p>Assim que você faz seu cadastro e adiciona sua área de atuação e suas habilidades, você já faz parte do banco de profissionais que a OITO divulga, mas o anúncio é o que realmente faz com que seu perfil se destaque.
            Clientes que procuram serviços parecidos com os seus podem encontrar o seu perfil e propor uma negociação.
</p>
            {/* video */}
            <iframe className="tutorial-video" src="https://www.youtube.com/embed/ulKnH0b62zI" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} title="Como anunciar os meus serviços?"></iframe>
            {/* <video src={Tutorial2} controls={true} className="tutorial-video" /> */}
            <p>Confira o tutorial para entender melhor o passo a passo.</p>
        </section>

        <section>
            <h3>6. Como ver minhas solicitações e negociar?</h3>
            <p>Ao receber uma solicitação você negocia diretamente com o cliente os detalhes do serviço como preços, horários, forma de pagamento e demais detalhes.
            A OITO não intermedia e nem cobra nada dos serviços que você conseguir, deixando você livre.
</p>
            {/* video */}
            <iframe className="tutorial-video" src="https://www.youtube.com/embed/_d6h-kR2PQs" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} title="Como ver minhas solicitações e negociar?"></iframe>
            {/* <video src={Tutorial3} controls={true} className="tutorial-video" /> */}
            <p>Confira o tutorial para entender melhor o passo a passo.</p>
        </section>

        <section>
            <h3>7. Quais as vantagens da Oito?</h3>
            <p>
                Para quem presta serviços de forma autônoma, a OITO serve como uma plataforma de divulgação de habilidades e competências com as referências e indicações dos seus clientes anteriores.
                Assim você consegue aumentar sua clientela e ganhar um dinheiro extra.
                Para quem procura profissionais, a OITO facilita a conexão com um Prestador de Serviço testado e avaliado pelos clientes anteriores, com bons indicativos de capacitação e garante segurança na contratação o serviço.
                        </p>
        </section>

        <section>
            <h3>8. O que são as categorias e habilidades?</h3>
            <p>As categorias são as seções mais abrangentes do site, que ajudam a filtrar as pesquisas do Cliente, deixando as buscas mais rápidas e práticas.
            Já as habilidades existem dentro dessas categorias e você, Prestador de Serviço, pode escolher até 3 delas para adicionar ao seu perfil.
</p>
        </section>

        <section>
            <h3>9. Qual a importância de um perfil completo?</h3>
            <p>Com um perfil completo você aumenta a confiabilidade no seu anúncio, garante a segurança nas negociações e consegue solicitar e fechar serviços.
            Os anúncios devem ser claros e honestos, novas fotos devem ser inseridas sempre que possível e os seus dados de contato devem estar sempre atualizados.
</p>
        </section>
        <section>
            <Link to={'/'}><ButtonText icon={'faChevronLeft'} name={'voltar'} /></Link>
        </section>
    </article>
    <ErrorBroundray><Footer /></ErrorBroundray>
</>