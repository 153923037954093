import React, { useLayoutEffect } from 'react'
// import UserProfile from '../components/Profile/Profile'
import './profileUser.scss'
import Navbar from "../../components/newNavbar/navbar";
import ProfileLoggedIn from "../../components/profileLoggedIn/profileLoggedIn";
import ErrorBroundray from '../../components/errorBoundary/errorBoundary'
import { Report } from 'notiflix'

// import ActivityCard from "../../components/activityCard/activityCard"

export default (props) => {
    const cache = JSON.parse(sessionStorage.getItem('OITO'));

    useLayoutEffect(() => {
        const location = window.location;

        const linksArray = document.querySelectorAll(
            'div.sub-nav > ul > li > a'
        );

        // console.log(location.pathname);

        // console.log(linksArray);

        for (let i = 0; i < linksArray.length; i++) {
            const elementClass = linksArray[i].className;
            const element = linksArray[i].pathname;
            const pureElement = linksArray[i];

            // console.log(element);

            if (elementClass.includes("active")) {
                pureElement.className = '';
            }

            if (location.pathname === element) {
                pureElement.classList.add("active");
            }
        }
    }, [])

    useLayoutEffect(() => {
        const location = window.location;

        const linksArray = document.querySelectorAll(
            'div.sub-nav > ul > li > a'
        );

        for (let i = 0; i < linksArray.length; i++) {
            const elementClass = linksArray[i].className;
            const element = linksArray[i].pathname;
            const pureElement = linksArray[i];

            if (elementClass.includes("active")) {
                pureElement.className = '';
            }

            if (location.pathname === element) {
                pureElement.classList.add("active");
            }
        }
    })

    useLayoutEffect(() => {

        if (!cache) {
            return Report.Failure('Você não está logado!', 'Entre ou cadastre-se. É gratuito!', 'Fechar', () => window.location.href = '/login')
        }

        const LastURL = JSON.parse(sessionStorage.getItem('LastURL'))
        if (LastURL) {
            if (LastURL.path !== window.location.pathname) {
                sessionStorage.removeItem('LastURL')
                window.history.go(parseInt(LastURL.count) - 1)
            }
        }
    }, [cache])

        return (
            <>
                <ErrorBroundray><Navbar /></ErrorBroundray>

                <div className={'profileUserLoggedIn col-xs-12'}>
                    <div className="row center-xs middle-xs">
                        {cache && <ErrorBroundray><ProfileLoggedIn userInfo={cache.user} /></ErrorBroundray>}
                        <div className="col-xs-12" style={{ marginBottom: '40px' }}>
                            <div className="row center-xs middle-xs">
                                <ErrorBroundray>{props.childrencomponent}</ErrorBroundray>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
