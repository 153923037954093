import React from "react"
import './chipWord.scss'

const ChipWord = (props) => {
    const {
        name,
        onClick
    } = props;

    return (
        <span className={'chip-word'} onClick={onClick} >{name}</span>
    )
}

export default ChipWord