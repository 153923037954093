import React from "react"
import './tooltip.scss'

import info from '../../assets/info-circle-solid.svg'

export default class Tooltip extends React.Component {
    render() {
        const {
            text,
            direction,
            children
        } = this.props;

        return (
            <div className="tooltip-container">
                            {children? children : <img className={'tooltip-icon'} src={info} alt={'Informação'} />}
                            <p className={direction === "left" ? "tooltip-content left" : direction === "bottom" ? "tooltip-content bottom" : direction === "top" ? "tooltip-content top" : 'tooltip-content'}>{text}</p>
            </div>
        )
    }
}
