import React from "react"
import './blog.scss'
import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'
import Button from '../../components/newButton/button'
import { Post } from '../../queries/blog'
import Back from '../../components/backButton/backButton'
import Navbar from '../../components/newNavbar/navbar'
import Loading from '../../components/loading/loading'
import ErrorBroundray from '../../components/errorBoundary/errorBoundary'
import Footer from '../../components/newFooter/footer'
import { useQuery } from "@apollo/client"

export default () => {
    const { loading, data, error } = useQuery(Post, {
        variables: {
            id: window.location.pathname.split('/')[(window.location.pathname.split('/').length - 1)]
        }
    })

    if (loading) return <Loading />

    return <>
        <div className="col-xs-12" style={{ marginLeft: "0", paddingLeft: "0" }}>
            <ErrorBroundray><Navbar /></ErrorBroundray>
            <div className="blogPost row">

                <div className="post col-xs-12">
                    <div className="contentHTML">
                        {data && data.post ? 
                            ReactHtmlParser(data.post.content) : 
                            <div style={{ textAlign: 'center', width: '100vw' }}>Post não encontrado</div>
                        }
                        {error && <div style={{ textAlign: 'center', width: '100vw' }}>Post não encontrado</div>}
                    </div>
                </div>


                <div className="col-xs-12">
                    <Back />
                </div>
                
                <div className="col-xs-12 row" style={{ color: "#5f5f5f", marginTop: "10px", marginBottom: "10px" }}>
                    <div className="col-xs-12">
                        <h3>Venha fazer parte da Oito.</h3>
                    </div>
                    <div className="col-xs-12">
                        <Link to='/cadastro'><Button name='Cadastre-se!' /></Link>
                    </div>

                </div>
            </div>

        </div>
        <ErrorBroundray><Footer /></ErrorBroundray>
    </>
}