import React, { Component } from "react";
import './select.scss'

export class Select extends Component {
    // constructor(props){
    //     super(props)
    // }

    render() {
        const {
            name,
            id,
            placeholder,
            itens,
            onChange
        } = this.props;

        // console.log(itens);
        return (
            <select name={name} id={id} className={'select-input'} onChange={onChange}>
                <option value={'clean'}>{placeholder}</option>
                {itens.map((obj, i) => <option key={i} value={obj}>{obj}</option>)}
            </select>
        )
    }
}
