import React, { useState, useEffect } from 'react'
// import UserProfile from '../components/Profile/Profile'
import { Proposes3 } from "../../../queries/propose"
import * as dayjs from 'dayjs'
import './dashboard.scss'
import { useLazyQuery } from "@apollo/client"
import ActivityCard from "../../../components/activityCard/activityCard"
import ErrorBoundary from '../../../components/errorBoundary/errorBoundary'
import Calendar from "../../../components/calendar/calendar"
import Loading from "../../../components/loading/loading"

const Dashboard = () => {

    const [player, setPlayer] = useState()
    const [getProposes, { loading, data }] = useLazyQuery(Proposes3)

    useEffect(() => {
        const cache = JSON.parse(sessionStorage.getItem('OITO'));
        if (cache) {
            setPlayer(cache.user)
        } else {
            window.location.href = '/login'
        }
    }, [])

    useEffect(() => player && getProposes({ variables: { id: player.id } }), [player, getProposes])

    const ProposeList = () => {

        let propsort = []

        if (data && data.user) {
            let proposes = []

            if (data.user.proposes && data.user.proposesFrom) {
                proposes = data.user.proposes.concat(data.user.proposesFrom)
            } else if (data.user.proposes) {
                proposes = data.user.proposes
            } else if (data.user.proposesFrom) {
                proposes = data.user.proposesFrom
            }

            // const propsort = proposes.sort(proposes.date).reverse().splice(0, 3)
            propsort = proposes.sort(function (a, b) {
                return b.createdAt - a.createdAt
            })

        }

        return propsort.length ? propsort.map((prop, i) => {
            return <ErrorBoundary key={i} ><ActivityCard link={prop.id} catg={prop.anuncio ? prop.anuncio.skill.name : prop.skill} date={dayjs(prop.dateInitF ? prop.dateInitF : prop.dateInit).format("DD/MM/YYYY")} status={prop.status} /></ErrorBoundary>
        }) : <p>Você não tem nenhuma solicitação no momento</p>
    }

    if (loading) return <Loading />

    return (
        <div className='dashboard col-xs-12' style={{ maxWidth: "980px" }}>
            <div className="row center-xs">
                <div className={'col-xs-12 col-md-8 last-xs first-md'}>
                    <div>
                        <h1>Últimas Atividades</h1>
                    </div>
                    <div className="row center-xs">
                        {player &&

                            <ProposeList />
                            
                        }
                    </div>
                </div>
                <div className={'calendarContainer col-xs-12 col-md-4 first-xs last-md'}>
                    <div>
                        <h1>Calendário</h1>
                    </div>
                    <ErrorBoundary><Calendar /></ErrorBoundary>
                </div>
            </div>

        </div>
    )
}

export default Dashboard