import { gql } from "@apollo/client"

export const LastComment = gql`
query ($userId: ID){
  allComments(filter: {
    toId: $userId
  }, 
  # last: 2,
    # orderBy: id_DESC
  ){
    id
    description
    rating
    typeTo
    reply
    createdAt
    propose {
      anuncio {
        skill {
          name
        }
      }
    }
  }
}
`

export const PositiveComment = gql`
query ($userId: ID){
  allComments(filter: {
    toId: $userId
    typeTo: PROFISSIONAL
    rating: POSITIVE
  }){
    id
    description
    rating
    propose {
      anuncio {
        skill {
          name
        }
      }
    }
  }
}
`

export const NeutralComment = gql`
query ($userId: ID){
  allComments(filter: {
    toId: $userId
    typeTo: PROFISSIONAL
    rating: NEUTRAL
  }){
    id
    description
    rating
    propose {
      anuncio {
        skill {
          name
        }
      }
    }
  }
}
`

export const NegativeComment = gql`
query ($userId: ID){
  allComments(filter: {
    toId: $userId
    typeTo: PROFISSIONAL
    rating: NEGATIVE
  }){
    id
    description
    rating
    propose {
      anuncio {
        skill {
          name
        }
      }
    }
  }
}
`


export const ClientComment = gql`
query LastComment($userId: ID){
  allComments(filter: {
    toId: $userId
    typeTo: CLIENTE
  },
  #  orderBy: id_DESC
  ){
    id
    description
    rating
    typeTo
    reply
    createdAt
    propose {
      anuncio {
        skill {
          name
        }
      }
    }
  }
}
`

export const ProComment = gql`
query ($userId: ID){
  allComments(filter: {
    toId: $userId
    typeTo: PROFISSIONAL
  },
  #  orderBy: id_DESC
  ){
    id
    description
    rating
    typeTo
    reply
    createdAt
    propose {
      anuncio {
        skill {
          name
        }
      }
    }
  }
}
`
export const PositiveCommentC = gql`
query ($userId: ID){
  allComments(filter: {
    toId: $userId
    typeTo: CLIENTE
    rating: POSITIVE
  }){
    id
    description
    rating
    propose {
      anuncio {
        skill {
          name
        }
      }
    }
  }
}
`

export const NeutralCommentC = gql`
query ($userId: ID){
  allComments(filter: {
    toId: $userId
    typeTo: CLIENTE
    rating: NEUTRAL
  }){
    id
    description
    rating
    propose {
      anuncio {
        skill {
          name
        }
      }
    }
  }
}
`

export const NegativeCommentC = gql`
query ($userId: ID){
  allComments(filter: {
    toId: $userId
    typeTo: CLIENTE
    rating: NEGATIVE
  }){
    id
    description
    rating
    propose {
      anuncio {
        skill {
          name
        }
      }
    }
  }
}
`

export const LastCommentPro = gql`
query ($userId: ID){
  allComments(filter: {
    toId: $userId
    typeTo: PROFISSIONAL
  }, 
  # last: 2,
    # orderBy: id_DESC
  ){
    id
    description
    rating
    typeTo
    reply
    createdAt
    propose {
      anuncio {
        skill {
          name
        }
      }
    }
  }
}
`

export const LastCommentCli = gql`
query ($userId: ID){
  allComments(filter: {
    toId: $userId
    typeTo: CLIENTE
  },
  #  last: 4,
    # orderBy: id_DESC
  ){
    id
    description
    rating
    typeTo
    reply
    createdAt
    propose {
      anuncio {
        skill {
          name
        }
      }
    }
  }
}
`

export const Reply = gql`
mutation (
    $id: ID!,
    $reply: String
     ){
  updateComment(
    id: $id, input: {
      reply : $reply
    }){
    id
    description
    reply
  }
}
`