import { gql } from "@apollo/client"

export const CreatePropose = gql`
mutation CreatePropose(
    $dateInit: String!,
    $dateEnd: String,
    $timeInit: String,
    $timeEnd: String,
    $location: String,
    $status: String!,
    $valueInit: String,
    $userPro: ID!,
    $anuncioId: ID!,
    $description: String!){
  createPropose(
    input: {
      dateInit: $dateInit
  dateEnd: $dateEnd
  timeInit: $timeInit
  timeEnd: $timeEnd
  status: $status
  location: $location
  valueInit: $valueInit
  toId: $userPro
  description: $description
  anuncioId: $anuncioId
    }
  ){
id
anuncio {
  id
  skill {
    id
    name
  }
}
from {
      id
      name
      email
      phone
    }
    to {
      id
      name
      email
      phone
    }
}
}
`

// export const Proposes3 = gql`
// query($id: ID){
// user( id: $id ){
//   propoes(last: 3) {
//     id
//     valueInit
//     valueEnd
//     status
//     skill
//     location
//     dateInit
//     dateInitF
//     locationF
//     dateEnd
//     createdAt
//     anuncio {
//       id
//       skill {
//         id
//         name
//       }
//     }
//   }
//   proposesFrom(last: 3) {
//     id
//     valueInit
//     valueEnd
//     status
//     skill
//     dateInit
//     dateInitF
//     location
//     locationF
//     dateEnd
//     createdAt
//     anuncio {
//       id
//       skill {
//         id
//         name
//       }
//     }
//   }
// }
// }
// `

export const Proposes3 = gql`
query($id: ID!){
user( id: $id ){
  proposes {
    id
    valueInit
    valueEnd
    status
    location
    dateInit
    dateInitF
    locationF
    dateEnd
    createdAt
    anuncio {
      id
      skill {
        id
        name
      }
    }
  }
  proposesFrom {
    id
    valueInit
    valueEnd
    status
    dateInit
    dateInitF
    location
    locationF
    dateEnd
    createdAt
    anuncio {
      id
      skill {
        id
        name
      }
    }
  }
}
}
`

export const ProposeCli = gql`
query($id: ID!){
user( id: $id ){
  proposesFrom(orderBy: { createdAt: "desc"}) {
    id
    valueInit
    valueEnd
    status
    dateInit
    location
    locationF
    createdAt
    anuncio {
      id
      skill {
        id
        name
      }
    }
  }
}
}`

export const ProposePro = gql`
query($id: ID!){
user( id: $id ){
  proposes(orderBy: { createdAt: "desc"}) {
    id
    valueInit
    valueEnd
    status
    dateInit
    location
    locationF
    createdAt
    anuncio {
      id
      skill {
        id
        name
      }
    }
  }
}
}
`

export const ProposeAgenda = gql`
query($id: ID!){
user(id: $id){
  proposes(orderBy: { createdAt: "asc"}) {
    id
    valueInit
    valueEnd
    status
    locationF
    skill
    dateInit
    dateInitF
    anuncio {
      id
      skill {
        id
        name
      }
    }
    dateEnd
    location
    dateEndF
    timeEnd
    timeEndF
    timeInit
    timeInitF
    createdAt
  }
  proposesFrom(orderBy: { createdAt: "asc"}) {
    id
    valueInit
    valueEnd
    status
    skill
    dateInit
    dateInitF
    anuncio {
      id
      skill {
        id
        name
      }
    }
    dateEnd
    location
    dateEndF
    timeEnd
    timeEndF
    timeInit
    timeInitF
    createdAt
  }
}
}
`

export const ProposeId = gql`
query($id: ID!){
  propose( id: $id ){
    id
    createdAt
    dateEnd
    dateEndF
    dateInit
    locationF
    dateInitF
    location
    description
    aceite
    anuncio {
      id
      skill {
        id
        name
      }
    }
    from{
      id
      name
      email
      photo
      type
      phone
      address {
        state
        city
      }
    }
    status
    timeEnd
    timeEndF
    timeInit
    timeInitF
    to{
      id
      name
      email
      photo
      phone
      type
      address {
        state
        city
      }
    }
    valueEnd
    valueInit
    ranks {
      from {
        id
         name
      }
    }
  }
}
`

export const ChangeStatus = gql`
mutation ChangeStatus($id: ID!, $status: String){
  updatePropose(
    id: $id,
    input: {
      status: $status
      }){
      id
      status
  }
}
`

export const UpdatePropose = gql`
mutation ($id: ID!, $dateEndF: String, $dateInitF: String, $timeEndF: String,
$timeInitF: String, $valueEnd: String, $description: String, $locationF: String){
  updatePropose(
    id: $id, 
    input: {
      dateEndF: $dateEndF,
      dateInitF: $dateInitF,
      timeEndF: $timeEndF,
      timeInitF: $timeInitF,
      valueEnd: $valueEnd,
      description: $description
      locationF: $locationF
    }){
      createdAt
      dateEnd
      dateEndF
      dateInit
      dateInitF
      description
      id
      locationF
      status
      timeEnd
      timeEndF
      timeInit
      location
      timeInitF
      valueEnd
      valueInit
      aceite
    }
}
`

export const ProposesCalendar = gql`
query ($id: ID){
                    from: allProposes(filter: {
                        fromId: $id
                    }){
                      id
                      dateInit
                      dateInitF
                      description
                      createdAt
                      from {
                        name
                      }
                      to {
                        name
                      }
                      anuncio {
                        skill {
                          name
                        }
                      }
                    }
                    to: allProposes(filter: {
                          toId: $id
                    }){
                      id
                      dateInit
                      dateInitF
                      description
                      createdAt
                      from {
                        name
                      }
                      to {
                        name
                      }
                      anuncio {
                        skill {
                          name
                        }
                      }
                    }
                  }
`

export const AcceptPropose = gql`
mutation ($id: ID!){
                    updatePropose(
                        id: $id,
                        input: {
                            aceite: true,
                            status: "Serviço Aceito"
                        }){
                            id
                        }
                }
`