import { gql } from "@apollo/client";

export const CreateNotification = gql`
  mutation CreateNotification(
    $userId: ID!
    $title: String!
    $description: String!
    $link: String!
  ) {
    createNotification(
      input: {
        userId: $userId
        title: $title
        description: $description
        link: $link
        status: false
      }
    ) {
      id
    }
  }
`;

// export const NotificationUser = gql`
// query ($id: ID){
//   user( id: $id ){
//     name
//     notifications(orderBy: id_DESC){
//       id
//       createdAt
//       description
//       link
//       status
//       title
//     }
//   }
// }
// `

export const NotificationUser = gql`
  query($id: ID!) {
    user(id: $id) {
      name
      notifications(orderBy: { createdAt: "desc"}) {
        id
        createdAt
        description
        link
        status
        title
      }
    }
  }
`;

export const ReadNotification = gql`
  mutation ReadNotification($id: ID!) {
    updateNotification(id: $id, input: { status: true }) {
      id
      createdAt
      description
      link
      status
      title
    }
  }
`;

export const NotificationsResume = gql`
  query($id: ID!) {
    allNotifications(filter: { userId: $id }, orderBy: { createdAt: "desc" }) {
      id
      createdAt
      description
      link
      status
      title
      user {
        name
      }
    }

    allNotificationsMeta(filter: { userId: $id, status: false }) {
      count
    }
  }
`;
