import React from "react"

import './terms.scss'

import { Link } from 'react-router-dom'
import ErrorBroundray from '../../components/errorBoundary/errorBoundary'

import Navbar from '../../components/newNavbar/navbar'
import ButtonText from '../../components/buttonText/buttonText'
import Footer from '../../components/newFooter/footer'

export default () => <>
    <ErrorBroundray><Navbar /></ErrorBroundray>
    <article className={'terms col-xs-12'} style={{ maxWidth: "980px" }}>
        <section>
            <h1> TERMOS DE SERVIÇO E PRIVACIDADE DE DADOS DO SITE OITO APP</h1>

            <h3>CONDIÇÕES GERAIS</h3>
            <p id="1">
                Este documento visa estabelecer as responsabilidades, deveres e obrigações que todo USUÁRIO e ANUNCIANTE assumem ao acessar o SITE OITO APP na Internet, sendo complementar aos contratos, termos adicionais e outros documentos firmados diretamente com o USUÁRIO e o ANUNCIANTE.{" "}
            </p>

            <p id="2">
                Para os fins destes Termos: SITE OITO APP é o website https://oito.app, de propriedade de OITO ATIVIDADES DE INTERNET S.A., pessoa jurídica de direito privado inscrita no CNPJ/MF sob o nº 33.113.323/0001-40; USUÁRIO é toda pessoa física que acessa o OITO APP; ANUNCIANTE é toda pessoa física que insere anúncios, oferece serviços ou outro conteúdo no SITE OITO APP.{" "}
            </p>

            <p id="3">
                O simples acesso e uso do SITE OITO APP configura a total concordância do USUÁRIO e do ANUNCIANTE com os termos, condições e informações a seguir. {" "}
            </p>

            <p id="4">
                O SITE OITO APP disponibiliza espaço para a hospedagem de anúncios provenientes de diversos ANUNCIANTES (marketplace) e possibilita que USUÁRIOS e ANUNCIANTES se comuniquem entre si. O SITE OITO APP não atua como prestador ou fornecedor dos serviços anunciados em seu site, ou mesmo de consultoria, supervisão ou ainda intermediário ou participante em nenhum negócio entre o USUÁRIO e o ANUNCIANTE.
                        </p>

            <p id="5">
                Dessa forma, o SITE OITO APP não assume responsabilidade por nenhuma consequência que possa advir da relação direta ou indireta estabelecida entre o USUÁRIO e o ANUNCIANTE, incluindo, mas não se limitando; danos indiretos, incidentais, especiais, punitivos ou emergentes, inclusive lucros cessantes, perda de dados, danos morais ou patrimoniais relacionados, associados ou decorrentes de qualquer uso dos serviços ainda que o SITE OITO APP tenha sido alertado para a possibilidade desses danos.{" "}
            </p>

            <p id="6">
                O SITE OITO APP não é responsável por qualquer ação ou omissão do ANUNCIANTE baseada nas informações, oferta de serviços, anúncios, fotos, vídeos ou outros materiais inseridos no SITE OITO APP, sendo certo que o SITE OITO APP envida seus melhores esforços para manter o conteúdo sempre atualizado. Não se responsabiliza por imprecisão, erro, fraude, inexatidão ou divergência nos dados, fotos e vídeos ou outros materiais relacionados a anúncios ou à imprecisão das informações aqui contida e se isenta de qualquer responsabilidade pela disponibilidade, pontualidade, segurança ou confiabilidade dos serviços anunciados no SITE OITO APP.
                        </p>

            <p id="7">
                Nos termos dos art. 166, I; 171, I e 180 do Código Civil Brasileiro, os serviços do SITE OITO APP estão disponíveis apenas para as pessoas que tenham capacidade legal para contratá-los. Não podem utilizá-los, assim, pessoas que não gozem dessa capacidade, inclusive menores de idade (arts. 3º, 4ºe 5º do Código Civil).
                        </p>

            <p id="8">
                Não podem utilizar os serviços do SITE OITO APP pessoas que tenham sido inabilitadas por qualquer razão pelo próprio site, temporária ou definitivamente.
                        </p>

            <p id="9">
                O SITE OITO APP se resguarda o direito de suspender, inabilitar ou excluir a qualquer tempo e sem aviso prévio, cadastros de USUÁRIOS ou ANUNCIANTES que estejam em desacordo com os Termos de Uso. Todas as partes envolvidas, inclusive terceiros, se obrigam a eximir o SITE OITO APP, seus funcionários, agentes, representantes e procuradores, de qualquer responsabilização judicial ou extrajudicial que advenha de relação entre USUÁRIOS e ANUNCIANTES. O SITE OITO APP não se responsabiliza, ainda, por eventuais obrigações tributárias ou trabalhistas atinentes a negócios celebrados entre USUÁRIOS e ANUNCIANTES.
                        </p>

            <p id="10">
                ANUNCIANTE e USUÁRIO estão cientes de que o conteúdo publicado no SITE OITO APP é público e acessível a terceiros, podendo ser listado nos resultados de ferramentas de pesquisa, que não são de responsabilidade do SITE OITO APP.
                        </p>

            <p id="11">
                A empresa proprietária do SITE OITO APP se reserva no direito de modificar, suprimir e/ou ampliar, livremente e a qualquer tempo, sem comunicação prévia o conteúdo, serviços, produtos e outros elementos do SITE OITO APP, ou mesmo encerrar suas atividades sem aviso prévio e sem possibilidade de indenização. O mesmo se aplica ao presente termo de uso, pelo que USUÁRIOS e ANUNCIANTES deverão revê-lo periodicamente.
                        </p>

            <h3>CONDIÇÕES DE USO</h3>
            <p id="12">
                O SITE OITO APP disponibiliza, exclusivamente para pessoas físicas, espaço para anúncio de serviços, sendo certo que não serão aceitos pedidos de cadastro de pessoas jurídicas como ANUNCIANTES.
                        </p>
            <p id="13">
                Aos ANUNCIANTES, o SITE OITO APP oferece:
                        </p>
            <ol>
                <li>
                    Cadastro de serviço, cadastro de produtos, Inclusão de fotos/ vídeos, divulgação de serviços/ produtos, chat entre ANUNCIANTE E USUÁRIO e pesquisa de profissionais disponíveis.
                            </li>
                <li>
                    Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta (incluindo-se opiniões e comentários). Também não se permitirá a manutenção de mais de um cadastro por uma mesma pessoa, ou ainda a criação de novos cadastros por pessoas cujos cadastros originais tenham sido suspensos temporária ou definitivamente por infrações às políticas do SITE OITO APP.
                            </li>
                <li>
                    O ANUNCIANTE que captar um cliente (USUÁRIO) por meio do SITE OITO APP deverá concluir o serviço por meio do site, sendo vedado que o ANUNCIANTE direcione o USUÁRIO para outros veículos para conclusão do negócio.
                            </li>
                <li>
                    A comunicação entre ANUNCIANTE e USUÁRIO se dará exclusivamente pelo SITE OITO APP via chat.
                            </li>
                <li>
                    O serviço de chat aqui disponibilizado permite ao SITE OITO APP acesso a toda comunicação realizada entre ANUNCIANTE e USUÁRIO.
                            </li>
            </ol>
            <p id="14">
                O SITE OITO APP poderá iniciar uma cobrança percentual, mediante comunicação prévia, que incidirá sobre o valor total de todo e qualquer negócio celebrado entre ANUNCIANTE e USUÁRIO, que dependerá do formato de contratação entre o SITE OITO APP e o ANUNCIANTE, escolhido por este.
                        </p>
            <p id="15">
                O ANUNCIANTE se compromete a fornecer informações verídicas, responsabilizando-se por quaisquer informações veiculadas através de seus dados de login e senha de acesso.
                        </p>
            <p id="16">
                O SITE OITO APP recomenda expressamente ao USUÁRIO que:
                        </p>
            <ol>
                <li>
                    Verifique cautelosamente toda a documentação e/ou características de qualquer dos produtos ou serviços ofertados antes da conclusão de qualquer negócio, concordando que ao negociar com o ANUNCIANTE dos produtos e serviços ofertados o faz por sua conta e risco.
                            </li>
                <li>
                    Seja cuidadoso com os dados de sua identificação individual sempre que acessar a Internet, informando-os apenas em operações em que exista a proteção de dados.
                            </li>
                <li>
                    Em caso de preços e condições muito convidativas, certifique-se quanto a veracidade da informação e confiabilidade do ANUNCIANTE. Cumpre ressaltar que eventuais pagamentos realizados não passam pelo SITE OITO APP, que não se responsabiliza por quaisquer transações financeiras entre USUÁRIOS e ANUNCIANTES;
                            </li>
                <li>
                    Caso o USUÁRIO acesse outros sites através de links no SITE OITO APP e sejam solicitadas informações pessoais ou financeiras, o SITE OITO APP não possui qualquer responsabilidade sobre o manejo de tais informações.
                            </li>
                <li>
                    Todos os cuidados devem ser tomados para se proteger de danos, inclusive fraudes ou estelionato online.
                            </li>
            </ol>
            <p id="17">
                Este SITE OITO APP pode conter hyperlinks para outros portais operados por terceiros. Tais hyperlinks são apenas referenciais, ou seja, o SITE OITO APP não tem controle sobre outros websites e portais da Internet para os quais os hyperlinks redirecionam e não se responsabiliza pelo conteúdo dos mesmos. A inclusão desses hyperlinks não implica na aprovação pelo SITE OITO APP do material ou qualquer associação com seus operadores. Ao acessar e usar esses outros websites - incluindo as informações, materiais, produtos e serviços dos mesmos -, o USUÁRIO estará fazendo-o por sua conta e risco. O mesmo se aplica para portais que possuam hyperlinks para o SITE OITO APP, que, por sua vez, serão permitidos desde que não apresente conteúdo enganoso ou depreciativo.
                        </p>
            <p id="18">
                O USUÁRIO poderá acessar, utilizar e imprimir materiais deste SITE OITO APP para seu uso pessoal e não-comercial, contudo não poderá copiar, distribuir, transmitir, exibir, reproduzir, modificar, publicar, licenciar, criar trabalho derivado, colocar e/ou usar em outra página da Internet, transferir ou vender qualquer informação, software, solução tecnológica, lista de USUÁRIOS, produtos ou serviços obtidos no SITE OITO APP, para uso comercial. Esta proibição expressamente inclui, mas não se limita, à prática de screen scraping ou database scraping para obter listas de USUÁRIOS ou outras informações para uso comercial ou não.
                        </p>
            <p id="19">
                Mesmo que USUÁRIOS e ANUNCIANTES estejam cientes de que o SITE OITO APP não realiza nenhuma seleção ou pré-aprovação de conteúdo, anunciante ou usuário, esse se reserva o direito de recusar qualquer solicitação de cadastro e de cancelar um cadastro previamente aceito, à seus exclusivos critérios de oportunidade e conveniência, e sem aviso prévio, seja por violação às condições ora expostas ou mesmo por razões de ordem ética, legal ou moral, não cabendo nenhuma indenização ou reparação pelo cancelamento de quaisquer cadastros ou de impossibilidade de cadastro.
                        </p>
            <p id="20">
                O SITE OITO APP não permite a USUÁRIOS e ANUNCIANTES:
                        </p>
            <ol>
                <li>
                    utilizar os produtos e/ou serviços disponíveis no SITE OITO APP para fins diversos daqueles a que se destinam;
                            </li>
                <li>
                    enviar ou transmitir quaisquer tipos de informações que induzam, incitem ou resultem em atitudes discriminatórias, mensagens violentas ou delituosas, de conteúdo nocivo, abusivo, difamatório, pornográfico, libidinoso, ilegais, que de qualquer forma represente assédio, invasão de privacidade, risco a menores ou que atentem contra a moral e bons costumes e contrariem a ordem pública;
                            </li>
                <li>
                    utilizar os dados para contato de ANUNCIANTES com outro propósito que não seja o de encaminhar proposta comercial;
                            </li>
                <li>
                    cadastrar-se com informações falsas ou de propriedade de terceiros;
                            </li>
                <li>
                    enviar ou transmitir qualquer tipo de informação que seja de propriedade de terceiros;
                            </li>
                <li>
                    alterar, apagar ou corromper dados e informações de outros USUÁRIOS, ANUNCIANTES ou terceiros;
                            </li>
                <li>
                    violar a privacidade de outros USUÁRIOS e ANUNCIANTES;
                            </li>
                <li>
                    enviar ou transmitir arquivos com vírus de computador, com conteúdo destrutivo, invasivo ou que causem dano permanente ou temporário aos equipamentos do destinatário e/ou do SITE OITO APP;
                            </li>
                <li>
                    utilizar endereços de computadores, de rede ou de correio eletrônico falsos;
                            </li>
                <li>
                    violar propriedade intelectual (direito autoral, segredo comercial, marca, patente etc) de terceiros, por meio de qualquer tipo de reprodução de material, sem a prévia autorização do proprietário;
                            </li>
                <li>
                    distribuir cópias do SITE OITO APP;
                            </li>
                <li>
                    utilizar apelido que guarde semelhança com o nome do SITE OITO APP;
                            </li>
                <li>
                    utilizar qualquer apelido que insinue ou sugira que os produtos ou serviços anunciados pertencem a SITE OITO APP ou que fazem parte de promoções suas;
                            </li>
                <li>
                    utilizar apelidos considerados ofensivos, bem como os que contenham dados pessoais do USUÁRIO ou alguma URL ou endereço eletrônico.
                            </li>
                <li>
                    utilizar propagandas enganosas que possam induzir o USUÁRIO a erro;
                            </li>
                <li>
                    difamar, agredir ou injuriar outros USUÁRIOS ou ANUNCIANTES;
                            </li>
                <li>
                    desrespeitar a legislação vigente.
                            </li>
            </ol>
            <p id="21">
                A inobservância de qualquer dessas condições e dos termos de uso deste SITE OITO APP ensejará o cancelamento ou suspensão de seu cadastro, temporariamente ou de modo definitivo, sem prejuízo das cominações legais pertinentes.
                        </p>

            <h3>POLÍTICA DE PRIVACIDADE.</h3>
            <p id="22">
                Todo o material do SITE OITO APP, quais sejam, apresentação e "layout", marcas, logotipos, produtos, sistemas, denominações de serviços e outros materiais, incluindo programas, bases de dados, imagens, arquivos ou materiais de qualquer outra espécie e que têm contratualmente autorizadas as suas inserções neste SITE OITO APP, é protegido pela legislação de propriedade intelectual e direitos autorais, sendo de titularidade do SITE OITO APP. A reprodução, distribuição e transmissão de tais materiais não são permitidas sem o expresso consentimento por escrito da proprietária do SITE OITO APP ou do respectivo titular, especialmente para fim comercial ou econômico.
                        </p>
            <p id="23">
                O uso indevido de materiais protegidos por propriedade intelectual (direito autoral, marcas comerciais, patentes etc.) apresentados no SITE OITO APP será caracterizado como infração da legislação pertinente, sujeitando o infrator às ações judiciais cabíveis e dando ensejo à respectiva indenização aos prejudicados, seja ao SITE OITO APP, seja a terceiros, sem prejuízo de perdas e danos e honorários advocatícios, guiadas pelos princípios de privacy by design e pelas Leis Brasileiras aplicáveis, incluindo, mas sem se limitar, o Marco Civil da Internet.
                        </p>
            <p id="24">
                ANUNCIANTE e USUÁRIO possuem a prerrogativa de não aceitar a utilização de cookies pelo SITE OITO APP. Para tanto, basta desabilitá-los nas configurações de seu próprio navegador de Internet.
                        </p>
            <p id="25">
                ANUNCIANTE e USUÁRIO, desde já, autorizam o SITE OITO APP a preservar e armazenar todas as informações inseridas em seu conteúdo e a ele submetidas de qualquer forma, bem como todos os seus dados pessoais, a exemplo de endereços de e-mail, endereços de IP (Internet Protocol), informações de data e horário de acesso, entre outras informações. O USUÁRIO também autoriza o SITE OITO APP, a seus exclusivos critérios de oportunidade e conveniência, a informar e/ou divulgar estes dados em caso de exigência legal ou se forem, razoavelmente, necessárias para: (a) obedecer ao princípio do devido processo legal; (b) fazer cumprir os Termos de Uso; (c) responder a alegações de suposta violação de direitos de terceiros e de divulgação indevida de informações para contato de terceiros e para proteger os direitos, a propriedade ou a segurança de terceiros ou do próprio SITE OITO APP e de seus ANUNCIANTES e USUÁRIOS.
                        </p>
            <p id="26">
                O SITE OITO APP coleta informações capazes de identificar os ANUNCIANTES e os USUÁRIOS de diferentes formas e em diferentes contextos de sua utilização, tais como: quando o ANUNCIANTE e o USUÁRIO se cadastram no SITE OITO APP; quando preenchem quaisquer formulários existentes no conteúdo do SITE OITO APP; quando enviam mensagens de contato; quando enviam mensagens através do chat do SITE OITO APP; quando participam de promoções ou concursos; dentre outras formas.
                        </p>
            <p id="27">
                Para cada modalidade de coleta de informações poderão ser solicitados dados diferentes, de acordo com a finalidade a que se destinam. Os ANUNCIANTES e USUÁRIOS serão sempre informados sobre quais informações estão sendo solicitadas, ficando a seu exclusivo critério fornecê-las ou não.
                        </p>
            <p id="28">
                As informações coletadas através do SITE OITO APP serão utilizadas somente pela equipe do SITE OITO APP com a única finalidade de identificar o público que o acessa e de personalizar conteúdo e serviços. Essas informações são tratadas como confidenciais e não são divulgadas a terceiros sem autorização, sem a determinação da autoridade competente ou sem que concorram quaisquer das hipóteses previstas nestes termos.
                        </p>
            <p id="29">
                Ao cadastrar-se no SITE OITO APP ou ao preencher quaisquer formulários existentes em seu conteúdo, ANUNCIANTE e USUÁRIO autorizam o envio de mensagens para o e-mail ou número de celular cadastrado e obrigam-se a manter seu cadastro atualizado para que recebam as mensagens corretamente.
                        </p>
            <p id="30">
                Para que o ANUNCIANTE e o USUÁRIO possam acessar e utilizar determinados recursos tecnológicos do SITE OITO APP, pode-se exigir o seu cadastro através de Nome de Usuário e Senha. O SITE OITO APP, a empresa proprietária ou quaisquer de seus colaboradores não têm acesso à senha cadastrada. É responsabilidade exclusiva do ANUNCIANTE e do USUÁRIO guardar e proteger sua senha e garantir que o acesso seja feito somente por pessoas autorizadas.
                        </p>
            <p id="31">
                O ANUNCIANTE e o USUÁRIO declaram expressamente que são os únicos responsáveis pela administração de suas respectivas senhas, eximindo-se o SITE OITO APP de quaisquer responsabilidades pela má utilização do Nome de Usuário ou da Senha cadastrada. Recomenda-se o uso de senhas complexas, pois senhas simples são fáceis de serem quebradas ou descobertas.
                        </p>
            <p id="32">
                Quando o ANUNCIANTE ou o USUÁRIO acessam o SITE OITO APP autorizam automaticamente a utilização de cookies. Cookies são arquivos de texto que identificam o ANUNCIANTE e o USUÁRIO e são arquivados em seus computadores para serem lidos pelo sistema de tecnologia do SITE OITO APP todas as vezes que o acessam e para acompanhar e armazenar informações sobre a navegação do ANUNCIANTE e do USUÁRIO na Internet.
                        </p>
            <p id="33">
                ANUNCIANTE e USUÁRIO desde já declaram estarem cientes que o SITE OITO APP não assume nenhuma responsabilidade em caso de roubo, perda, alteração ou uso indevido de suas informações pessoais e do Conteúdo como um todo, inclusive na hipótese de informações fornecidas a prestadores de serviços terceirizados ou a outros Anunciantes e Usuários e na hipótese de descumprimento destes Termos de Uso.
                        </p>
            <p id="34">
                Quaisquer riscos tecnológicos, como roubo, perda, alteração ou uso indevido de informações, necessidade de serviços ou reposição de material, equipamento ou informação e informações fornecidas por anunciantes, são de responsabilidade de ANUNCIANTES e USUÁRIOS.
                        </p>
            <p id="35">
                Em nenhum caso o SITE OITO APP ou sua proprietária serão responsabilizados por qualquer dano direto, indireto, incidental, especial ou como consequência de quaisquer fatos resultantes do uso do SITE OITO APP ou da inabilidade de uso deste, ou ainda por quaisquer informações, produtos ou serviços obtidos através dele ou em decorrência do seu uso.
                        </p>
            <p id="36">
                Em caso de conflito entre estes termos de serviço e os documentos adicionais e contratos celebrados diretamente entre O SITE OITO APP e ANUNCIANTES ou USUÁRIOS, os termos adicionais e contratos diretos prevalecerão com relação a esse conflito.
                        </p>
            <p id="37">
                O descumprimento das condições de uso e política de privacidade do SITE OITO APP poderá gerar as medidas judiciais cabíveis. A inércia do SITE OITO APP em instituir tais medidas não significará renúncia de direitos.
                        </p>
            <p id="38">
                Fica eleito o foro da Comarca de São Paulo (SP), como competente para dirimir quaisquer controvérsias decorrentes deste Termo de Uso independentemente de qualquer outro, por mais privilegiado que seja.
                        </p>
        </section>
        <section>
            <Link to={'/'}><ButtonText icon={'faChevronLeft'} name={'voltar'} /></Link>
        </section>
    </article>
    <ErrorBroundray><Footer /></ErrorBroundray>
</>
