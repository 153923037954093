import React from 'react'
import { BrowserRouter } from 'react-router-dom'
// import { HashRouter } from 'react-router-dom'
import Routes from './Routes'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'font-awesome/css/font-awesome.min.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './app.scss'
import Helmet from 'react-helmet'
import ScrollToTop from 'react-router-scroll-top'
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module'
// import Navbar from "../components/newNavbar/newNavbar";
// import Footer from "../components/newFooter/newFooter";

const tagManagerArgs = {
    gtmId: 'GTM-W7SPCQR'
};

TagManager.initialize(tagManagerArgs);

const hjid = 1945614
const hjsv = 6
 
hotjar.initialize(hjid, hjsv);


export default class props extends React.Component {

    render() {

        return (
            <BrowserRouter>
                <ScrollToTop>
                    <div className='row center-xs'>
                        <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                            <meta httpEquiv="cache-control" content="max-age=0" />
                            <meta httpEquiv="cache-control" content="no-cache" />
                            <meta httpEquiv="expires" content="0" />
                            {/* <meta httpEquiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT" /> */}
                            <meta httpEquiv="pragma" content="no-cache" />
                            <script
                                type="module"
                                src="https://cdn.jsdelivr.net/npm/@pwabuilder/pwainstall"
                            ></script>
                            <pwa-install />
                        </Helmet>
                        {/*{window.screen.width < 1024 || window.screen.height < 768 ? <div className="warning"><h1>Acesse a oito pelo computador para ter uma experiência completa!</h1><img src={Logo} alt="" height="45px"/></div> : <Routes className='main' />}*/}
                        <ToastContainer />
                        {/*<newNavbar/>*/}
                        <Routes />
                        {/*<newFooter/>*/}
                    </div>
                </ScrollToTop>
            </BrowserRouter>
        )
    }
}

