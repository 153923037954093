import React from "react"
import './buttonText.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export default class ButtonText extends React.Component {
    render() {
        const {
            name,
        } = this.props;

        return(
            <button className={'button-text'}>
                <div><FontAwesomeIcon icon={faChevronLeft} size={'1x'}/></div>
                {name}
            </button>
        )
    }
}
