import React from 'react'
import Back from '../backButton/backButton'
import './errorscreen.scss'

export default (props) => <div className="ErrorScreen">
    <div>
        <p>Erro ao conectar com o servidor.</p>
        <p>Tente novamente mais tarde.</p>
    </div>
    {/* {props && props.cod && <div>
        <p>Caso o problema persista informe o código {props.cod} ao suporte.</p>
    </div>} */}
    <Back />
</div>