import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import Loading from '../../../components/loading/loading'
import { useQuery } from "@apollo/client"
import { ProposeCli } from '../../../queries/propose'
import * as dayjs from "dayjs";
import ActivityCard from "../../../components/activityCard/activityCard";

export default () => {
    const cache = JSON.parse(sessionStorage.getItem('OITO'))
    const { data, loading } = useQuery(ProposeCli, {
        variables: {
            id: cache.user.id
        }
    })

    if (loading) return <Loading />

    return (
        <div className="col-xs-12" style={{ maxWidth: "980px" }}>
            <Helmet title='Oito - Solicitações recebidas'>
                <meta name="description" content="Suas solicitações recebidas ficam nessa página. Negocie diretamente com seu cliente ou prestador de serviços e crie conexões mutuamente positivas." />
                <meta name="keywords" content="Profissionais, Solicitações, Oportunidades" />
                <meta name="robots" content="index, follow" />
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="Portuguese" />
                <meta name="revisit-after" content="1 days" />
            </Helmet>

            <h1 className="title-list">Solicitações recebidas</h1>

            <section className="card-list row center-xs">


                {data && data.user && data.user.proposesFrom ?
                    data.user.proposesFrom.map((prop, i) =>
                        <ActivityCard link={prop.id} key={i} catg={prop.anuncio ? prop.anuncio.skill.name : prop.skill} date={dayjs(prop.dateInitF ? prop.dateInitF : prop.dateInit).format("DD/MM/YYYY")} status={prop.status} />
                    ) :
                    <article className="msg-not-found"><h5>Você ainda não recebeu nenhuma solicitação. <Link to="/meusanuncios">Ofereça seus serviços</Link> e ganhe uma grana extra!</h5></article>
                }

            </section>
        </div>
    )
}