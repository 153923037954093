import React from 'react'
import './annoucementCard.scss'

import Tooltip from '../../components/tooltip/tooltip'

export default (props) => {
    const {
        title,
        desc,
        price,
        edit,
        del,
        view,
        annouActive
    } = props;

    // console.log(userInfo);

    return (
        <div className={'announcementCard col-xs-12 row middle-xs'}>
            <div className="title col-xs-12 col-md-3"><h1>{title}</h1></div>
            <div className='desc col-xs-12 col-sm-6 col-md-5'><span>{desc ? desc.length > 30 ? desc.substr(0, 30) + "..." : desc : null}</span></div>
            <div className="col-xs-5 price col-sm-2"><span>R${price}</span></div>
            <div className='action col-xs-7 col-sm-4 col-md-2'>
                <Tooltip text="Editar anúncio" direction="bottom"><button onClick={edit}><svg style={{ width: '24px', height: '24px', color: '#707070' }} viewBox="0 0 24 24">
                    <path fill="#707070" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                </svg></button></Tooltip>
                <Tooltip text={annouActive ? "Desativar anúncio" : "Ativar anúncio"} direction="bottom"><button onClick={del}>
                    <svg style={{ width: '24px', height: '24px', color: '#707070' }} viewBox="0 0 24 24">
                        {/*<path fill="#707070" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />*/}
                        {annouActive ? <path fill="#ff8000" d="M17,7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7M17,15A3,3 0 0,1 14,12A3,3 0 0,1 17,9A3,3 0 0,1 20,12A3,3 0 0,1 17,15Z" />
                            : <path fill="#707070" d="M17,7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7M7,15A3,3 0 0,1 4,12A3,3 0 0,1 7,9A3,3 0 0,1 10,12A3,3 0 0,1 7,15Z" />}
                    </svg>
                </button></Tooltip>
                <Tooltip text="Visualizar anúncio" direction="bottom"><button onClick={view}><svg style={{ width: '24px', height: '24px', color: '#707070' }} viewBox="0 0 24 24">
                    <path fill="#707070" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
                </svg></button></Tooltip>
            </div>
        </div>
    )
}