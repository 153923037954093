import React, { useState } from 'react'
import './profileExib.scss'

import AvaliationStar from "../../components/avaliationStars/avaliationStars";
import Modal from '../../components/Modal/modal'
import Tooltip from '../../components/tooltip/tooltip'

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';

import userLogo from '../../assets/user_logo.svg'

export default (props) => {
    const [show, setShow] = useState(false)
    const {
        imgUrl,
        name,
        city,
        resume,
        rank,
        rlist
    } = props;

    // this.render = this.render.bind(this)


    let proRate = 0
    let agilidadeRate = 0
    let atendimentoRate = 0
    let custoRate = 0
    let ferramentasRate = 0
    let pontualidadeRate = 0
    let respostaRate = 0
    let solucaoRate = 0

    if (rank !== "view" && rlist && rlist.length > 0) {

        let media = []
        let agilidade = []
        let atendimento = []
        let custo = []
        let ferramentas = []
        let pontualidade = []
        let resposta = []
        let solucao = []

        rlist.map(rank => {
            // console.log(rank)
            media.push(rank.media)
            agilidade.push(rank.agilidade)
            atendimento.push(rank.atendimento)
            custo.push(rank.custo)
            ferramentas.push(rank.ferramentas)
            pontualidade.push(rank.pontualidade)
            resposta.push(rank.resposta)
            solucao.push(rank.solucao)
            return null
        })

        proRate = parseInt(media.reduce((sum, num) => sum + num) / media.length)
        agilidadeRate = parseInt(agilidade.reduce((sum, num) => sum + num) / agilidade.length)
        atendimentoRate = parseInt(atendimento.reduce((sum, num) => sum + num) / atendimento.length)
        custoRate = parseInt(custo.reduce((sum, num) => sum + num) / custo.length)
        ferramentasRate = parseInt(ferramentas.reduce((sum, num) => sum + num) / ferramentas.length)
        pontualidadeRate = parseInt(pontualidade.reduce((sum, num) => sum + num) / pontualidade.length)
        respostaRate = parseInt(resposta.reduce((sum, num) => sum + num) / resposta.length)
        solucaoRate = parseInt(solucao.reduce((sum, num) => sum + num) / solucao.length)
    }

    return (
        <div className={'profileExib row'}>
            <div className={'img col-xs-4'}>
                <img src={imgUrl ? imgUrl : userLogo} alt={'Foto Usuário'} />
            </div>
            <div className={'infos col-xs-8'}>
                <h1>{name ? name : 'Nome de usuário'}</h1>
                <span>{city ? city.city + ' - ' + city.state : 'Cidade - Estado'}</span>
                <p>{resume ? resume === "null" ? "" : resume : ''}</p>

                {rank !== "view" && <div className={'socialBtn row between-xs middle-xs'}>
                    <div className={'socialList col-xs-12 col-sm-7'}>
                        <span>Compartilhe este perfil:</span>
                        <FacebookShareButton url={window.location.href}>
                            <FacebookIcon size={30} round />
                        </FacebookShareButton>
                        <LinkedinShareButton url={window.location.href}>
                            <LinkedinIcon size={30} round />
                        </LinkedinShareButton>
                        <TwitterShareButton url={window.location.href}>
                            <TwitterIcon size={30} round />
                        </TwitterShareButton>
                        <TelegramShareButton url={window.location.href}>
                            <TelegramIcon size={30} round />
                        </TelegramShareButton>
                        <WhatsappShareButton url={window.location.href}>
                            <WhatsappIcon size={30} round />
                        </WhatsappShareButton>
                        <EmailShareButton url={window.location.href}>
                            <EmailIcon size={30} round />
                        </EmailShareButton>
                    </div>
                    <div className={'action col-xs-12 col-sm-5'}>
                        <div onClick={() => setShow(!show)}>
                            <div><AvaliationStar avaliation={rank} /></div>
                            {rank < 1 && <div><span>Profissional ainda não foi avaliado</span></div>}
                        </div>
                    </div>
                </div>}
            </div>

            {rank !== "view" && <Modal show={show} title={`Rank`} onClose={() => setShow(!show)} >
                <div className="row center-xs" style={{ maxWidth: "600px", margin: "auto" }}>

                    <div className="performance-card col-xs-12" style={{ margin: "auto" }}>
                        <div className="row center-xs">
                            <div className="col-xs-12 col-sm-7 col-md-8">
                                <div className="row end-sm">
                                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Média</span>
                                    <Tooltip text={`Média geral. Total de avaliadores: ${rlist ? rlist.length : 0}`} />
                                </div>

                            </div>
                            <div className="col-xs-12 col-sm-5 col-md-4">
                                <div className="row"><AvaliationStar avaliation={proRate} />
                                </div></div>
                        </div>
                    </div>
                    <div className="performance-card col-xs-12">
                        <div className="row">
                            <div className="col-xs-12 col-sm-7 col-md-8">
                                <div className="row end-sm">
                                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Agilidade no serviço</span>
                                    <Tooltip text={`O serviço foi feito de forma ágil e eficiente?`} />
                                </div>

                            </div>
                            <div className="col-xs-12 col-sm-5 col-md-4">
                                <div className="row"><AvaliationStar avaliation={agilidadeRate} />
                                </div></div>
                        </div>
                    </div>
                    <div className="performance-card col-xs-12">

                        <div className="row">
                            <div className="col-xs-12 col-sm-7 col-md-8">
                                <div className="row end-sm">
                                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Atendimento</span>
                                    <Tooltip text={`O prestador de serviço atendeu você com respeito, dedicação e transparência?`} />
                                </div>

                            </div>
                            <div className="col-xs-12 col-sm-5 col-md-4">
                                <div className="row"><AvaliationStar avaliation={atendimentoRate} />
                                </div></div>
                        </div>
                    </div>
                    <div className="performance-card col-xs-12">

                        <div className="row">
                            <div className="col-xs-12 col-sm-7 col-md-8">
                                <div className="row end-sm">
                                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Custo-benefício</span>
                                    <Tooltip text={`Como você avalia a relação de valores e entrega do serviço?`} />
                                </div>

                            </div>
                            <div className="col-xs-12 col-sm-5 col-md-4">
                                <div className="row"><AvaliationStar avaliation={custoRate} />
                                </div></div>
                        </div>
                    </div>
                    <div className="performance-card col-xs-12">
                        <div className="row">
                            <div className="col-xs-12 col-sm-7 col-md-8">
                                <div className="row end-sm">
                                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Ferramentas necessárias</span>
                                    <Tooltip text={`O prestador de serviço levou todas as ferramentas necessárias para a execução do serviço?`} />
                                </div>

                            </div>
                            <div className="col-xs-12 col-sm-5 col-md-4">
                                <div className="row"><AvaliationStar avaliation={ferramentasRate} />
                                </div></div>
                        </div>
                    </div>
                    <div className="performance-card col-xs-12">
                        <div className="row">
                            <div className="col-xs-12 col-sm-7 col-md-8">
                                <div className="row end-sm">
                                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Pontualidade</span>
                                    <Tooltip text={`Todos os prazos combinados durante a negociação e execução dos serviços foram cumpridos sem atrasos?`} />
                                </div>

                            </div>
                            <div className="col-xs-12 col-sm-5 col-md-4">
                                <div className="row"><AvaliationStar avaliation={pontualidadeRate} />
                                </div></div>
                        </div>
                    </div>
                    <div className="performance-card col-xs-12">
                        <div className="row">
                            <div className="col-xs-12 col-sm-7 col-md-8">
                                <div className="row end-sm">
                                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Respostas no chat</span>
                                    <Tooltip text={`O prestador de serviço usou o chat de forma ágil, respeitosa e clara?`} />
                                </div>

                            </div>
                            <div className="col-xs-12 col-sm-5 col-md-4">
                                <div className="row"><AvaliationStar avaliation={respostaRate} />
                                </div></div>
                        </div>
                    </div>
                    <div className="performance-card col-xs-12">
                        <div className="row">
                            <div className="col-xs-12 col-sm-7 col-md-8">
                                <div className="row end-sm">
                                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Solução do problema</span>
                                    <Tooltip text={`O prestador de serviço contratado solucionou seu problema de forma eficiente?`} />
                                </div>

                            </div>
                            <div className="col-xs-12 col-sm-5 col-md-4">
                                <div className="row"><AvaliationStar avaliation={solucaoRate} />
                                </div></div>
                        </div>
                    </div>
                </div>

            </Modal>}
        </div>
    )
}
