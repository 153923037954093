/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import './profileLoggedIn.scss'

import userLogo from '../../assets/user_logo.svg'
import ButtonText from "../buttonText/buttonText";
import AvaliationStar from "../avaliationStars/avaliationStars"
import { useLazyQuery } from "@apollo/client"
import { ProfileData } from "../../queries/player"
import { Link, NavLink } from 'react-router-dom'
import ErrorBroundray from '../../components/errorBoundary/errorBoundary'
import ElementLoading from "../elementLoading/elementLoading"

export default (props) => {
    const { userInfo } = props;
    const [getUserData, { loading, data }] = useLazyQuery(ProfileData)

    useEffect(() => {
        userInfo && getUserData({ variables: { id: userInfo.id } })
    }, [userInfo, getUserData])

    const MoneyProposePro = () => {
        let count = 0
        if (data && data.proposePro.user && data.proposePro.user.proposes.length) {
            const propostas = data.proposePro.user.proposes

            propostas.map(prop => (
                prop.valueEnd && prop.status === "Finalizada" ?
                    (count = parseFloat(count) + parseFloat(prop.valueEnd)) : null
            ))

        }

        return <span><strong>R$ {count === 0 ? "0,00" : count}</strong></span>
    }

    const MoneyProposeCli = () => {
        let count = 0

        if (data && data.proposeCli.user && data.proposeCli.user.proposesFrom.length) {
            const propostas = data.proposeCli.user.proposesFrom

            propostas.map(prop => (
                prop.valueEnd && typeof prop.valueEnd !== "string" && prop.valueEnd !== null ?
                    (count = parseFloat(count) + parseFloat(prop.valueEnd)) : null
            ))

        }

        return <span><strong>R$ {count === 0 ? "0,00" : count}</strong></span>
    }

    const RankCli = () => {
        let notes = []
        let clientRate = 0

        if (data && data.rateRankClient.allRanks) {
            data.rateRankClient.allRanks.map(rank => (
                notes.push(rank.media)
            ))
            clientRate = notes.length ? parseInt(notes.reduce((sum, num) => sum + num) / notes.length) : 0
        }

        return <AvaliationStar avaliation={clientRate ? clientRate : 0} />
    }

    const RankPro = () => {
        let notes = []
        let proRate = 0

        if (data && data.rateRankPro.allRanks) {
            data.rateRankPro.allRanks.map(rank => (
                notes.push(rank.media)
            ))
            proRate = notes.length ? parseInt(notes.reduce((sum, num) => sum + num) / notes.length) : 0
        }

        return <AvaliationStar avaliation={proRate ? proRate : 0} />
    }


    if (loading) return <ElementLoading />

    return (
        <div className={'ProfileLoggedIn col-xs-12'}>
            <div className="row center-xs">
                <div className={'userInfo col-xs-12'}>
                    <div className={'user row middle-xs center-xs'}>
                        <div className='imgprofile col-xs-4 col-sm-3'>

                            <img src={userInfo.photo ? userInfo.photo : userLogo} alt={userInfo.name} />

                            <Link to="/perfil/data"><ButtonText name={'editar perfil'} /></Link>
                        </div>

                        <div className='dataprofile col-xs-8 col-sm-7'>
                            <h1>{userInfo.name ? userInfo.name : 'Nome Usuário'}</h1>
                            {userInfo.address.state && <span>{userInfo.address.city + ' - ' + userInfo.address.state}</span>}

                            {data &&
                                data.allAnunciosChip && data.allAnunciosChip.length > 0 &&
                                data.allAnunciosChip.map((p, i) => (
                                    p.active && <span className="skills" key={i}>{p.skill.name}</span>))}

                        </div>

                        <div className={'moneyInfo col-xs-12 col-sm-2'}>
                            <ErrorBroundray>
                                <div className={'enter'}>
                                    <span>Ganho </span>
                                    <MoneyProposePro />
                                </div>
                            </ErrorBroundray>

                            <ErrorBroundray>
                                <div className={'spend'}>
                                    <span>Gasto </span>
                                    <MoneyProposeCli />
                                </div>
                            </ErrorBroundray>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'userAction row center-xs'}>
                <div className={'userNotes col-xs-12 col-sm-4 last-sm start-lg'}>
                    <div className="row center-xs">
                        <Link to={'/perfil/rank'}>
                            <div className="col-xs-6">
                                <span>Cliente</span>

                                <RankCli />

                            </div>
                            <div className="col-xs-6">
                                <span>Profissional</span>

                                <RankPro />
                                
                            </div>
                        </Link>
                    </div>
                </div>
                <div className={'menu col-xs-12 col-sm-8 row first-sm center-xs'}>

                    <NavLink exact to="/perfil" activeClassName='active'>
                        <button>
                            <img src="" alt="" />
                            <span>Atividades</span>
                        </button>
                    </NavLink>

                    {userInfo.type === "PROFISSIONAL" &&
                        <NavLink exact to="/perfil/received" activeClassName='active'>
                            <button>
                                <img src="" alt="" />
                                <span>Solicitações Recebidas</span>
                            </button>
                        </NavLink>}

                    <NavLink exact to="/perfil/send" activeClassName='active'>
                        <button>
                            <img src="" alt="" />
                            <span>Solicitações Enviadas</span>
                        </button>
                    </NavLink>

                    <NavLink exact to="/perfil/meuanuncios" activeClassName='active'>
                        <button>
                            <img src="" alt="" />
                            <span>Meus Anúncios</span>
                        </button>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}