import React, { useState, useEffect, useLayoutEffect } from 'react'
import Helmet from 'react-helmet'
import Input from '../../components/newInput/input'
import Loading from '../../components/loading/loading'
import axios from 'axios'
import { UpdateUserDD, UpdateAddress, UserDoc } from '../../queries/player'
import CPF from 'gerador-validador-cpf'
import ErrorM from '../../components/ErrorMessange/errormenssage'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import dayjs from 'dayjs'
import Miniloading from '../../components/miniloading/miniloading'
import { Report } from 'notiflix'
import Button from "../../components/newButton/button"
import { useLazyQuery, useMutation } from '@apollo/client'

export default () => {
  const cache = JSON.parse(sessionStorage.getItem('OITO'))
  const [step, setStep] = useState(1)

  useEffect(() => {
    if (cache) {
      if (!cache.user.phoneverif) {
        return Report.Failure('Você não verificou seu telefone!', '', 'Verificar', () => window.location.href = '/verificartelefone')
      }

      if (!cache.user.document || !cache.user.birthday) {
        setStep(1)
      } else if (cache.user.document && cache.user.birthday && !cache.user.address.city) {
        setStep(2)
      } else if (cache.user.document && cache.user.birthday && cache.user.address.city) {
        window.location.href = '/perfil'
      }
    } else {
      return Report.Failure('Você não está logado!', 'Entre ou cadastre-se. É gratuito!', 'Fechar', () => window.location.href = '/login')
    }
  }, [cache])

  const SetPerson = () => {
    const [doc, setDoc] = useState()
    const [date, setDate] = useState()
    const [dateOk, setDateOk] = useState(false)
    const [dateErr, setDateErr] = useState()
    const [docOk, setDocOk] = useState(false)
    const [docErr, setDocErr] = useState()
    const [searchDocument, { data: docSearchResult, loading: spinnerdoc }] = useLazyQuery(UserDoc)
    const [savePerson, { loading: loadingPerson }] = useMutation(UpdateUserDD)

    useLayoutEffect(() => {
      if (docSearchResult && docSearchResult.allUsers.length > 0) {
        setDocOk(false)
        setDateErr(<ErrorM content='CPF já cadastrado.' />)
      } else {
        setDocOk(true)
        setDateErr()
      }
    }, [docSearchResult])

    useLayoutEffect(() => {
      if (date) {
        // console.log(e)
        if (date.length >= 10) {
          const day = date.split('/')[0]
          const month = date.split('/')[1]
          const year = date.split('/')[2]
          const birth = year + '-' + month + '-' + day
          const birthday = dayjs(birth).toDate()

          if (dayjs(birthday).isValid()) {
            const anos = dayjs().diff(birthday, 'month') / 12
            if (anos < 18) {
              setDateOk(false)
              setDateErr(<ErrorM content='Você precisa ser maior de 18 anos para se cadastrar.' />)
            } else if (anos >= 18 && anos < 120) {
              setDateOk(true)
              setDateErr()
            } else {
              setDateOk(false)
              setDateErr(<ErrorM content='Digite uma data válida. Não é necessário digitar as barras. Exemplo: 01/01/2000.' />)
            }
          } else if (date !== "__/__/____" && !date.match(/_/)) {
            setDateOk(false)
            setDateErr(<ErrorM content='Digite uma data válida. Não é necessário digitar as barras. Exemplo: 01/01/2000.' />)
          }
        } else {
          setDateOk(false)
          setDateErr()
        }
      }
    }, [date])

    useLayoutEffect(() => {
      if (doc && doc.length > 13 && !doc.match(/_/)) {
        if (CPF.validate(doc)) {
          searchDocument({
            variables: {
              document: doc
            }
          })
        } else {
          setDocOk(false)
          setDateErr(<ErrorM content='Seu CPF não é válido. Por favor, digite novamente sem pontos, barras ou traços. Ele deve conter 11 números.' />)
        }
      } else if (doc && doc.length > 11 && !doc.match(/_/)) {
        setDocErr(<ErrorM content='Digite seu CPF.' />)
        setDocOk(false)
      } else {
        setDocErr()
        setDocOk(false)
      }
    }, [doc, searchDocument])

    const sendRegister = (e) => {
      e.preventDefault()

      if (dateOk && docOk) {

        savePerson({
          variables: {
            document: doc,
            birthday: date,
          }
        }).then(
          res => {

            const userAuth = {
              token: cache.token,
              user: res.data.updateUser
            }

            sessionStorage.setItem('OITO', JSON.stringify(userAuth))

            if (res.data.updateUser.address.city && res.data.updateUser.address.state) {
              return Report.Success(
                "Cadastro atualizado com sucesso!",
                "",
                "Fechar",
                () => window.location.href = "/perfil"
              )
            }

            setStep(2)
          }
        ).catch(err => {
          console.log(err)
          return Report.Failure(
            "Erro ao cadastrar.",
            "Aconteceu alguma coisa. Tente novamente mais tarde.",
            "Voltar"
          )
        })
      }
    } //enviar cadastro

    if (loadingPerson) return <Loading />

    return (
      <div className="col-xs-12">
        <div className="row center-xs middle-xs">
          <div className="col-xs-12 col-sm-8 col-md-6">
            <form id="setperson" onSubmit={(e) => sendRegister(e)}>
              <Input icon={dateOk ? 'fas fa-check' : 'fas fa-birthday-cake'} name="birthday" mask="99/99/9999" placeholder="Data de nascimento. DD/MM/AAAA" onChange={(e) => setDate(e.target.value)} />
              {dateErr}
              <OverlayTrigger
                placement='right'
                overlay={<Tooltip id="pqdate">A Oito exige que o usuário cadastrado seja maior de 18 anos de idade.</Tooltip>} >
                <i className="fas fa-info"></i>
              </OverlayTrigger>

              <Input name={'document'} placeholder={'CPF. Ex.: xxx.xxx.xxx-xx'} mask="999.999.999-99" type={'text'} onChange={(e) => setDoc(e.target.value)} />
              {docErr}
              <OverlayTrigger
                placement='right'
                overlay={<Tooltip id="pqdoc">A Oito requer um número de CPF válido para confirmar seus dados e fortalecer a credibilidade da nossa plataforma.</Tooltip>} >
                <i className="fas fa-info"></i>
              </OverlayTrigger>

              <div style={{ justifyContent: 'center' }}>
                {spinnerdoc && <Miniloading />}
                <Button type="submit" disabled={!(docOk && dateOk)} name={'Próximo'} />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  const SetAddress = () => {
    const [saveAddress, { loading }] = useMutation(UpdateAddress)
    const [findAddress, setFindAddress] = useState(false)
    const [zipcode, setZipcode] = useState()
    const [street, setStreet] = useState()
    const [neighborhood, setNeighborhood] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()
    const [zipErr, setZipErr] = useState()
    const [number, setNumber] = useState()
    const [complement, setComplement] = useState()

    const getZipcode = async e => {
      setZipErr()

      const input = e.target.value.replace(/\D/g, "");

      if (input.length < 8) return false;

      try {
        setFindAddress(true)
        
        const address = await axios.get(`https://viacep.com.br/ws/${input}/json/`, {})

        if (address.data && !address.data.erro) {
          setZipcode(address.data.cep)
          setStreet(address.data.logradouro)
          setNeighborhood(address.data.bairro)
          setCity(address.data.localidade)
          setState(address.data.uf)
          setFindAddress(false)
        } else {
          setFindAddress(false)
          setZipErr(<ErrorM content='CEP inválido.' />)
        }
      } catch (e) {
        console.log("# zipcode error", e)
        if (zipcode.length > 8)
          setFindAddress(false)
        setZipErr(<ErrorM content='CEP inválido.' />)
      }
    }; //_getZipcode

    const blur = () => {

      if (number && street && city && state) {
        setZipErr()
      } else {
        if (!street) {
          setZipErr(<ErrorM content='Digite a rua.' />)
        }
        if (!number) {
          setZipErr(<ErrorM content='Digite o número do endereço.' />)
        }
        if (!city) {
          setZipErr(<ErrorM content='Digite a cidade.' />)
        }
        if (!state) {
          setZipErr(<ErrorM content='Digite a sigla do estado.' />)
        }
      }
    }

    const updateAddress = (e) => {
      e.preventDefault()

      saveAddress({
        variables: {
          zipcode: zipcode,
          street: street,
          number: number,
          complement: complement,
          neighborhood: neighborhood,
          city: city,
          state: state,
        }
      }).then(
        res => {

          console.log("atualizando o storage")
          const userAuth = {
            token: cache.token,
            user: res.data.updateUser
          }

          sessionStorage.setItem('OITO', JSON.stringify(userAuth))

          return Report.Success(
            "Cadastro atualizado com sucesso!",
            "",
            "Fechar",
            () => window.location.href = "/perfil"
          )
        }
      ).catch(err => {
        console.log(err)
        return Report.Failure(
          "Erro ao cadastrar.",
          "Aconteceu alguma coisa. Tente novamente mais tarde.",
          "Voltar"
        )
      })
    }

    if (loading) return <Loading />

    return (
      <div className="address-form col-xs-12" style={{ maxWidth: "800px" }}>
        <div className="row center-xs middle-xs">
          <div className="col-xs-12">
            <h3>Endereço</h3>
          </div>

          <div className="col-xs-12">
            {zipErr}
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input mask="99999-999" onChange={(e) => getZipcode(e)} name="zipcode" placeholder="CEP. Ex.: 00000-000" type="text" />

            <p style={{ textAlign: "right", fontSize: "0.8rem" }}><a href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm" target="_blank" rel="noopener noreferrer">Não sei meu CEP</a></p>

            <OverlayTrigger
              placement='right'
              overlay={<Tooltip id="pqaddress">A Oito exige que você cadastre um endereço válido para manter a integridade da nossa plataforma.</Tooltip>} >
              <i className="fas fa-info"></i>
            </OverlayTrigger>
          </div>

          {findAddress && <Miniloading />}

          {state && <form className={'form-complete col-xs-12 row'} onSubmit={(e) => updateAddress(e)}>
            <div className="street col-xs-12">
              <Input onInput={(e) => setStreet(e.target.value)} onChange={blur} name="street" value={street} label="Endereço" placeholder="Rua, Avenida ou Logradouro." type="text" />
            </div>

            <div className="num col-xs-12 col-sm-6">
              <Input onInput={(e) => setNumber(e.target.value)} onChange={blur} name="number" placeholder="Número. Ex.: 00" type="text" />
            </div>

            <div className="complement col-xs-12 col-sm-6">
              <Input onInput={(e) => setComplement(e.target.value)} onChange={blur} name="complement" placeholder="Complemento. Ex.: Apto 1101" type="text"  required={false}/>
            </div>

            <div className="neighborhood col-xs-12">
              <Input onInput={(e) => setNeighborhood(e.target.value)} onChange={blur} name="neighborhood" value={neighborhood} placeholder="Bairro" type="text" />
            </div>

            <div className="city col-xs-12">
              <Input onChange={blur} name="city" value={city} placeholder="Cidade" type="text" />
            </div>

            <div className="state col-xs-12">
              <Input onChange={blur} name="state" value={state} placeholder="Estado" type="text" />
            </div>

            <div className="col-xs-12" style={{ justifyContent: 'center' }}>
              <Button type="submit" name={'Concluir'} disabled={!!zipErr}></Button>
            </div>
          </form>}
        </div>

      </div>
    )
  }

  const ShowSteps = () => {
    switch (step) {
      case 2: //endereço
        return <SetAddress />
      case 1:
      default: //dados
        return <SetPerson />
    }
  }

  return (
    <div className="col-xs-12" style={{ marginTop: '25px' }}>
      <Helmet>
        <title>Oito - Complete seus dados</title>
        <meta name="description" content="Faça seu cadastro em Oito.app e encontre profissionais qualificados e confiáveis para solucionar seus problemas, ou divulgue seus serviços e encontre oportunidades imperdíveis." />
        <meta name="keywords" content="Cadastro, Oportunidades, Serviços" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="pt-br" />
        <meta name="revisit-after" content="1 days" />
      </Helmet>
      <div className="row">
        <div className="col-xs-12">
          <h1>Complete seus dados</h1>
        </div>
        <div className="col-xs-12">
          <div className="register-box row center-xs" id="myform" style={{ marginBottom: "30px" }}>
            <ShowSteps />
          </div>
        </div>
      </div>
    </div>
  )
}