import { gql } from "@apollo/client"

export const Users = gql`
{
    allUsers{
    id
    type
    role
    name
    address {
      city
      state
    }
  birthday
  email
  proposes{
      id
  }
  proposesFrom {
      id
  }
  ranks{
      id
  }
  status
  type
  anuncios {
      id
      skill {
          id
          name
      }
  }
  phoneverif
  }
}
`

export const CountUsers = gql`
{
    total: allUsersMeta{
        count
    }

    profissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
        }
    ){
        count
    }

    cliente: allUsersMeta(
        filter: {
            type: CLIENTE
        }
    ){
        count
    }

}
`

export const CountUsersStates = gql`
{
    ACtotal: allUsersMeta(
        filter: {
            address: {
                state: "AC"
            }
        }
    ){
        count
    }

    ACprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "AC"
            }
        }
    ){
        count
    }

    ACcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "AC"
            }
        }
    ){
        count
    }

    ALtotal: allUsersMeta(
        filter: {
            address: {
                state: "AL"
            }
        }
    ){
        count
    }

    ALprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "AL"
            }
        }
    ){
        count
    }

    ALcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "AL"
            }
        }
    ){
        count
    }

    APtotal: allUsersMeta(
        filter: {
            address: {
                state: "AP"
            }
        }
    ){
        count
    }

    APprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "AP"
            }
        }
    ){
        count
    }

    APcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "AP"
            }
        }
    ){
        count
    }

    AMtotal: allUsersMeta(
        filter: {
            address: {
                state: "AM"
            }
        }
    ){
        count
    }

    AMprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "AM"
            }
        }
    ){
        count
    }

    AMcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "AM"
            }
        }
    ){
        count
    }

    BAtotal: allUsersMeta(
        filter: {
            address: {
                state: "BA"
            }
        }
    ){
        count
    }

    BAprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "BA"
            }
        }
    ){
        count
    }

    BAcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "BA"
            }
        }
    ){
        count
    }

    CEtotal: allUsersMeta(
        filter: {
            address: {
                state: "CE"
            }
        }
    ){
        count
    }

    CEprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "CE"
            }
        }
    ){
        count
    }

    CEcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "CE"
            }
        }
    ){
        count
    }

    DFtotal: allUsersMeta(
        filter: {
            address: {
                state: "DF"
            }
        }
    ){
        count
    }

    DFprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "DF"
            }
        }
    ){
        count
    }

    DFcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "DF"
            }
        }
    ){
        count
    }

    EStotal: allUsersMeta(
        filter: {
            address: {
                state: "ES"
            }
        }
    ){
        count
    }

    ESprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "ES"
            }
        }
    ){
        count
    }

    EScliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "ES"
            }
        }
    ){
        count
    }

    GOtotal: allUsersMeta(
        filter: {
            address: {
                state: "GO"
            }
        }
    ){
        count
    }

    GOprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "GO"
            }
        }
    ){
        count
    }

    GOcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "GO"
            }
        }
    ){
        count
    }

    MAtotal: allUsersMeta(
        filter: {
            address: {
                state: "MA"
            }
        }
    ){
        count
    }

    MAprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "MA"
            }
        }
    ){
        count
    }

    MAcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "MA"
            }
        }
    ){
        count
    }

    MTtotal: allUsersMeta(
        filter: {
            address: {
                state: "MT"
            }
        }
    ){
        count
    }

    MTprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "MT"
            }
        }
    ){
        count
    }

    MTcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "MT"
            }
        }
    ){
        count
    }

    MStotal: allUsersMeta(
        filter: {
            address: {
                state: "MS"
            }
        }
    ){
        count
    }

    MSprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "MS"
            }
        }
    ){
        count
    }

    MScliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "MS"
            }
        }
    ){
        count
    }

    MGtotal: allUsersMeta(
        filter: {
            address: {
                state: "MG"
            }
        }
    ){
        count
    }

    MGprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "MG"
            }
        }
    ){
        count
    }

    MGcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "MG"
            }
        }
    ){
        count
    }

    PAtotal: allUsersMeta(
        filter: {
            address: {
                state: "PA"
            }
        }
    ){
        count
    }

    PAprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "PA"
            }
        }
    ){
        count
    }

    PAcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "PA"
            }
        }
    ){
        count
    }

    PBtotal: allUsersMeta(
        filter: {
            address: {
                state: "PB"
            }
        }
    ){
        count
    }

    PBprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "PB"
            }
        }
    ){
        count
    }

    PBcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "PB"
            }
        }
    ){
        count
    }

    PRtotal: allUsersMeta(
        filter: {
            address: {
                state: "PR"
            }
        }
    ){
        count
    }

    PRprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "PR"
            }
        }
    ){
        count
    }

    PRcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "PR"
            }
        }
    ){
        count
    }

    PEtotal: allUsersMeta(
        filter: {
            address: {
                state: "PE"
            }
        }
    ){
        count
    }

    PEprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "PE"
            }
        }
    ){
        count
    }

    PEcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "PE"
            }
        }
    ){
        count
    }

    PItotal: allUsersMeta(
        filter: {
            address: {
                state: "PI"
            }
        }
    ){
        count
    }

    PIprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "PI"
            }
        }
    ){
        count
    }

    PIcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "PI"
            }
        }
    ){
        count
    }

    RJtotal: allUsersMeta(
        filter: {
            address: {
                state: "RJ"
            }
        }
    ){
        count
    }

    RJprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "RJ"
            }
        }
    ){
        count
    }

    RJcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "RJ"
            }
        }
    ){
        count
    }

    RNtotal: allUsersMeta(
        filter: {
            address: {
                state: "RN"
            }
        }
    ){
        count
    }

    RNprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "RN"
            }
        }
    ){
        count
    }

    RNcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "RN"
            }
        }
    ){
        count
    }

    RStotal: allUsersMeta(
        filter: {
            address: {
                state: "RS"
            }
        }
    ){
        count
    }

    RSprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "RS"
            }
        }
    ){
        count
    }

    RScliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "RS"
            }
        }
    ){
        count
    }

    ROtotal: allUsersMeta(
        filter: {
            address: {
                state: "RO"
            }
        }
    ){
        count
    }

    ROprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "RO"
            }
        }
    ){
        count
    }

    ROcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "RO"
            }
        }
    ){
        count
    }

    RRtotal: allUsersMeta(
        filter: {
            address: {
                state: "RR"
            }
        }
    ){
        count
    }

    RRprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "RR"
            }
        }
    ){
        count
    }

    RRcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "RR"
            }
        }
    ){
        count
    }

    SCtotal: allUsersMeta(
        filter: {
            address: {
                state: "SC"
            }
        }
    ){
        count
    }

    SCprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "SC"
            }
        }
    ){
        count
    }

    SCcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "SC"
            }
        }
    ){
        count
    }

    SPtotal: allUsersMeta(
        filter: {
            address: {
                state: "SP"
            }
        }
    ){
        count
    }

    SPprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "SP"
            }
        }
    ){
        count
    }

    SPcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "SP"
            }
        }
    ){
        count
    }

    SEtotal: allUsersMeta(
        filter: {
            address: {
                state: "SE"
            }
        }
    ){
        count
    }

    SEprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "SE"
            }
        }
    ){
        count
    }

    SEcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "SE"
            }
        }
    ){
        count
    }

    TOtotal: allUsersMeta(
        filter: {
            address: {
                state: "TO"
            }
        }
    ){
        count
    }

    TOprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: "TO"
            }
        }
    ){
        count
    }

    TOcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: "TO"
            }
        }
    ){
        count
    }
    
    NULLtotal: allUsersMeta(
        filter: {
            address: {
                state: null
            }
        }
    ){
        count
    }

    NULLprofissional: allUsersMeta(
        filter: {
            type: PROFISSIONAL
            address: {
                state: null
            }
        }
    ){
        count
    }

    NULLcliente: allUsersMeta(
        filter: {
            type: CLIENTE
            address: {
                state: null
            }
        }
    ){
        count
    }
}
`

export const CountProposes = gql`
{
    total: allProposesMeta{
        count
    }

    aberto: allProposesMeta(
        filter: {
            status: "Serviço Aberto para Negociação"
        }
    ){
        count
    }

    naorealizado: allProposesMeta(
        filter: {
            status: "Serviço Não Realizado"
        }
    ){
        count
    }

    recusado: allProposesMeta(
        filter: {
            status: "Serviço Recusado"
        }
    ){
        count
    }

    aceito: allProposesMeta(
        filter: {
            status: "Serviço Aceito"
        }
    ){
        count
    }

    cancelado: allProposesMeta(
        filter: {
            status: "Serviço Cancelado"
        }
    ){
        count
    }

    finalizado: allProposesMeta(
        filter: {
            status: "Serviço Finalizado com Sucesso"
        }
    ){
        count
    }
}
`

export const CountAnuncios = gql`
{
    allAnunciosMeta{
        count
    }
}
`

export const AnunciosAll = gql`
{
    allSkills {
      name
      anunciosMeta {
        count
      }
      anuncios {
        proposesMeta {
          count
        }
      }
    }
  }
`

export const CountUsersSetDate = gql`
query ($date: String!){
                total: allUsersMeta(filter: {
                  createdAt_lte: $date
                }) {
                  count
                }
                profissionais: allUsersMeta(filter: {
                  createdAt_lte: $date
                  player: {
                    type: PROFISSIONAL
                  }
                }) {
                  count
                }
            }
`

export const CreateSkills = gql`
mutation ($name: String!, $category: ID!) {
  createSkill (input: {
      name: $name
      categoryId: $category
  }){
    name
  }
}
`

export const newHabs = gql`
{
  allHabilidades(filter: {
    cadastro: false
  }) {
    name
  }
}
`

export const CreateCategory = gql`
mutation CreateCategory($name: String!, $description: String, $file: String!){
  createCategory(input: {
      name: $name
    description: $description
    file: $file
  }){
    name
    id
  }
}
`