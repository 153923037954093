import React, { Fragment } from 'react'
import './viewAnnouncement.scss'

import ProfileExib from "../../components/profileExib/profileExib";
import PhotoZoom from "../../components/photoZoom/photoZoom";
import ErrorBoundray from "../../components/errorBoundary/errorBoundary"

export default class ProfileSeller extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            page: null,
            show: false,
            anuncio: {},
            loading: false,
            openZoom: false,
            zoomUrl: '',
            zoomAlt: ''
        }

        this.form = {}

        this.openPropose = (anuncio) => {
            const cache = JSON.parse(sessionStorage.getItem('OITO'))

            if (cache) {
                this.playerId = cache.user.player.id
                this.form.location = cache.user.player.address ? cache.user.player.address.city + " - " + cache.user.player.address.state : null
                this.setState({
                    show: !this.state.show,
                    anuncio,
                    playerId: cache.user.player.id
                })
            } else {
                // console.log("deveria redirecionar")
                // window.location.href = '/login'

                const listHistory = {
                    count: 0,
                    path: window.location.pathname
                }

                sessionStorage.setItem('LastURL', JSON.stringify(listHistory))
                // console.log(sessionStorage.getItem('LastURL'))

                this.setState({
                    showAlert: true,
                    typeAlert: 'error',
                    titleAlert: 'Você não está logado!',
                    messageAlert: 'Entre ou cadastre-se. É gratuito!',
                    onClose: () => window.location.href = '/login'
                })
            }

        }

    }

    openZoom = () => {
        this.setState({ openZoom: !this.state.openZoom })
    };

    openImg = (imgUrl, imgAlt) => {
        this.openZoom();
        this.setState({ zoomUrl: imgUrl, zoomAlt: imgAlt })
    }

    filterAnun = (list, skill) => {
        // console.log(list)
        // console.log(skill)
        if (skill !== '' && skill !== null)
            list.filter((anuncio) => {
                if (anuncio.skills.id === skill) {
                    if (this.state.page === null) {
                        return this.setState({ page: anuncio })
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            })
    }

    render() {
        const { player, anuncio } = this.props
        return (
            <Fragment>

                {this.state.openZoom ? <PhotoZoom onClose={this.openZoom} imgUrl={this.state.zoomUrl} imgAlt={this.state.zoomAlt} /> : null}

                <div className={'profileUserView'}>

                    <ErrorBoundray><ProfileExib name={player.name} imgUrl={player.photo ? player.photo.url : player.avatar} city={player.address} resume={player.description} rank={"view"} /></ErrorBoundray>
                    <div>
                        <div className="skill-view">

                            <div className={'infos'}>
                                <div>
                                    {/* <p><strong>Skill: </strong>{page.skills.name}</p> */}
                                    <h2 style={{ fontWeight: "normal" }}>{anuncio.description}</h2>
                                    <p><strong>Preço: </strong>{anuncio.price ? anuncio.price : 'a combinar'}</p>
                                </div>
                            </div>
                            <h3>Fotos</h3>
                            <div className={'photos'}>
                                {anuncio.files ? anuncio.files.length > 0 ? anuncio.files.map((img, i) => (
                                    <img src={img} alt={img} style={{ cursor: 'pointer' }} key={i} onClick={() => this.openImg(img, img)} />
                                )) : <p>Este profissional ainda não possui fotos</p> : <p>Este profissional ainda não possui fotos</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
