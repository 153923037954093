import React from "react"
import './aboutUs.scss'

import { Link } from 'react-router-dom'

import Navbar from '../../components/newNavbar/navbar'
import ButtonText from '../../components/buttonText/buttonText'
import Footer from '../../components/newFooter/footer'
import ErrorBroundray from '../../components/errorBoundary/errorBoundary'

export default () => <>
    <ErrorBroundray><Navbar /></ErrorBroundray>
    <article className={'about-us col-xs-12'}>
        <section>
            <h1>Quem Somos</h1>
            <p>
                A Oito nasceu do desejo de conectar pessoas e oportunidades.
                Profissionais autônomos divulgam seus serviços e se conectam com
                clientes que procuram soluções. Tudo de forma clara, rápida, justa e
                gratuita.
                        </p>

            <p>
                Enxergamos a necessidade de milhões de brasileiros de encontrar
                oportunidades no mercado de trabalho, por isso criamos uma tecnologia
                que ranqueia e qualifica profissionais e oportunidades, agilizando e
                certificando o processo de contratação e divulgação.
                        </p>
        </section>
        <section>
            <h1>Missão</h1>
            <p>Queremos catalisar o processo de contratação baseado em ranqueamento
                            para pessoas, gerando conexões que sejam mutuamente proveitosas.</p>
        </section>
        <section>
            <h1>Visão</h1>
            <p>Ser protagonista no processo de inclusão social, por meio de conexões
                            que sejam mutuamente proveitosas, transparentes e justas.</p>
        </section>
        <section>
            <h1>Valores</h1>
            <p>
                Transparência: baseado no ranqueamento, todas as relações geradas por
                meio da Oito possuem um índice de qualidade livre para ser alterado
                mediante feedback.
                        </p>

            <p>
                Agilidade: gerar oportunidades de forma rápida, prática e objetiva
                tanto para quem oferece quanto para quem procura, minimizando
                dificuldades e maximizando soluções.
                        </p>

            <p>
                Responsabilidade social: contribuir para que o autônomo alcance
                liberdade e estabilidade financeira, por meio de divulgação de seus
                serviços.
                        </p>
        </section>
        <section>
            <Link to={'/'}><ButtonText icon={'faChevronLeft'} name={'voltar'} /></Link>
        </section>
    </article>
    <ErrorBroundray><Footer /></ErrorBroundray>
</>
