import React from "react";
import "./newsbar.scss";
import { gql, useQuery } from "@apollo/client"
import ErrorScreen from '../errorScreen/errorScreen'

const News = gql`
{
    allNews {
    name
    link
    logo
  }
}
`

export default () => {
    const { data, error } = useQuery(News)

    if (error) return <ErrorScreen cod={error} />

    return <div className="newsbar justify-content-center">
        {data && data.allNews.length > 0 &&
            <>
                <div className="newstitle">
                    <h4>Notícias</h4>
                </div>
                <div className="newslogo align-items-center">
                    {data.allNews.map((post, i) =>
                        <a href={post.link} key={i} target="_blank" rel="noopener noreferrer" >
                            <img src={post.logo} alt={post.name} />
                        </a>
                    )}
                </div>
            </>}

    </div>
}
