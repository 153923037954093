import React, { Fragment, useState, useEffect } from "react";
import "./login.scss";

import { Link } from "react-router-dom";

import Navbar from "../../components/newNavbar/navbar";
import Button from "../../components/newButton/button";
import Input from "../../components/newInput/input";
import ImgPage from "../../components/imgPage/imgPage";
import { useMutation, useLazyQuery } from "@apollo/client";
import { SigninUser, FindUserByEmail, RecoverUser } from "../../queries/player";
import Loading from "../../components/loading/loading";
import ErrorM from "../../components/ErrorMessange/errormenssage";
// import Modal from '../../components/Modal/modal'
import generatePassword from "password-generator";
import * as emailjs from "emailjs-com";
import Modal from "../../components/Modal/modal";
import ErrorBroundray from "../../components/errorBoundary/errorBoundary";
import { Report } from "notiflix";

const Login = () => {
  const cache = JSON.parse(sessionStorage.getItem("OITO"));
  const LastURL = JSON.parse(sessionStorage.getItem("LastURL"));
  const [loginUser, { loading }] = useMutation(SigninUser);
  const [
    findUser,
    { loading: userLoading, data: lostUserData },
  ] = useLazyQuery(FindUserByEmail);
  const [
    changePassword,
    { loading: loadingNewPassword, error: errorNewPassword },
  ] = useMutation(RecoverUser);
  const [wait, setWait] = useState(false);
  const [emailok, setEmailOk] = useState(false);
  const [spinneremail, setSpinneremail] = useState(false);
  const [emailErr, setEmailErr] = useState();
  const [show, setShow] = useState(false);
  const [passErr, setPassErr] = useState();
  const [emailPok, setEmailPOk] = useState(false);
  const [inputLoginFormEmail, setInputLoginFormEmail] = useState();
  const [inputLoginFormPassword, setInputLoginFormPassword] = useState();
  const [inputForgotFormEmail, setInputForgotFormEmail] = useState();
  const [inputForgotFormPhone, setInputForgotFormPhone] = useState();
  const [phoneOk, setPhoneOk] = useState();
  const [phoneErr, setPhoneErr] = useState();

  useEffect(() => {
    if (cache) window.location.href = "/perfil";

    if (LastURL) {
      if (LastURL.path !== window.location.pathname) {
        const listHistory = {
          count: parseInt(LastURL.count) - 1,
          path: window.location.pathname,
        };
        sessionStorage.setItem("LastURL", JSON.stringify(listHistory));
      }
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (inputLoginFormEmail && inputLoginFormEmail.length > 4) {
      setSpinneremail(true);
      if (
        inputLoginFormEmail.includes("@") &&
        inputLoginFormEmail.split("@")[1].includes(".")
      ) {
        setEmailOk(true);
        setSpinneremail(false);
        setEmailErr(null);
      } else {
        setEmailOk(false);
        setSpinneremail(false);
        setEmailErr(
          <ErrorM
            color="danger"
            content="E-mail inválido. Por favor, digite novamente. Ex.: jose@email.com"
          />
        );
      }
    } else {
      setEmailOk(false);
      setSpinneremail(false);
      setEmailErr(null);
    }
  }, [inputLoginFormEmail]);

  useEffect(() => {
    if (inputForgotFormEmail && inputForgotFormEmail.length > 4) {
      setSpinneremail(true);
      if (
        inputForgotFormEmail.includes("@") &&
        inputForgotFormEmail.split("@")[1].includes(".")
      ) {
        setEmailPOk(true);
        setSpinneremail(false);
        setPassErr(null);
      } else {
        setEmailOk(false);
        setSpinneremail(false);
        setPassErr(
          <ErrorM
            color="danger"
            content="E-mail inválido. Por favor, digite novamente. Ex.: jose@email.com"
          />
        );
      }
    } else {
      setEmailPOk(false);
      setSpinneremail(false);
      setPassErr(null);
    }
  }, [inputForgotFormEmail]);

  useEffect(() => {
    if (inputForgotFormPhone && inputForgotFormPhone.length === 15) {
      setPhoneOk(true);
      setPhoneErr(null);
    } else {
      setPhoneOk(false);
      setPhoneErr(
        <ErrorM content="Por favor, digite seu numero de celular." />
      );
    }
  }, [inputForgotFormPhone]);

  useEffect(() => {
    if (lostUserData && lostUserData.allUsers.length) {
      const newPass = generatePassword(12, false);
      const usuario = lostUserData.allUsers[0];

      console.log(usuario);
      //enviar email
      const template_params = {
        email: usuario.email,
        title: "Sua senha foi redefinida.",
        name: usuario.name,
        message: `Oi ${usuario.name}. Foi solicitada a recuperação de senha. Sua nova senha é: ${newPass}. Entre em seu perfil e troque por uma senha segura.`,
      };

      emailjs
        .send(
          "noreply",
          "default",
          template_params,
          "user_IagwdTtEr3AVRdLhxkyKc"
        )
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
        })
        .then(() =>
          changePassword({
            variables: {
              email: usuario.email,
              password: newPass,
            },
          })
        )
        .then(() => {
          sessionStorage.clear();

          Report.Success(
            "Nova senha solicitada com sucesso!",
            "Verifique seu e-mail para ver sua nova senha.",
            "Fechar",
            () => window.location.reload()
          );
          setShow(false);
          setInputForgotFormEmail();
          setInputForgotFormPhone();
          setEmailPOk(false);
          setPassErr(null);

          console.log("tudo ok");
        })
        .catch((error) => {
          console.log("FAILED...", error);
          Report.Failure(
            "Erro ao enviar nova senha.",
            "Ocorreu um erro ao enviar a nova senha. Por favor tente mais tarde.",
            "Fechar"
          );
        });
    }
  }, [lostUserData]); // eslint-disable-line

  useEffect(() => {
    if (errorNewPassword) {
      console.log("não cadastrado2");
      setInputForgotFormEmail();
      setInputForgotFormPhone();
      setPassErr(
        <ErrorM
          color="danger"
          content="E-mail e/ou celular estão incorretos. Por favor, digite novamente ou "
          linkHref="/cadastro"
          link="cadastre-se."
        />
      );
      setEmailPOk(false);
      Report.Failure(
        "E-mail e celular incorretos.",
        "E-mail e/ou celular estão incorretos. Por favor, digite novamente ou cadastre-se.",
        "Fechar",
        () => window.location.reload()
      );
    }
  }, [errorNewPassword]);

  const loginSubmit = (e) => {
    e.preventDefault();

    if (inputLoginFormEmail && inputLoginFormPassword && emailok) {
      loginUser({
        variables: {
          email: inputLoginFormEmail,
          password: inputLoginFormPassword,
        },
      })
        .then((res) => {
          setWait(true);

          const userAuth = {
            token: res.data.authUser.token,
            user: res.data.authUser.user,
          };

          sessionStorage.setItem("OITO", JSON.stringify(userAuth));
          window.location.href = "/perfil";
        })
        .catch(() => {
          Report.Failure(
            "E-mail e celular incorretos.",
            'Verifique se seu e-email e sua senha estão corretos. Caso tenha esquecido, clique em "Esqueci minha senha".',
            "Tentar novamente"
          );
        });
    }
  };

  const newPassword = (e) => {
    setShow(false);

    e.preventDefault();

    if (emailPok && phoneOk) {
      findUser({
        variables: {
          email: inputForgotFormEmail,
          phone: inputForgotFormPhone,
        },
      });
    }
  };

  return (
    <Fragment>
      <ErrorBroundray>
        <Navbar />
      </ErrorBroundray>

      {(loading || userLoading || loadingNewPassword || wait) && <Loading />}

      <ErrorBroundray>
        <ImgPage>
          <div className={"col-xs-12"}>
            <div className="login-div row middle-xs">
              <div className="col-xs-12">
                <h1>Seja bem-vindo a OITO!</h1>
                <span>
                  Anuncie seus serviços, encontre profissionais, conecte-se e
                  faça uma grana extra.
                </span>
              </div>

              <div className="col-xs-12">
                <form id="formlogin" onSubmit={(e) => loginSubmit(e)}>
                  <Input
                    name={"email"}
                    type={"email"}
                    loading={spinneremail}
                    placeholder={"E-mail"}
                    onChange={(e) => setInputLoginFormEmail(e.target.value)}
                    required={true}
                  />
                  {emailErr}
                  <Input
                    name={"password"}
                    isPassword={true}
                    type={"password"}
                    placeholder={"Senha"}
                    onChange={(e) => setInputLoginFormPassword(e.target.value)}
                    minlength={8}
                    required={true}
                  />
                  <div>
                    <Button
                      type="submit"
                      name="Entrar"
                      disabled={
                        !(
                          inputLoginFormEmail &&
                          inputLoginFormPassword &&
                          emailok
                        )
                      }
                    />
                  </div>
                </form>
              </div>

              <div className="col-xs-12">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setShow(!show)}
                >
                  Esqueci minha senha
                </span>
              </div>

              <div className="col-xs-12">
                <span>
                  Ainda não tem conta? <Link to={"/cadastro"}>Cadastre-se</Link>
                </span>
              </div>
            </div>
          </div>
        </ImgPage>
      </ErrorBroundray>

      <Modal
        show={show}
        onClose={() => setShow(!show)}
        title="Esqueceu sua senha?"
      >
        <p>Não se preocupe.</p>
        <p>
          Em seguida você irá receber em seu e-mail a nova senha para acessar a
          Oito.
        </p>

        <form id="newPassForm" onSubmit={(e) => newPassword(e)}>
          <Input
            name={"emailForgot"}
            type={"email"}
            placeholder={"E-mail. Ex.: joao@email.com"}
            onChange={(e) => setInputForgotFormEmail(e.target.value)}
            required={true}
          />
          {passErr}
          <Input
            name={"phone"}
            placeholder={"Celular cadastrado"}
            mask="(99) 99999-9999"
            type={"phone"}
            onChange={(e) => setInputForgotFormPhone(e.target.value)}
            required
            minlength={15}
          />
          {phoneErr}
          <Button
            type="submit"
            name="Entrar"
            disabled={
              !(
                inputForgotFormEmail &&
                inputForgotFormPhone &&
                emailPok &&
                phoneOk
              )
            }
          />
        </form>
      </Modal>
    </Fragment>
  );
};

export default Login;
