import { gql } from "@apollo/client"

export const CreateUser = gql`
mutation CreateUser($email: String!, $password: String!, $name: String!, $phone: String!){
  createUser(input: {
    email: $email,
    password: $password,
    name: $name,
    phone: $phone,
        }){
    email
    phone
    name
  }

  authUser(
      email: $email,
      password: $password
    ) {
      token
      user {
        id
        role
        name
        birthday
        phone
        type
        email
        document
        description
        photo
        phoneverif
        address {
          city
          state
          street
          zipcode
          number
          complement
          neighborhood
          country
        }
      }
    }
  }
`

export const SigninUser = gql`
mutation SigninUser($email: String!, $password: String!){
  authUser(
      email: $email,
      password: $password
    ) {
      token
      user {
        id
        role
        name
        birthday
        phone
        type
        email
        document
        description
        photo
        phoneverif
        address {
          city
          state
          street
          zipcode
          number
          complement
          neighborhood
          country
        }
      }
    }
  }
`

export const UserType = gql`
  {
    allUser(filter: {
    type: PROFISSIONAL
  }){
    photo
    id
    anuncios{
    skill{
        name
      }
    }
    name
    address {
      city
      state
    }
    ranks {
      media
    }
  }
  }
`

export const UserId = gql`
query($id: ID!){
  user(id: $id){
    id
    name
    birthday
    phone
    document
    type
    role
    photo
    email
    description
    phoneverif
    address {
      city
      state
      street
      zipcode
      number
      complement
      neighborhood
      country
    }
    ranks{
      media
      typeTo
      agilidade
      atendimento
      custo
      custoC
      ferramentas
      pontualidade
      pontualidadeC
      respeitoC
      resposta
      solucao
      transparenciaC
    }
    commentsTo{
      rating
      typeTo
      description
      rating
      createdAt
      reply
      propose {
        id
        anuncio {
          id
            skill {
              id
              name
            }
        }
      }
    }
    anuncios {
      id
      description
      skill {
        id
        name
      }
      price
      active
      files
    }
  }
}
`;

export const UserEmail = gql`
query($email: String!){
  allUsers(filter: {
    email: $email
  }){
    id
    email
    name
  }
}
`;

export const UserDoc = gql`
query($document: String!){
  allUsers(filter: {
    document: $document
  }){
    id
    email
    name
    document
  }
}
`;

export const UpdateUserDD = gql`
mutation UpdateUserDD(
    $document: String,
    $birthday: String
     ){
  updateUser(input: {
      document: $document
      birthday: $birthday
  }){
  id
  name
  birthday
  phone
  type
  role
  email
  description
  document
  photo
  phoneverif
  address {
    city
    state
    street
    zipcode
    number
    complement
    neighborhood
    country
  }                   
}
}
`

export const UpdateUser = gql`
mutation UpdateUser(
    $id: ID!,
    $zipcode: String!,
    $street: String,
    $number: String,
    $complement: String,
    $neighborhood: String,
    $city: String,
    $state: String,
    $country: String,
    $phone: String,
    $description: String,
    $email: String,
    $photo: String,
     ){
  updateUser(id: $id,
  input: {
      phone: $phone,
      description: $description,
      email: $email,
      photo: $photo,
    address: {
        zipcode: $zipcode,
        street: $street,
        number: $number,
        complement: $complement,
        neighborhood: $neighborhood,
        city: $city,
        state: $state,
        country: $country
  }
}){       
  id
  name
  birthday
  phone
  type
  role
  email
  photo
  description
  phoneverif
  document
  address {
    city
    state
    street
    zipcode
    number
    complement
    neighborhood
    country
  }
}
}
`

export const UpdatePhoto = gql`
mutation UpdateUser(
    $photo: String
     ){
  updateUser(input: {
photo: $photo
  }){
  id
  name
  birthday
  phone
  role
  type
  email
  photo
  description
  phoneverif
  document
  address {
    city
    state
    street
    zipcode
    number
    complement
    neighborhood
    country
  }                                  
}
}
`

export const UpdateAddress = gql`
mutation UpdateAddress(
    $zipcode: String!,
    $street: String,
    $number: String,
    $complement: String,
    $neighborhood: String,
    $city: String,
    $state: String,
    $country: String,
     ){
  updateUser(
  input: {
    address: {
        zipcode: $zipcode,
        street: $street,
        number: $number,
        complement: $complement,
        neighborhood: $neighborhood,
        city: $city,
        state: $state,
        country: $country
        }
}){       
  id
  name
  birthday
  phone
  type
  email
  role
  photo
  description
  document
  phoneverif
  address {
    city
    state
    street
    zipcode
    number
    complement
    neighborhood
    country
  }
}
}
`

export const UpdateDescription = gql`
mutation UpdateDescription(
    $description: String,
     ){
  updateUser(
  input: {
      description: $description
  }
){       
  id
  name
  birthday
  phone
  type
  email
  role
  photo
  description
  phoneverif
  document
  address {
    city
    state
    street
    zipcode
    number
    complement
    neighborhood
    country
  }
}
}
`

export const UpdatePhone = gql`
mutation UpdateUser(
    $phone: String,
    $phoneverif: Boolean
     ){
  updateUser(
  input: {
      phone: $phone,
      phoneverif: $phoneverif
  }
){       
  id
  name
  birthday
  phone
  type
  email
  role
  photo
  document
  description
  phoneverif
  address {
    city
    state
    street
    zipcode
    number
    complement
    neighborhood
    country
  }
}
}
`

export const FindUserByEmail = gql`
query (
  $email: String,
  $phone: String
){
  allUsers(filter: {
      email: $email
      phone: $phone
  }) {
      name
      email
  }
}`

export const PasswordChange = gql`
mutation (
  $password: String!
){
  updateUserPassword (
      password: $password
  )}`

export const ProfileData = gql`
query ProfileData($id: ID!){
  allAnunciosChip: allAnuncios(filter:{
    userId: $id,
    active: true
  }){
    id
    description
    skill {
      name
    }
    active
  }

  rateRankClient: allRanks ( filter: {
        toId: $id
        typeTo: CLIENTE
      }){
        id
        media
        custoC
        pontualidadeC
        respeitoC
        transparenciaC
      }

  rateRankPro: allRanks ( filter: {
        toId: $id
        typeTo: PROFISSIONAL
      }
      ){
        id
        media
        agilidade
        atendimento
        ferramentas
        solucao
        resposta
        custo
        pontualidade
      }

  proposePro: user( id: $id ){
    proposes {
      id
      valueInit
      valueEnd
      status
      dateInit
      location
      locationF
      createdAt
      anuncio {
        id
        skill {
          id
          name
        }
      }
    }
  }

  proposeCli: user( id: $id ){
    proposesFrom {
      id
      valueInit
      valueEnd
      status
      dateInit
      location
      locationF
      createdAt
      anuncio {
        id
        skill {
          id
          name
        }
      }
    }
  }

}`

export const UpdateType = gql`
mutation {
  updateUser(input: {
      type: PROFISSIONAL
  }
  ){
          id
          name
          birthday
          phone
          type
          email
          role
          photo
          description
          phoneverif
          address {
              city
              state
              street
              zipcode
              number
              complement
              neighborhood
              country
          }                   
      }
  }
`

export const VerifyEmail = gql`
query ($email: String){
    allUsers(filter: {
    email: $email
    }){
    id
    email
    name
    document
    }
}
`

export const UpdateVerifyPhone = gql`
mutation UpdateVerifyPhone(
    $phone: String
     ){
  updateUser(input: {
    phone: $phone
    phoneverif: true
  }){
  id
  name
  birthday
  phone
  role
  type
  email
  photo
  description
  phoneverif
  document
  address {
    city
    state
    street
    zipcode
    number
    complement
    neighborhood
    country
  }                                  
}
}
`

export const UpdateEmail = gql`
mutation UpdateUser(
    $email: String,
     ){
  updateUser(
  input: {
      email: $email,
  }
){       
  id
  name
  birthday
  phone
  type
  email
  role
  photo
  document
  description
  phoneverif
  address {
    city
    state
    street
    zipcode
    number
    complement
    neighborhood
    country
  }
}
}
`

export const RecoverUser = gql`
mutation(
  $email: String!
  $password: String!
){
  recoverUserPassword(
    email: $email
    password: $password
  )
}`