import React from "react"
import './imgPage.scss'

export default class ImgPage extends React.Component {
    render() {
        return (
            <div id={'ImgPage'} className="col-xs-12">
                <div className="row">
                    <div className="col-xs-12 col-sm-8 col-md-6">
                        <div className="row">{this.props.children}</div>
                    </div>
                    <div className={'pic col-sm-4 col-md-6'}>
                    </div>
                </div>

            </div>
        )
    }
}
