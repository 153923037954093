import React, { useEffect, useState } from "react"
import './register.scss'
import Navbar from '../../components/newNavbar/navbar'
import Button from '../../components/newButton/button'
import Loading from '../../components/loading/loading'
import Input from '../../components/newInput/input'
import ImgPage from '../../components/imgPage/imgPage'
import { CreateUser, VerifyEmail } from '../../queries/player'
import ErrorM from '../../components/ErrorMessange/errormenssage'
import * as emailjs from 'emailjs-com'
import PasswordMeter from '../../components/PassworMeter/passwordmeter'
import ErrorBroundray from '../../components/errorBoundary/errorBoundary'
import ReactPixel from 'react-facebook-pixel'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Notify, Report } from 'notiflix'

export default () => {
    const LastURL = JSON.parse(sessionStorage.getItem('LastURL'))
    const cache = JSON.parse(sessionStorage.getItem('OITO'))
    const [findEmail, { data, loading: spinnerEmail }] = useLazyQuery(VerifyEmail)
    const [saveRegister, { loading }] = useMutation(CreateUser)
    const [name, setName] = useState()
    const [nameErr, setNameErr] = useState()
    const [nameOk, setNameOk] = useState(false)
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [emailErr, setEmailErr] = useState()
    const [emailOk, setEmailOk] = useState(false)
    const [password, setPassword] = useState()
    const [passwordErr, setPasswordErr] = useState()
    const [passwordOk, setPasswordOk] = useState(false)
    const [termo, setTermo] = useState(false)

    useEffect(() => {
        if (LastURL) {
            if (LastURL.path !== window.location.pathname) {
                const listHistory = {
                    count: parseInt(LastURL.count) - 1,
                    path: window.location.pathname
                }
                sessionStorage.setItem('LastURL', JSON.stringify(listHistory))
            }
        }
    }, [])// eslint-disable-line

    useEffect(() => {
        if (cache) {
            window.location.href = "/perfil"
        }
    }, [])// eslint-disable-line

    const options = {
        autoConfig: true, 	// set pixel's autoConfig
        debug: false, 		// enable logs
    };
    ReactPixel.init('1245215422327975', options);
    ReactPixel.init('676541136290102', options);

    const blurName = (e) => {
        if (e) {
            setName(e)
            if (e.length >= 5 && e.includes(' ')) {
                setName(e)
                setNameErr()
                setNameOk(true)
                // this.setState({ nameok: true, nameErr: null })
            } else {
                setNameErr(<ErrorM content='Por favor, digite seu nome completo.' />)
                setNameOk(false)
            }
        } else {
            setNameErr()
            setNameOk(false)
        }
    }

    const blurEmail = (e) => {
        if (e && e.length > 5) {
            if (e.includes('@') && e.split("@")[1].includes('.')) {
                const emailValid = e.trim()
                setEmail(emailValid)

                findEmail({
                    variables: {
                        email: emailValid
                    }
                })
            } else {
                setEmailErr(<ErrorM content='E-mail inválido. Por favor digite novamente. Ex.: jose@email.com' />)
                setEmailOk(false)
            }
        } else {
            setEmailErr()
            setEmailOk(false)
        }
    }

    useEffect(() => {
        if (data) {
            if (data.allUsers.length > 0) {
                setEmailOk(false)
                setEmailErr(<ErrorM content='E-mail já cadastrado.' />)
            } else {
                setEmailOk(true)
                setEmailErr()
            }
        }
    }, [data])

    const blurPassword = (e) => {
        var regex = /^(?=(?:.*?[0-9]){2})(?=(?:.*?[a-zA-Z]){2})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/;

        if (e) {
            setPassword(e)
            if (e.length >= 8) {
                if (regex.exec(e)) {
                    setPasswordOk(true)
                    setPasswordErr()
                } else {
                    setPasswordOk(false)
                    setPasswordErr(<ErrorM content='A senha deve ter, no mínimo, 2 números e 2 letras.' />)
                }
            } else {
                setPasswordOk(false)
                setPasswordErr(<ErrorM content='A senha deve ter, no mínimo, 8 caracteres.' />)
            }
        } else {
            setPasswordOk(false)
            setPasswordErr()
        }
    }

    const sendRegister = (e) => {

        e.preventDefault()

        if (nameOk && emailOk && passwordOk && phone && termo) {

            saveRegister({
                variables: {
                    email: email,
                    password: password,
                    name: name,
                    phone: phone,
                }
            }).then(
                res => {
                    console.log(res)
                    const userAuth = {
                        token: res.data.authUser.token,
                        user: res.data.authUser.user
                    }

                    sessionStorage.setItem('OITO', JSON.stringify(userAuth))

                    //enviar email
                    var template_params = {
                        "email": res.data.createUser.email,
                        "name": res.data.createUser.name,
                    }

                    emailjs.send('noreply', 'bemvindo', template_params, "user_IagwdTtEr3AVRdLhxkyKc")
                        .then((response) => {
                            console.log('SUCCESS!', response.status, response.text)
                        }, function (error) {
                            console.log('FAILED...', error)
                        });

                    return Report.Success(
                        "Cadastro atualizado com sucesso!",
                        "",
                        "Fechar",
                        () => window.location.href = "/verificartelefone?sucesso"
                    )
                }
            ).catch(err => {
                console.log(err)
                return Report.Failure(
                    "Erro ao cadastrar.",
                    "Aconteceu alguma coisa. Tente novamente mais tarde.",
                    "Voltar"
                )
            })
        } else {
            if (!nameOk) Notify.Failure('Por favor, digite seu nome completo.')

            if (!emailOk) Notify.Failure('Por favor, digite um e-mail válido e que não tenha sido usado.')

            if (!passwordOk) Notify.Failure('A senha deve ter, no mínimo, 2 números, 2 letras e 8 caracteries.')

            if (!phone) Notify.Failure('Por favor, numero de celular valido e que não tenha sido usado.')

            if (!termo) Notify.Failure('É obrigátorio aceitar os termos de uso.')
        }
    } //enviar cadastro

    if (loading) return <Loading />

    return (
        <>
            <ErrorBroundray><Navbar /></ErrorBroundray>
            <ErrorBroundray><ImgPage>

                <div className={'register-div'}>
                    <div style={{ marginBottom: "50px" }}>
                        <h1>Cadastre-se</h1>
                        <form onSubmit={(e) => sendRegister(e)} >
                            <Input name={'name'} placeholder={'Nome completo'} type={'text'} onChange={(e) => blurName(e.target.value)} required={true} />
                            {nameErr}
                            <Input name={'phone'} placeholder={'Celular'} mask="(99) 99999-9999" type={'phone'} onChange={(e) => setPhone(e.target.value)} required={true} />

                            <Input name={'email'} placeholder={'E-mail'} type={'email'} onChange={(e) => blurEmail(e.target.value)} required={true} loading={!!spinnerEmail} />
                            {emailErr}
                            <Input name={'password'} isPassword={true} placeholder={'Senha'} type={'password'} required={true} minlength={8} onChange={(e) => blurPassword(e.target.value)} />
                            <PasswordMeter password={password} />
                            {passwordErr}

                            <div style={{ textAlign: 'center', marginBottom: 15, marginTop: 15 }}>
                                <input type="checkbox" style={{ width: 'auto' }} name="termos" defaultChecked={termo} onChange={(e) => setTermo(e.target.checked)} required={true} />
                                Aceito os <a href="/termosUso" target="_blank">Termos de uso</a>
                            </div>
                            {(nameOk && emailOk && passwordOk && phone && !termo) && <ErrorM content='É obrigatório aceitar os termos de uso.' />}

                            <div>
                                <Button type='submit' name={'prosseguir'} disabled={!(nameOk && emailOk && passwordOk && phone && termo)} />
                            </div>
                        </form>
                    </div>
                </div>
            </ImgPage></ErrorBroundray>
        </>
    )
}
