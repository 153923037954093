import React from 'react'
import './elementLoading.scss'

export default () =>
<div className="loading-item">
    <div>
        <div className={'rotate-spin'}>
            <svg style={{width:"36px", height:"36px"}} viewBox="0 0 48 48">
                <path fill="#ff8000" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
        </div>
        {/* <h2>carregando</h2> */}
    </div>
</div>
