import React, { useState, useEffect, useLayoutEffect } from 'react'
import './avaliationPropose.scss'
import Helmet from 'react-helmet'
import Loading from '../../../components/loading/loading'
import ClicableAvaliationStar from "../../../components/clicableAvaliationStar/clicableAvaliationStar";
import TextInput from "../../../components/textInput/textInput"
import { ProposeId } from '../../../queries/propose'
import AvaliationOito from '../../../components/avaliationOito/avaliationOito'
import Button from '../../../components/newButton/button'
import { Report } from "notiflix"
import { CreateRank } from '../../../queries/rank'
import Tooltip from '../../../components/tooltip/tooltip'
import ErrorBoundary from '../../../components/errorBoundary/errorBoundary'
import { useMutation, useQuery } from '@apollo/client';

export default () => {
    const proposeId = window.location.pathname.split('/')[(window.location.pathname.split('/').length - 1)]
    const cache = JSON.parse(sessionStorage.getItem('OITO'))
    const noresponse = !!window.location.search === "?noresponse"
    const { data: proposeData, loading } = useQuery(ProposeId, {
        variables: {
            id: proposeId
        }
    })
    const [create, { loading: loadingMutation }] = useMutation(CreateRank)
    const [user, setUser] = useState()
    const [propose, setPropose] = useState()
    const [impro, setImpro] = useState(false)
    const [otherUser, setOtherUser] = useState()

    useEffect(() => {
        if (!cache) {
            Report.Failure(
                "Você não está logado!",
                "Entre ou cadastre-se. É gratuito!",
                "Fechar",
                () => window.location.href = "/login"
            )
        } else {
            setUser(cache.user)
        }
    }, []);// eslint-disable-line

    useLayoutEffect(() => {
        if (proposeData) {
            setPropose(proposeData.propose)

            const propose = proposeData.propose

            if (propose.from.id === user.id) {
                setOtherUser(propose.to)
            } else {
                setOtherUser(propose.from)

                if (!impro) setImpro(true)
            }

            if (propose.ranks && propose.ranks.length > 0) {
                propose.ranks.find(rank => {
                    // console.log(rank)
                    return rank.from.id === user.id &&
                        Report.Failure(
                            'Você já avaliou esta proposta.',
                            'Esta proposta já foi avaliada por você. Clique para voltar aos detalhes da proposta',
                            'Fechar',
                            () => window.location.href = '/perfil/proposedetails/' + proposeId
                        )
                })
            }
        }
    }, [proposeData]) // eslint-disable-line

    let rank = {}

    const sendRank = e => {
        e.preventDefault()


        if (rank.description) {
            if (impro) {
                if ((rank.custoC && rank.pontualidadeC && rank.respeitoC && rank.transparenciaC && rank.rating) || rank.media) {
                    const media = parseInt((rank.custoC + rank.pontualidadeC + rank.respeitoC + rank.transparenciaC) / 4) || rank.media

                    create({
                        variables: {
                            custoC: rank.custoC && parseInt(rank.custoC),
                            pontualidadeC: rank.pontualidadeC && parseInt(rank.pontualidadeC),
                            respeitoC: rank.respeitoC && parseInt(rank.respeitoC),
                            transparenciaC: rank.transparenciaC && parseInt(rank.transparenciaC),
                            media: media,
                            toId: otherUser.id,
                            proposeId: proposeId,
                            rating: rank.rating,
                            typeTo: "CLIENTE",
                            description: rank.description,
                        }
                    }).then(
                        res => {
                            console.log(res)
                            Report.Success(
                                'Avaliação enviada com sucesso!',
                                'Em breve a outra parte também avaliará você. Quanto mais serviços fizer, melhor será sua nota.',
                                'Fechar',
                                () => window.location.href = `/perfil/rank`
                            )
                        }).catch(err => {
                            console.log(err)
                            Report.Failure(
                                "Erro ao enviar a avaliação.",
                                "Algo de errado aconteceu. Tente novamente mais tarde.",
                                "Fechar",
                                () => window.location.reload()
                            )
                        })
                } else {
                    Report.Warning(
                        "Formulário incompleto.",
                        "Por favor preencha todos os campos. Clique nas estrelas para dar nota, no boneco para avaliar de modo geral, e escreva um comentário.",
                        "Fechar"
                    )
                }
            } else {
                if ((rank.agilidade && rank.atendimento && rank.ferramentas && rank.solucao
                    && rank.resposta && rank.custo && rank.pontualidade && rank.rating) || rank.media) {

                    const media = parseInt((rank.agilidade + rank.atendimento + rank.ferramentas + rank.solucao
                        + rank.resposta + rank.custo + rank.pontualidade) / 7) || rank.media

                    create({
                        variables: {
                            agilidade: rank.agilidade && parseInt(rank.agilidade),
                            atendimento: rank.atendimento && parseInt(rank.atendimento),
                            ferramentas: rank.ferramentas && parseInt(rank.ferramentas),
                            solucao: rank.solucao && parseInt(rank.solucao),
                            resposta: rank.resposta && parseInt(rank.resposta),
                            custo: rank.custo && parseInt(rank.custo),
                            pontualidade: rank.pontualidade && parseInt(rank.pontualidade),
                            media: media,
                            toId: otherUser.id,
                            proposeId: proposeId,
                            rating: rank.rating,
                            typeTo: "PROFISSIONAL",
                            description: rank.description,
                        }
                    }).then(
                        res => {
                            console.log(res)
                            Report.Success(
                                'Avaliação enviada com sucesso!',
                                'Em breve a outra parte também avaliará você. Quanto mais serviços fizer, melhor será sua nota.',
                                'Fechar',
                                () => window.location.href = `/perfil/rank`
                            )
                        }).catch(err => {
                            console.log(err)
                            Report.Failure(
                                "Erro ao enviar a avaliação.",
                                "Algo de errado aconteceu. Tente novamente mais tarde.",
                                "Fechar",
                                () => window.location.reload()
                            )
                        })
                } else {
                    Report.Warning(
                        "Formulário incompleto.",
                        "Por favor preencha todos os campos. Clique nas estrelas para dar nota, no boneco para avaliar de modo geral, e escreva um comentário.",
                        "Fechar"
                    )
                }
            }
        }
    }

    const getDataFromStars = (data) => {
        // console.log(data);
        rank[`${data.name}`] = data.value
    }

    if (loading || loadingMutation) return <Loading />

    return (
        <div className="col-xs-12" style={{ maxWidth: '980px' }}>
            <Helmet title="Oito - Avaliar">
                <meta name="description" content="Avalie a qualidade dos serviços prestados pelos profissionais encontrados em Oito.app, ou de clientes que contrataram serviços em Oito.app." />
                <meta name="keywords" content="Avaliação, Profissionais, Oportunidades" />
                <meta name="robots" content="index, follow" />
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="Portuguese" />
                <meta name="revisit-after" content="1 days" />
            </Helmet>

            { propose && user && otherUser && <div className="proposeAvaliation row center-xs">
                <section className='header col-xs-12'>
                    <h1>Avalie {otherUser.name} - {propose.anuncio.skill.name}</h1>
                    {!impro ? <p><small>Você pode qualificar o serviço que contratou dando estrelas de 1 a 5, sendo 1 quando você estiver totalmente insatisfeito e 5 quando estiver totalmente satisfeito. Assim você qualifica prestadores de serviço, otimiza nossa plataforma e ajuda outros clientes.</small></p> :
                        <p><small>Você pode qualificar o cliente que contratou seus serviços dando estrelas de 1 a 5, sendo 1 quando você estiver totalmente insatisfeito e 5 quando estiver totalmente satisfeito. Assim você qualifica clientes, otimiza nossa plataforma e ajuda outros profissionais.</small></p>}
                </section>

                <section className={'notes col-xs-12'}>
                    <div className={'questions row'}>
                        <h2>Classifique</h2>
                        {noresponse ?
                            <div className="col-xs-12">
                                <Tooltip text='Como você avalia seu contato com a outra parte? Mesmo com o serviço não realizado, a comunicação foi boa? Foi educado, deu as devidas explicações? Retornou em tempo habil?' />
                                <span>Avalie sua experiência com o {impro ? "cliente" : "profissional"}.</span>
                                <ErrorBoundary><ClicableAvaliationStar name="media" callbackFunctions={getDataFromStars.bind(this)} /></ErrorBoundary>

                            </div>
                            : impro ? <div className="row center-xs bottom-xs listnotes">
                                <div className="col-xs-12 row" style={{ maxWidth: "500px" }}>
                                    <Tooltip text='O valor pago é justo em relação ao serviço prestado?' />
                                    <span>Custo-benefício</span>
                                    <ErrorBoundary><ClicableAvaliationStar name="custoC" callbackFunctions={getDataFromStars.bind(this)} /></ErrorBoundary>
                                </div>

                                <div className="col-xs-12 row" style={{ maxWidth: "500px", marginTop: "15px" }}>
                                    <Tooltip text='O cliente que contratou seu serviço pagou de forma pontual?' />
                                    <span>Pontualidade no pagamento</span>

                                    <ErrorBoundary><ClicableAvaliationStar name="pontualidadeC" callbackFunctions={getDataFromStars.bind(this)} /></ErrorBoundary>
                                </div>

                                <div className="col-xs-12 row" style={{ maxWidth: "500px", marginTop: "15px" }}>
                                    <Tooltip text='O cliente que contratou você foi respeitoso e atencioso durante sua prestação de serviço?' />
                                    <span>Respeito no atendimento</span>

                                    <ErrorBoundary><ClicableAvaliationStar name="respeitoC" callbackFunctions={getDataFromStars.bind(this)} /></ErrorBoundary>
                                </div>

                                <div className="col-xs-12 row" style={{ maxWidth: "500px", marginTop: "15px" }}>
                                    <Tooltip text='A descrição do serviço estava coerente com o que fora executado?' />
                                    <span>Transparência no serviço</span>

                                    <ErrorBoundary><ClicableAvaliationStar name="transparenciaC" callbackFunctions={getDataFromStars.bind(this)} /></ErrorBoundary>
                                </div>
                            </div>
                                :
                                <div className="row center-xs bottom-xs listnotes">
                                    <div className="col-xs-12 row" style={{ maxWidth: "500px", marginTop: "15px" }}>
                                        <Tooltip text='O serviço foi feito de forma ágil e eficiente?' />
                                        <span>Agilidade no serviço</span>
                                        <ErrorBoundary><ClicableAvaliationStar name="agilidade" callbackFunctions={getDataFromStars.bind(this)} /></ErrorBoundary>
                                    </div>

                                    <div className="col-xs-12 row" style={{ maxWidth: "500px", marginTop: "15px" }}>
                                        <Tooltip text='O prestador de serviço atendeu você com respeito, dedicação e transparência?' />
                                        <span>Atendimento</span>
                                        <ErrorBoundary><ClicableAvaliationStar name="atendimento" callbackFunctions={getDataFromStars.bind(this)} /></ErrorBoundary>
                                    </div>

                                    <div className="col-xs-12 row" style={{ maxWidth: "500px", marginTop: "15px" }}>
                                        <Tooltip text='Como você avalia a relação de valores e entrega do serviço?' />
                                        <span>Custo-benefício</span>
                                        <ErrorBoundary><ClicableAvaliationStar name="custo" callbackFunctions={getDataFromStars.bind(this)} /></ErrorBoundary>
                                    </div>

                                    <div className="col-xs-12 row" style={{ maxWidth: "500px", marginTop: "15px" }}>
                                        <Tooltip text='O prestador de serviço levou todas as ferramentas necessárias para a execução do serviço?' />
                                        <span>Ferramentas necessárias</span>
                                        <ErrorBoundary><ClicableAvaliationStar name="ferramentas" callbackFunctions={getDataFromStars.bind(this)} /></ErrorBoundary>
                                    </div>

                                    <div className="col-xs-12 row" style={{ maxWidth: "500px", marginTop: "15px" }}>
                                        <Tooltip text='Todos os prazos combinados durante a negociação e execução dos serviços foram cumpridos sem atrasos?' />
                                        <span>Pontualidade</span>
                                        <ErrorBoundary><ClicableAvaliationStar name="pontualidade" callbackFunctions={getDataFromStars.bind(this)} /></ErrorBoundary>
                                    </div>

                                    <div className="col-xs-12 row" style={{ maxWidth: "500px", marginTop: "15px" }}>
                                        <Tooltip text='O prestador de serviço usou o chat de forma ágil, respeitosa e clara?' />
                                        <span>Respostas no chat</span>
                                        <ErrorBoundary><ClicableAvaliationStar name="resposta" callbackFunctions={getDataFromStars.bind(this)} /></ErrorBoundary>
                                    </div>

                                    <div className="col-xs-12 row" style={{ maxWidth: "500px", marginTop: "15px" }}>
                                        <Tooltip text='O prestador de serviço contratado solucionou seu problema de forma eficiente?' />
                                        <span>Solução do problema</span>
                                        <ErrorBoundary><ClicableAvaliationStar name="solucao" callbackFunctions={getDataFromStars.bind(this)} /></ErrorBoundary>
                                    </div>
                                </div>
                        }
                    </div>

                    <div className="row satisfaction">
                        <h2>Qual sua satisfação com o cliente?</h2>
                        <div className="col-xs-12">
                            <ErrorBoundary><AvaliationOito name="rating" callbackFunctions={getDataFromStars.bind(this)} /></ErrorBoundary>
                        </div>
                    </div>
                </section>

                <section className="col-xs-12 row comment">
                    <h2>Comentário</h2><Tooltip text='Deixe um comentário de recomendação. Seja educado, o comentário é publico, mas não exibe o autor.' />

                    <form className="col-xs-12" onSubmit={(e) => sendRank(e)}>
                        <TextInput placeholder='Digite aqui um comentário sobre o profissional' name="description" onChange={(e) => rank.description = e.target.value} />

                        <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                            <Button name='Salvar avaliação' type='submit' />
                        </div>
                    </form>
                </section>
            </div>}
        </div>
    )
}
