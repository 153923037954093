import React from "react"
import './cardFeaturedPro.scss'

import { Link } from 'react-router-dom'
import AvaliationStar from '../avaliationStars/avaliationStars'

import userLogo from '../../assets/user_logo.svg'

const CardFeaturedPro = (props) => {
    const {
        name, photo, mainJob, avaliationStar, link
    } = props;

    return (
        <Link to={"/profile/" + link} style={{ color: '#5F5F5F' }}>
            <div className={'cardFeatured'} style={{ zIndex: '-1' }}>
                <div className={'imgContainer'}>
                    <img src={photo ? photo : userLogo} alt={name} />
                </div>
                <div className={'content'} style={{ zIndex: '-1' }}>
                    <h1>{name ? name : 'Nome de usuário'}</h1>
                    {mainJob && mainJob.length && mainJob.map((anun, i) => (
                        anun.active ? <span key={i}>{anun.skill.name}</span> : null
                    ))}
                    <AvaliationStar avaliation={avaliationStar ? avaliationStar : 0} />
                </div>
            </div>
        </Link>
    )
}

export default CardFeaturedPro