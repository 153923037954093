import dotenv from 'dotenv'
import * as totalvoice from 'totalvoice-node'

dotenv.config()

const base = process.env.REACT_APP_ENDPOINT
// const base = "https://oitoapi.herokuapp.com/"
export const endpoint = base + 'graphql'
export const endpointRest = base + "api/"
export const endpontStorage = base + "storage/"

// export const websocket = "wss://subscriptions.us-west-2.graph.cool/v1/oitoapi"

export const tokensms = '9eaecc364c8f54e88377aee84517c2dd'

export const clientSms = new totalvoice(tokensms)

export const userEmailJS = "user_IagwdTtEr3AVRdLhxkyKc"

// export const configaws = {
//     bucketName: 'oitofileserver',
//     dirName: '/avatar', /* optional */
//     region: 'us-east-2',
//     accessKeyId: 'AKIAIRQOY4YNAVZ2W75A',
//     secretAccessKey: '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'
// }
