import React, { useEffect, useState } from "react";

import "./registerAnnouncement.scss";

import IconCtg from "../../../components/iconCtg/iconCtg";
import Input from "../../../components/newInput/input";
import Button from "../../../components/newButton/button";
import Loading from "../../../components/loading/loading";
import { FileInput } from "../../../components/fileInput/fileInput";
import AnnoucementCard from "../../../components/annoucementCard/annoucementCard";
import { Categories, NewHab } from "../../../queries/category";
import axios from "axios";
import Modal from "../../../components/Modal/modal";
import PhotoZoom from "../../../components/photoZoom/photoZoom";
import { endpontStorage } from "../../../queries/endpoint";
import {
  CreateAnuncio,
  AllAnuncios,
  UpdateAnuncio,
  StatusAnuncio,
  UpdateFilesAnuncio,
} from "../../../queries/anuncios";
import { UpdateType } from "../../../queries/player";
import ViewAnnouncement from "../../../components/viewAnnuoncement/viewAnnouncement";
import ErrorBoundary from "../../../components/errorBoundary/errorBoundary";
import { animateScroll as scroll } from "react-scroll";
import LoadingProgress from "../../../components/loadingprogress/loadingprogress";
import { useQuery, useMutation } from "@apollo/client";
import { Report, Notify } from "notiflix";
import Select from "react-select";

export default () => {
  const cache = JSON.parse(sessionStorage.getItem("OITO"));
  const { data: categoriesList } = useQuery(Categories);
  const { data: anunciosList, loading, refetch } = useQuery(AllAnuncios, {
    variables: {
      userId: cache && cache.user.id,
    },
  });
  const [saveAnuncio, { loading: loadingSaveAnuncio }] = useMutation(
    CreateAnuncio
  );
  const [setUserPro, { loading: loadingsetUserPro }] = useMutation(UpdateType);
  const [statusAnuncio, { loading: loadingstatusAnuncio }] = useMutation(
    StatusAnuncio
  );
  const [editableAnuncio, { loading: loadingeditableAnuncio }] = useMutation(
    UpdateAnuncio
  );
  const [salveImages, { loading: loadingsalveImages }] = useMutation(
    UpdateFilesAnuncio
  );
  const [saveSugestions, { loading: loadingsaveSugestions }] = useMutation(
    NewHab
  );
  const [user, setUser] = useState();
  const [skillsOptions, setSkillsOptions] = useState([]);
  const [skillSelected, setSkillSelected] = useState();
  const [setPrice, setSetPrice] = useState(false);
  const [newPro, setNewPro] = useState(true);
  const [createAnun, setCreateAnun] = useState(false);
  const [newAnun, setNewAnun] = useState(true);
  const [show, setShow] = useState(false);
  const [showViewer, setShowViewer] = useState(false);
  const [anuncioSelected, setAnuncioSelected] = useState();
  const [openZoom, setOpenZoom] = useState(false);
  const [zoomUrl, setZoomUrl] = useState();
  const [zoomAlt, setZoomAlt] = useState();
  const [isEditable, setIsEditable] = useState(false);
  const [showDisableAnuncio, setShowDisableAnuncio] = useState(false);
  const [fileName, setFileName] = useState("Escolha um arquivo");
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [arqTotal, setArqTotal] = useState();
  const [arq, setArq] = useState();
  const [now, setNow] = useState();
  const [loadingGeneral, setLoadingGeneral] = useState(false);
  const [anuncios, setAnuncios] = useState();

  useEffect(() => {
    if (cache) {
      setUser(cache.user);

      if (
        cache.user.document === null ||
        cache.user.birthday === null ||
        cache.user.address.city === null
      ) {
        Report.Failure(
          "Você não completou seu cadastro!",
          "Para criar um anúncio é necessário que você complete o cadastro.",
          "Completar cadastro",
          () => (window.location.href = "/perfil/completarcadastro")
        );
      } else if (!cache.user.phoneverif) {
        Report.Failure(
          "Você não verificou o seu celular!",
          "Isso é um requisito para que você consiga criar um anúncio.",
          "Verificar telefone",
          () => (window.location.href = "/verificartelefone")
        );
      }
    } else {
      Report.Failure(
        "Você não está logado!",
        "Entre ou cadastre-se. É gratuito!",
        "Fechar",
        () => (window.location.href = "/login")
      );
    }
  }, []); // eslint-disable-line

  const openImg = (imgUrl, imgAlt) => {
    setOpenZoom(!openZoom);
    setZoomUrl(imgUrl);
    setZoomAlt(imgAlt);
  };

  const activeToggle = (e) => {
    setShowDisableAnuncio(!showDisableAnuncio);
    setAnuncioSelected(e);
  };

  const activeViewer = (e) => {
    setShowViewer(!showViewer);
    setAnuncioSelected(e);
  };

  const createAnuncio = (e) => {
    e.preventDefault();

    const description = e.target.elements.description.value;
    let price =
      (e.target.elements.price &&
        e.target.elements.price.value.split("R$ ")[1]) ||
      "a combinar";

    if (
      description === "" ||
      description === null ||
      description === undefined
    ) {
      return Report.Failure(
        "Você não pode deixar a descrição vazia.",
        "Por favor digite uma breve descrição do seu serviço. É ela que irá conquistar seus clientes.",
        "Voltar"
      );
    }

    if (price !== "a combinar") {
      if (price.split(",")[1] === "") {
        price = price + "00";
      }
    }

    if (newAnun) {
      
      anunciosList.allAnuncios.filter((anuncio) => {
        return (
          anuncio.skill.id === skillSelected &&
          Report.Failure(
            "Este anúncio já existe.",
            "Você já possui um anúncio relacionado a essa habilidade. Escolha outra ou edite o anúncio já existente.",
            "Voltar",
            () => closeAlert()
          )
        );
      });

      let sum = 0;

      anunciosList.allAnuncios.map((a) => a.active && sum++);
      
      if (sum >= 3) {
        return Report.Failure(
          "Quantidade máxima excedida.",
          "Você já atingiu a quantidade máxima de anúncios ativos permitidos. Caso deseje adicionar uma nova habilidade, desabilite alguma.",
          "Voltar",
          () => closeAlert()
        );
      }

      saveAnuncio({
        variables: {
          description: description,
          skill: skillSelected,
          price: price,
          city: user.address.city,
          state: user.address.state,
        },
      })
        .then((res) => {
          if (user.type !== "PROFISSIONAL") {
            setUserPro().then((response) => {
              const userAuth = {
                token: cache.token,
                user: response.data.updateUser,
              };

              sessionStorage.setItem("OITO", JSON.stringify(userAuth));
            });
          }

          return Report.Success(
            "Cadastro realizado com sucesso!",
            "",
            "Fechar",
            () => closeAlert(res.data.createAnuncio)
          );
        })
        .catch(() => {
          return Report.Failure(
            "Erro ao criar anúncio.",
            "Aconteceu alguma coisa. Tente novamente mais tarde.",
            "Voltar"
          );
        });
    } else {
      return Report.Failure(
        "Quantidade máxima excedida.",
        "Você já atingiu a quantidade máxima de anúncios ativos permitidos. Caso deseje adicionar uma nova habilidade, desabilite alguma.",
        "Voltar",
        () => closeAlert()
      );
    }
  };

  const statusSkill = (anuncio) => {
    if (!anuncio.active) {
      let sum = 0;

      anunciosList.allAnuncios.map((a) => a.active && sum++);

      activeToggle();

      if (sum >= 3) {
        return Report.Failure(
          "Quantidade máxima excedida.",
          "Você já atingiu a quantidade máxima de anúncios ativos permitidos. Caso deseje adicionar uma nova habilidade, desabilite alguma.",
          "Voltar"
        );
      }
    }

    statusAnuncio({
      variables: {
        id: anuncio.id,
        active: !anuncio.active,
      },
    })
      .then((res) => {
        if (!res.data.updateAnuncio.active) setNewAnun(true);

        Notify.Success(
          `Anúncio ${
            res.data.updateAnuncio.active ? "ativado" : "desativado"
          } com sucesso.`
        );

        refetch();

        activeToggle();
      })
      .catch(function (error) {
        console.log(error);
        activeToggle();

        return Report.Failure(
          "Erro ao editar o anúncio.",
          "Alguma coisa aconteceu. Tente novamente mais tarde.",
          "Voltar"
        );
      });
  };

  const editSkill = (anuncio) => {
    scroll.scrollToBottom();
    setAnuncioSelected(anuncio);
    setIsEditable(true);
  };

  const editAnuncio = (e) => {
    e.preventDefault();

    const description = e.target.elements.descricao.value;
    let price = e.target.elements.preco.value.split("R$ ")[1] || "a combinar";

    if (
      description === "" ||
      description === null ||
      description === undefined
    ) {
      return Report.Failure(
        "Você não pode deixar a descrição vazia.",
        "Por favor digite uma breve descrição do seu serviço. É ela que irá conquistar seus clientes.",
        "Voltar"
      );
    }

    if (price !== "a combinar") {
      if (price.split(",")[1] === "") {
        price = price + "00";
      }
    }

    editableAnuncio({
      variables: {
        id: anuncioSelected.id,
        description,
        price,
      },
    })
      .then(() => {
        refetch();

        setIsEditable(false);
        return Report.Success("Anúncio alterado com sucesso!", "", "Fechar");
      })
      .catch(function (error) {
        console.log(error);

        return Report.Failure(
          "Erro ao criar anúncio.",
          "Aconteceu alguma coisa. Tente novamente mais tarde.",
          "Voltar"
        );
      });
  };

  const closeAlert = (id) => {
    if (id !== undefined) editSkill(id);
  };

  const saveImg = async (file, i) => {
    setFileName(file.name);
    setArqTotal(i + 1);

    let data = new FormData();
    data.append("data", file);

    await axios
      .post(endpontStorage + "upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${cache.token}`,
        },
        onUploadProgress: (ProgressEvent) => {
          setNow(parseInt((ProgressEvent.loaded / ProgressEvent.total) * 100));
        },
      })
      .then((res) => {
        let newFiles = [...anuncioSelected.files];

        newFiles = [...new Set([...newFiles, res.data.data.url])];

        if (newFiles.length) {
          salveImages({
            variables: {
              id: anuncioSelected.id,
              files: newFiles,
            },
          })
            .then(() => {
              setAnuncioSelected({ ...anuncioSelected, files: newFiles });
              setFileName("Escolha um arquivo");
              refetch();

              Notify.Success("Imagem salva com sucesso.");
            })
            .catch((err) => {
              console.log(err);
              return Report.Failure(
                "Erro ao salvar a imagem.",
                "Alguma coisa aconteceu. Tente novamente mais tarde.",
                "Voltar"
              );
            });
        }
      })
      .catch((err) => {
        console.log(err);
        return Report.Failure(
          "Erro ao salvar a imagem.",
          "Alguma coisa aconteceu. Tente novamente mais tarde.",
          "Voltar"
        );
      });
  };

  const sendFile = async (e) => {
    if (e.target && e.target.files.length > 0) {
      setLoadingProgress(true);

      const files = e.target.files;

      setArqTotal(files.length);

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        setArq(i + 1);

        if (file > 5242880) {
          return Report.Failure(
            "Imagem muito grande.",
            "Tente diminuir o tamanho e/ou a qualidade ou escolha outra foto.",
            "Voltar"
          );
        }

        if (file.type.split("/")[0] === "image") {
          switch (file.type.split("image/")[1]) {
            case "jpg":
            case "jpeg":
            case "png":
            case "giff":
            case "bmp":
            case "svg":
              await saveImg(file, i);
              break;
            default:
              return Report.Failure(
                "Formato inválido",
                "Selecione uma imagem válida.",
                "Voltar"
              );
          }
        } else {
          return Report.Failure(
            "Formato inválido",
            "Selecione uma imagem válida.",
            "Voltar"
          );
        }
      }

      refetch();
      setLoadingProgress(false);
      setFileName("Escolha um arquivo");
    }
  };

  const deleteFile = async (file, e) => {
    e.preventDefault();

    setLoadingGeneral(true);

    const fileurl = file.split("https://oitofiles.blob.core.windows.net/")[1];

    const fileId = fileurl.split("/")[1];

    await axios
      .delete(endpontStorage + `${fileId}`, {
        headers: {
          Authorization: `Bearer ${cache.token}`,
        },
      })
      .then(() => {
        const array = [...anuncioSelected.files];

        const index = array.indexOf(file);

        if (index > -1) {
          array.splice(index, 1);
        }

        salveImages({
          variables: {
            id: anuncioSelected.id,
            files: array,
          },
        })
          .then(() => {
            setAnuncioSelected({ ...anuncioSelected, files: array });
            setFileName("Escolha um arquivo");

            refetch();

            Notify.Success("Imagem deletada com sucesso.");
          })
          .catch((err) => {
            console.log(err);
            Report.Failure(
              "Erro ao salvar a imagem.",
              "Alguma coisa aconteceu. Tente novamente mais tarde.",
              "Voltar"
            );
          });
        setLoadingGeneral(false);
      });
  };

  const saveNewHab = (e) => {
    e.preventDefault();

    const newsugestion = e.target.elements.newhab.value;

    if (newsugestion) {
      saveSugestions({
        variables: {
          name: newsugestion,
        },
      })
        .then(() =>
          Notify.Success(
            "Obrigado por enviar uma sugestão de habilidade, em breve vamos avaliar e adicionar a lista."
          )
        )
        .catch(() =>
          Notify.Failure("Algo aconteceu. Tente novamente mais tarde.")
        );

      setShow(!show);
    }
  };

  useEffect(() => {
    if (anunciosList) {
      setAnuncios(anunciosList.allAnuncios);

      if (newPro && anunciosList.allAnuncios.length > 0) {
        setNewPro(false);
      }
    }
  }, [anunciosList]); // eslint-disable-line

  const AnunciosList = () => {
    if (!createAnun && !newPro) {
      let sum = 0;

      anuncios.map((a) => a.active && sum++);

      if (sum >= 3 && newAnun) {
        setNewAnun(false);
      }

      return (
        <div className="row center-xs listannuns">
          {anuncios.map((anuncio, i) => (
            <ErrorBoundary key={i}>
              <AnnoucementCard
                title={anuncio.skill.name}
                desc={anuncio.description}
                price={anuncio.price}
                annouActive={anuncio.active}
                edit={() => editSkill(anuncio)}
                del={() => activeToggle(anuncio)}
                view={() => activeViewer(anuncio)}
              />
            </ErrorBoundary>
          ))}
        </div>
      );
    }

    return null;
  };

  const FormCreateAnuncio = () => {
    const setHabilidadesList = (skills) => {
      scroll.scrollToBottom();

      let options = [];

      skills.map((item) => {
        return (options = [
          ...new Set([...options, { value: item.id, label: item.name }]),
        ]);
      });

      setSkillsOptions(options);
      // this.setState({ filtered: true })
    };

    const selectHabilidade = (e) => {
      scroll.scrollToBottom();
      setSkillSelected(e.value);
    };

    return (
      <div className="row center-xs">
        <p>
          Siga os passos e crie o seu anúncio para divulgar seus serviços e ser
          encontrado mais facilmente!
        </p>
        <div className="col-xs-12 titlecat end-xs">
          <h3>Selecione uma categoria</h3>
          <Button onClick={() => setCreateAnun(!createAnun)} name="Fechar" />
        </div>

        <div className={"col-xs-12 row center-xs catg"}>
          {categoriesList &&
            categoriesList.allCategories.length &&
            categoriesList.allCategories.map((p, i) => (
              <ErrorBoundary key={i}>
                <IconCtg
                  icon={p.file}
                  label={true}
                  name={p.name}
                  onClick={() => setHabilidadesList(p.skills)}
                />
              </ErrorBoundary>
            ))}
        </div>

        {skillsOptions.length > 0 && (
          <div className={"subCatg col-xs-12"} style={{ marginTop: 30 }}>
            <Select
              options={skillsOptions}
              onChange={(e) => selectHabilidade(e)}
            />
            <p onClick={() => setShow(!show)} className="linknew">
              Não encontrou sua habilidade?
            </p>
          </div>
        )}

        {skillSelected && (
          <div className="row center-xs">
            <div className="col-xs-12">
              <h3>Informações do anúncio</h3>
            </div>
            <div className="col-xs-12 start-xs precifing">
              <div>
                <span>Como deseja negociar seu serviço?</span>
              </div>
              <div>
                <button
                  style={{ background: "none", border: "none" }}
                  onClick={() => setSetPrice(!setPrice)}
                >
                  <svg
                    style={{ width: "24px", height: "24px", color: "#707070" }}
                    viewBox="0 0 24 24"
                  >
                    {setPrice ? (
                      <path
                        fill="#ff8000"
                        d="M17,7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7M17,15A3,3 0 0,1 14,12A3,3 0 0,1 17,9A3,3 0 0,1 20,12A3,3 0 0,1 17,15Z"
                      />
                    ) : (
                      <path
                        fill="#707070"
                        d="M17,7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7M7,15A3,3 0 0,1 4,12A3,3 0 0,1 7,9A3,3 0 0,1 10,12A3,3 0 0,1 7,15Z"
                      />
                    )}
                  </svg>
                </button>
                <span>
                  {setPrice ? <b>Precificar</b> : <b>Preço a combinar</b>}
                </span>
              </div>
            </div>

            <form className="col-xs-12 desc" onSubmit={(e) => createAnuncio(e)}>
              <Input
                placeholder="Descreva brevemente o que você faz e dê informações claras, verdadeiras e sucintas."
                name="description"
                maxlength={64}
                required={true}
              />

              {setPrice && (
                <Input
                  placeholder="Insira a sua média de preço por unidade ou tempo de serviço."
                  name="price"
                  money="true"
                />
              )}

              <Button name="Salvar" type="submit" />
            </form>
          </div>
        )}
      </div>
    );
  };

  const FormEditAnuncio = () => {
    return (
      <div className="form-container row center-xs">
        <form
          id="myform"
          className="col-xs-12"
          onSubmit={(e) => editAnuncio(e)}
        >
          <div className="end-xs fechando">
            <Button onClick={() => setIsEditable(!isEditable)} name="Fechar" />
          </div>

          <h3>Editar anúncio de serviço</h3>
          <div className="row center-xs">
            {/* <div style={{marginBottom: '15px'}}>Esse anúncio está ativo? {this.state.active}</div> -------------------COLOCAR A PORRA DO TOOGLE */}

            <div className="col-xs-12">
              <Input
                defaultValue={anuncioSelected.description}
                name="descricao"
                label="Descrição"
                placeholder="Insira uma breve descrição das atividades que realiza."
                type="text"
                maxlength={64}
              />
            </div>
            <div className="col-xs-12">
              <Input
                defaultValue={anuncioSelected.price}
                money="true"
                name="preco"
                label="Valor"
                placeholder="Insira o valor que você cobra para realizar o seu serviço."
              />
            </div>

            <div
              className="col-xs-12"
              style={{ marginBottom: "15px", textAlign: "left" }}
            >
              <h3>Adicionar Foto</h3>
              <FileInput
                name={"fileInputs"}
                onchange={(e) => sendFile(e)}
                accept="image/*"
                id="uploadPortfolio"
                filename={fileName}
              />
            </div>

            <div className="col-xs-12 photo-register-announcement">
              {/* <h3>Fotos</h3> */}
              <div className="row center-xs">
                {anuncioSelected.files.length > 0 &&
                  anuncioSelected.files.map((file, i) => (
                    <div key={i}>
                      <img
                        src={file}
                        alt={""}
                        onClick={() => openImg(file, "")}
                        style={{ height: 150 }}
                      />
                      <Button
                        onClick={(e) => deleteFile(file, e)}
                        name="Remover"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="register-two-buttons col-xs-12">
            <Button type="submit" name={"Salvar"} />
          </div>
        </form>
      </div>
    );
  };

  if (
    loading ||
    loadingGeneral ||
    loadingSaveAnuncio ||
    loadingsetUserPro ||
    loadingstatusAnuncio ||
    loadingsalveImages ||
    loadingeditableAnuncio ||
    loadingsaveSugestions
  )
    return <Loading />;

  return (
    <div className={"registerAnnouncement"}>
      <div className="row center-xs">
        <div className={"title col-xs-12"}>
          <h1>Meus Anúncios</h1>
        </div>
        <div className="col-xs-12 end-xs" style={{ marginBottom: "15px" }}>
          {!newPro && !createAnun && (
            <Button
              onClick={() => {
                setCreateAnun(true);
                scroll.scrollToBottom();
              }}
              name={"criar anúncio"}
            />
          )}
        </div>

        {anuncios && anuncios.length > 0 && <AnunciosList />}
      </div>

      {createAnun || newPro ? (
        <FormCreateAnuncio />
      ) : (
        isEditable && <FormEditAnuncio />
      )}

      {loadingProgress && (
        <LoadingProgress now={now} arq={arq} arqtotal={arqTotal} />
      )}

      <Modal
        onClose={activeToggle}
        show={showDisableAnuncio}
        title={"Situação do anúncio"}
      >
        <p>
          Deseja{" "}
          {anuncioSelected && anuncioSelected.active ? "desativar" : "ativar"}{" "}
          seu anúncio nesse momento?
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => statusSkill(anuncioSelected)}
            name={
              anuncioSelected && anuncioSelected.active
                ? "Desativar o anúncio"
                : "Ativar o anúncio"
            }
          />
          <button className="buttoncancel" onClick={() => activeToggle()}>
            Cancelar
          </button>
        </div>
      </Modal>

      <Modal
        onClose={() => activeViewer()}
        show={showViewer}
        title={"Prévia do anúncio"}
      >
        <ErrorBoundary>
          <ViewAnnouncement player={user} anuncio={anuncioSelected} />
        </ErrorBoundary>
      </Modal>

      <Modal
        show={show}
        onClose={() => setShow(!show)}
        title={"Sugestão de habilidade"}
      >
        <div>
          <p>
            Não encontrou sua habilidade? Nos diga qual é que em breve iremos
            adicionar.
          </p>
          <form id="formpropose" onSubmit={(e) => saveNewHab(e)}>
            <div className="col-12">
              <Input
                name="newhab"
                placeholder="Digita a habilidade que procura"
                type="text"
                required={true}
              />
            </div>
            <div className="col-12">
              <Button name="Enviar" type="submit" />
            </div>
          </form>
        </div>
      </Modal>

      {openZoom && (
        <ErrorBoundary>
          <PhotoZoom
            onClose={() => setOpenZoom(!openZoom)}
            imgUrl={zoomUrl}
            imgAlt={zoomAlt}
          />
        </ErrorBoundary>
      )}
    </div>
  );
};
