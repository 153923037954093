import { gql } from "@apollo/client"

export const AllPosts = gql`
query AllPosts{
  allPosts(filter: {
    published: true
  }
  # , orderBy: id_DESC
  ){
    id
    thumbnail
    title
    updated
    categoria
  }
}
`

export const CreatePost = gql`
mutation AllPosts{
  allPosts(filter: {
    published: true
  }){
    id
    content
    thumbnail
    file
    title
    updated
    categoria
  }
}
`

export const UpdatePost = gql`
mutation AllPosts{
  allPosts(filter: {
    published: true
  }){
    id
    content
    thumbnail
    file
    title
    updated
    categoria
  }
}
`

export const Post = gql`
query Post ($id: ID!){
  post(
    id: $id
    ){
    id
    content
    thumbnail
    file
    title
    updated
    categoria
    published
  }
}
`