import React from "react"
import './button.scss'

export default class Button extends React.Component {
    render() {
        const {
            name,
            onClick,
            type,
            disabled,
            style
        } = this.props;

        // console.log(disabled);

        return(
            <button style={style} className={disabled ? 'button-disabled' : 'button-primary'} onClick={onClick} type={type} disabled={disabled}>{name}</button>
        )
    }
}
