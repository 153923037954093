import React, { Fragment, useState, useEffect } from "react"
import './announcementService.scss'

import Navbar from '../../components/newNavbar/navbar'
import { Select } from '../../components/select/select'
import Loading from '../../components/loading/loading'
import CardFeatured from '../../components/cardFeatured/cardFeatured'
import Input from '../../components/newInput/input'
import Footer from '../../components/newFooter/footer'
import Alert from 'react-bootstrap/Alert'
import { Link } from 'react-router-dom'
import Back from '../../components/backButton/backButton'
import { GraphQLClient } from 'graphql-request'
import { endpoint, endpointRest } from '../../queries/endpoint'
import ErrorBroundray from '../../components/errorBoundary/errorBoundary'
import ReactPaginate from 'react-paginate'
import 'react-paginate/demo/styles/style.css'
import axios from "axios"

const client = new GraphQLClient(endpoint)

const AnnouncementService = () => {

  const [loading, setLoading] = useState(true)
  const [anunciosList, setAnunciosList] = useState([])
  const [states, setStates] = useState([])
  const [skillId, setSkillId] = useState()
  const [skillName, setSkillName] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [pageSize] = useState(20)
  const [stateSelect, setStateSelect] = useState(null)
  const [cities, setCities] = useState([])
  const [citySelect, setCitySelect] = useState(null)
  const [searchQuery, setSearchQuery] = useState()

  const getAnuncios = (params) => {

    return new Promise((resolve) => {
      axios.post(endpointRest + "advertisements",
        params
      ).then(
        res => {

          const anuncios = res.data.data

          let totalPages = 1

          if ((res.data.totalFounded % res.data.pageSize) > 0) {
            totalPages = parseInt(res.data.totalFounded / res.data.pageSize) + 1
          } else {
            totalPages = parseInt(res.data.totalFounded / res.data.pageSize)
          }

          if (anuncios && anuncios.length > 0) {
            anuncios.map(item => {

              if (item.user && item.user.ranks) {

                let count = 0

                item.user.ranks.map(med =>
                  med.typeTo === "PROFISSIONAL" ?
                    count = count + med.media : count
                )

                return item.total = (count / (item.user.ranks.length))
              }

              return null

            })
          }

          return resolve({
            anuncios,
            currentPage: res.data.currentPage,
            pageCount: totalPages,
            loading: false
          })

        })
    })


  }

  useEffect(() => {
    const LastURL = JSON.parse(sessionStorage.getItem('LastURL'));
    if (LastURL) {
      window.location.href = LastURL.perfil
    }
  }, [])

  useEffect(() => {
    client.request(`
      {
        skill( 
          id: "${window.location.pathname.split('/')[(window.location.pathname.split('/').length - 1)].replace('+', '/')}"
          ){
            id
            name
          }
      }
      `).then(res => {
      setSkillId(res.skill.id)
      setSkillName(res.skill.name)
    })
  }, [])

  useEffect(() => {

    if (skillId) {
      client.request(`
    {
      allAnuncios(
        filter: {
        skillId: "${skillId}"
        active: true
      }){
        state
      }
    }
    `).then(res => {

        if (res.allAnuncios.length > 0) {

          const states = res.allAnuncios
          let list = []

          states.map(estado => {
            return list = [
              ...new Set([...list, estado.state])
            ]
          })

          setStates(list.sort())

        }
      })
    }
  }, [skillId])

  useEffect(() => {

    if (skillId) {

      setLoading(true)

      const params = {
        filter: {
          skillId: skillId,
          state: stateSelect,
          city: citySelect
        },
        pageSize: pageSize.toString(),
        currentPage: currentPage.toString()
      }

      getAnuncios(params).then(res => {
        setAnunciosList(res.anuncios)
        setCurrentPage(res.currentPage)
        setPageCount(res.pageCount)
        setLoading(res.loading)
      })

    }


  }, [skillId, currentPage, stateSelect, citySelect, pageSize])

  useEffect(() => {

    if (stateSelect && !citySelect) {
      client.request(`
      {
        allAnuncios(
          filter: {
          skillId: "${skillId}"
          state: "${stateSelect}"
          active: true
        }){
          city
        }
      }
      `).then(res => {

        if (res.allAnuncios.length > 0) {

          const cities = res.allAnuncios
          let list = []

          cities.map(estado => {
            return list = [
              ...new Set([...list, estado.city])
            ]
          })

          setCities(list.sort())

        }
      })
    }
  }, [stateSelect, citySelect, skillId])

  const finder = (query) => {

    const findQuery = encodeURI(query)

    window.location.href = "/anuncios/busca/" + findQuery
  }

  const stateFilter = async (state) => {

    setLoading(true)

    if (state === "clean") {
      document.querySelector('#dropdown-state').selectedIndex = 0
      setStateSelect()
      setCities([])
      setCitySelect()

    } else {

      setStateSelect(state)

      setLoading(false)
    }
  }

  const cityFilter = (city) => {

    setLoading(true)

    if (city === "clean") {
      document.querySelector('#dropdown-cities').selectedIndex = 0
      setCitySelect()
      setCities([])
      stateFilter(stateSelect)
    } else {

      setCitySelect(city)

      setLoading(false)

    }
  }

  return (
    <Fragment>
      <div className={'AnnouncementService col-xs-12'}>
        <div className="row center-xs">
          
          <form className="col-xs-12" style={{ maxWidth: '600px' }} method="post" onSubmit={(e) => {
            e.preventDefault()
            finder(searchQuery)
          }}>
            <Input placeholder={'Pesquisar...'} name={'search'} type="search" onInput={e => setSearchQuery(e.target.value)} />
          </form>
        </div>

        {loading ? <Loading /> : null}

        <div className="row start-xs">
          <Back />

          <div className="buttonFilter col-xs-12" style={{ marginTop: "15px" }}>
            {states.length > 0 && <Select id={"dropdown-state"} onChange={(e) => stateFilter(e.target.value)} name={"Filtrar por estado"} placeholder={'Filtrar por estado'} itens={states} />}
            {cities.length > 0 && <Select id={"dropdown-cities"} onChange={(e) => cityFilter(e.target.value)} name={"Filtrar por cidade"} placeholder={'Filtrar por cidade'} itens={cities} />}
          </div>


          {stateSelect ? <Alert id='selected' onClose={() => stateFilter("clean")} dismissible>{stateSelect}</Alert> : null}
          {citySelect ? <Alert id='selected' onClose={() => cityFilter("clean")} dismissible>{citySelect}</Alert> : null}

        </div>

        <div className="col-xs-12">
          <h1>Anúncios de {skillName}</h1>
        </div>

        <div className="col-xs-12">

          <div className={'sideSlide col-xs-12'}>
            <div className="row around-xs center-xs">
              {anunciosList.length > 0 ? anunciosList.map((anun, i) =>
                <ErrorBroundray key={i}><CardFeatured description={anun.description} avaliationStar={anun.total} mainJob={anun} salary={anun.price} photo={anun.files && anun.files.length > 0 ? anun.files : anun.user.photo} link={anun.userId + '?' + skillId} /></ErrorBroundray>
              ) : <h4>Ainda não temos anúncios desta habilidade. Se você é profissional desta área, seja o primeiro a <Link to="/perfil/meuanuncios">cadastrar um anúncio.</Link></h4>}

            </div>
          </div>

          {anunciosList.length > 0 &&
            <div className="col-xs-12">
              <ReactPaginate
                previousLabel={'Anteriores'}
                nextLabel={'Próximos'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                initialPage={currentPage - 1}
                onPageChange={(e) => setCurrentPage(e.selected + 1)}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            </div>
          }
        </div>
      </div>
      <ErrorBroundray><Navbar /></ErrorBroundray>
      <div style={{ marginTop: "50px" }}>
        <ErrorBroundray><Footer /></ErrorBroundray>
      </div>
    </Fragment >
  )
}

export default AnnouncementService
