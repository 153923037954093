import React from "react"
import './clicableAvaliationStar.scss'

// import { Link } from 'react-router-dom'

import regularStar from '../../assets/icons/star-regular.svg'
import solidStar from '../../assets/icons/star-solid.svg'

export default class ClicableAvaliationStar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            avaliation: 0
        }
    }

    chooseStar = (e) => {
        // console.log(e);
        this.setState({avaliation: e});

        const data = {
            value: e,
            name: this.props.name
        }
        this.props.callbackFunctions(data);
    };

    render() {
        return(
            <div className={'ClicableAvaliationStars'}>
                <div  onClick={() => this.chooseStar(1)}>{this.state.avaliation >= 1 ? <img src={solidStar} alt={'empty star'}/> : <img src={regularStar} alt={'empty star'}/>}</div>
                <div  onClick={() => this.chooseStar(2)}>{this.state.avaliation >= 2 ? <img src={solidStar} alt={'empty star'}/> : <img src={regularStar} alt={'empty star'}/>}</div>
                <div  onClick={() => this.chooseStar(3)}>{this.state.avaliation >= 3 ? <img src={solidStar} alt={'empty star'}/> : <img src={regularStar} alt={'empty star'}/>}</div>
                <div  onClick={() => this.chooseStar(4)}>{this.state.avaliation >= 4 ? <img src={solidStar} alt={'empty star'}/> : <img src={regularStar} alt={'empty star'}/>}</div>
                <div  onClick={() => this.chooseStar(5)}>{this.state.avaliation >= 5 ? <img src={solidStar} alt={'empty star'}/> : <img src={regularStar} alt={'empty star'}/>}</div>
            </div>
        )
    }
}
