import React from 'react'
import './loadingprogress.scss'
import ProgressBar from 'react-bootstrap/ProgressBar'

export default ({ now, arq, arqtotal }) =>
    <div className="loading-screen-progress">
        <div>
            <h2>carregando{arq && arqtotal? ` ${arq}/${arqtotal}` : null }</h2>
            <ProgressBar now={now} label={`${now}%`} />
        </div>
    </div>
