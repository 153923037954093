import React from "react"
import './blog.scss'

import { Link } from 'react-router-dom'
import { AllPosts } from '../../queries/blog'
import Back from '../../components/backButton/backButton'
import dayjs from 'dayjs'
import Navbar from '../../components/newNavbar/navbar'
import Footer from '../../components/newFooter/footer'
import Loading from '../../components/loading/loading'
import { useQuery } from "@apollo/client";
import Banner from '../../assets/banner-blog-oito.jpg'
import ErrorScreen from '../../components/errorScreen/errorScreen'


export default () => {
    const { loading, data, error } = useQuery(AllPosts)

    if (error) return <ErrorScreen cod={error} />

    return (
        <>
            <Navbar />
            <div className="col-xs-12" style={{ marginLeft: "0", paddingLeft: "0" }}>

                <img src={Banner} alt='Blog da Oito' className="bannerblog" />

                <div className="blog8">
                    <div className="cards row center-xs" style={{ flexWrap: 'wrap', justifyContent: 'center' }}>

                        {loading?
                        <Loading /> :
                        data.allPosts.length > 0 ?
                            data.allPosts.map((post, i) => (
                                <div className='card col-xs-12' style={{ maxWidth: '350px', marginBottom: 20 }} key={i}>
                                    <div className="card__img" style={{ backgroundImage: `url(${post.thumbnail})` }}></div>
                                    <Link to={'/post/' + post.id} className="card_link">
                                        <div className="card__img--hover" style={{ backgroundImage: `url(${post.thumbnail})` }}></div>
                                    </Link>
                                    <div className="card__info">
                                        <span className="card__category">{post.categoria}</span>
                                        <h3 className="card__title">{post.title}</h3>
                                        <span className="card__date">{dayjs(post.updated).format("DD/MM/YYYY")}</span>
                                    </div>
                                </div>
                            )): <p>nenhum post</p>
                        }

                    </div>
                </div>

                <Back />

                <div style={{ height: "60px" }} />
                
                <Footer />
            </div>

        </>
    )
}

