import React from 'react'
import './commentCard.scss'

import negative from '../../assets/icons/oitonegative.svg'
import neutral from '../../assets/icons/oitoneutral.svg'
import positive from '../../assets/icons/oitopositive.svg'
import dayjs from 'dayjs'

export default ({ comment }) => comment &&
    <div className={'commentCard col-xs-12'}>
        {/* <p>{comment}</p>
                <span>{infos}</span> */}
        {/* <div style={{ maxWidth: '400px', border: '1px solid grey', borderRadius: 5, boxSizing: 'border-box', padding: '15px' }}> */}
        <div style={{ display: 'flex', marginBottom: 10 }}>
            <div style={{ flex: '0 0 10%', display: 'flex', alignItems: 'center' }}>
                {comment.rating === "NEGATIVE" ?
                    <img style={{ height: 24 }} src={negative} alt="negative" /> :
                    comment.rating === "NEUTRAL" ?
                        <img style={{ height: 24 }} src={neutral} alt="neutral" /> :
                        comment.rating === "POSITIVE" ?
                            <img style={{ height: 24 }} src={positive} alt="positive" /> : null}
            </div>
            <div style={{ flex: '0 0 90%', textAlign: 'left', display: 'flex', alignItems: 'center' }}><h4 style={{ margin: 0 }}>{comment.propose.anuncio.skill.name}</h4></div>
        </div>
        <div className="content" style={{ textAlign: 'left', maxWidth: 'max-content' }}><p style={{ margin: '0 0 15px 0' }}>{comment.description}</p></div>
        {comment.reply ? <div className="replica"><p>- {comment.reply}</p></div> : null}
        <div className="footer" style={{ textAlign: 'right', margin: 0 }}><p style={{ margin: 0 }}>{dayjs(comment.date).format("DD/MM/YYYY")}</p></div>
        {/* </div> */}
    </div>
