import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import axios from 'axios'
import Input from '../components/newInput/input'
import Button from '../components/newButton/button'
import Navbar from '../components/newNavbar/navbar'
import '../style/pages/phoneverify.scss'
import Loading from '../components/loading/loading'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { tokensms } from '../queries/endpoint'
import ErrorBroundray from '../components/errorBoundary/errorBoundary'
import { Report, Notify } from 'notiflix'
import { UpdateVerifyPhone } from '../queries/player'
import { useMutation } from '@apollo/client'

export default () => {
    const cache = JSON.parse(sessionStorage.getItem('OITO'))
    const LastURL = JSON.parse(sessionStorage.getItem('LastURL'))
    const [updateConfirm, { loading: loadingconfirm }] = useMutation(UpdateVerifyPhone)
    const [user, setUser] = useState()
    const [phoneOk, setPhoneOk] = useState(false)
    const [phone, setPhone] = useState()
    const [end, setEnd] = useState(false)
    const [loading, setLoading] = useState(false)
    const [idConfirm, setIdConfirm] = useState()

    useEffect(() => {
        if (cache) {
            setUser(cache.user)
            if (cache.user.phone && !phoneOk) {
                if (cache.user.phone.replace("+55", "").replace(/\D/g, "").length === 11)
                    setPhoneOk(true)
                setPhone(cache.user.phone)
            }
            if (cache.user.phoneverif) window.location.href = '/perfil'

        } else {
            Report.Failure('Você não está logado!', 'Entre ou cadastre-se. É gratuito!', 'Fechar', () => window.location.href = '/login')
        }
    }, [])// eslint-disable-line

    useEffect(() => {
        if (LastURL) {

            if (LastURL.path !== window.location.pathname) {
                const listHistory = {
                    count: parseInt(LastURL.count) - 1,
                    path: window.location.pathname
                }

                sessionStorage.setItem('LastURL', JSON.stringify(listHistory))

            }
        }
    }, [])// eslint-disable-line

    const verify = e => {
        e.preventDefault()

        setLoading(true)

        const number = phone.replace("+55", "").replace(/\D/g, "")

        const verication = {
            numero_destino: number,
            nome_produto: "Oito App",
            tamanho: "6",
            tts: false
        }

        axios.post('https://api2.totalvoice.com.br/verificacao', verication, {
            headers: {
                "Content-Type": "application/json",
                "Access-Token": tokensms
            }
        }).then(
            res => {
                // console.log(res)
                setIdConfirm(res.data.dados.id)
                setEnd(true)
                setLoading(false)
            }
        ).catch(
            err => {
                console.log(err)
                return Report.Failure(
                    "Erro ao verificar o telefone.",
                    "Aconteceu alguma coisa. Tente novamente mais tarde.",
                    "Voltar"
                );
            }
        )
    }

    const confirm = e => {
        e.preventDefault()
        console.log("confirmando verificacao")

        const pin = e.target.elements.pin.value.replace(/\D/g, "")

        if (pin.length > 5) {
            setLoading(true)

            axios.get(`https://api.totalvoice.com.br/verificacao/?id=${idConfirm}&pin=${pin}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Token": tokensms
                }
            }).then(
                res => {

                    if (res.status === 200) {
                        updateConfirm({
                            variables: {
                                phone
                            }
                        }).then(
                            res => {
                                // console.log(res)

                                const userAuth = {
                                    token: cache.token,
                                    user: res.data.updateUser
                                }

                                sessionStorage.setItem('OITO', JSON.stringify(userAuth))

                                setLoading(false)
                                return Report.Success(
                                    "Telefone verificado com sucesso!",
                                    "",
                                    "Fechar",
                                    () => window.location.href = '/perfil'
                                )
                            }
                        ).catch(
                            err => {
                                console.log(err)
                                setLoading(false)
                                return Report.Failure(
                                    "Erro ao verificar o telefone.",
                                    "Aconteceu alguma coisa. Tente novamente mais tarde.",
                                    "Voltar"
                                )
                            }
                        )
                    }
                }
            ).catch(
                err => {
                    console.log(err)
                    setLoading(false)
                    return Report.Failure(
                        "Erro ao verificar o telefone.",
                        "Aconteceu alguma coisa. Tente novamente mais tarde.",
                        "Voltar"
                    )
                }
            )
        } else {
            return Notify.Failure("Este código não é válido.")
        }

    }

    const FormPhone = () => {
        return <form id="myForm" onSubmit={(e) => verify(e)}>
            <Input name="phone" label="Telefone celular" placeholder="Ex.: (xx) xxxxx-xxxx" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} mask="(99) 99999-9999" required />
            <OverlayTrigger
                placement='right'
                overlay={<Tooltip id="pqphone">A Oito necessita de um número de telefone válido para facilitar o contato entre clientes e prestadores de serviço. Simbolos serão inseridos automaticamente. Certifique-se de que o número esteja correto e no formato solicitado.</Tooltip>} >
                <i className="fas fa-info"></i>
            </OverlayTrigger><br />
            <Button type="submit" className={phoneOk ? null : 'disable'} name={'Verificar'} />
        </form>
    }

    const FormConfirm = () => {
        return <form id="myForm2" onSubmit={(e) => confirm(e)}>
            <Input name="pin" label="Código de confirmação" placeholder="Ex.: 123456" required={true} mask="999999" />
            <OverlayTrigger
                placement='right'
                overlay={<Tooltip id="pqpin">Você irá receber uma mensagem de SMS com um código. Digite-o aqui e clique em enviar. Caso não receba, aguarde alguns instantes, verifique se o número digitado está correto, se o anti-spam de SMS está destivado e se seu aparelho tem sinal.</Tooltip>} >
                <i className="fas fa-info"></i>
            </OverlayTrigger><br />
            <Button type="submit" name={'Enviar'} />
        </form>
    }

    if (loading || loadingconfirm) return <Loading />

    return (
        <div className="content">
            <Helmet title='Oito - Verificar o celular'>
                <meta name="description" content="Complete seu cadastro em Oito.app com dados pessoais e habilidades e encontre clientes que buscam por seus serviços." />
                <meta name="keywords" content="Cadastro, Oportunidades, Serviços" />
                <meta name="robots" content="index, follow" />
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="Portuguese" />
                <meta name="revisit-after" content="1 days" />
            </Helmet>

            <ErrorBroundray><Navbar /></ErrorBroundray>

            <div id="phoneferify">
                <div className="form-register-box container">
                    <div className="form-title col-12">
                        <h1>Oi, <span>{user && user.name.split(' ')[0]}</span>. Agora vamos verificar seu número.</h1>
                        <p>É necessário que esteja com seu celular em mãos e com sinal. Para verificar seu número iremos enviar uma mensagem SMS, sem nenhum custo.</p>
                    </div>

                    <div className="form-container col-12 align-items-center justify-content-center">
                        <div className="col-10 col-sm-6 col-md-4 input-phoneverify">

                            {!end ? <FormPhone />
                                : <FormConfirm />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
