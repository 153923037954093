import React from "react"
import './textInput.scss'

export default class TextInput extends React.Component {
    render() {
        const {
            placeholder,
            name,
            rows,
            onInput,
            onFocus,
            onBlur,
            onKeyPress,
            onKeyUp,
            onKeyDown,
            onChange,
            _onFocus,
            _onBlur,
            required
        } = this.props;

        return(
            <div className="textarea-container">
                <textarea id={name}
                          name={name}
                          className="input"
                          rows={rows}
                          onInput={onInput}
                          onFocus={onFocus || _onFocus}
                          onBlur={onBlur || _onBlur}
                          onKeyPress={onKeyPress}
                          onKeyUp={onKeyUp}
                          onKeyDown={onKeyDown || null}
                          onChange={onChange || null}
                          required={required || true}/>
                <label className="label" htmlFor={name}>{placeholder}</label>
            </div>
        )
    }
}
