import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import Button from "../newButton/button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import userLogo from "../../assets/user_logo.svg";
import "./navbar.scss";
import logoPurple from "../../assets/Logo-Oito-1.png";
import logoWhite from "../../assets/oito-white.png";
import dayjs from "dayjs";
import {
  ReadNotification,
  NotificationsResume,
} from "../../queries/notification";
import { useQuery, useMutation } from "@apollo/client";

const Navbar = (props) => {
  const { logoContrastPurple } = props;
  const cache = JSON.parse(sessionStorage.getItem("OITO"));
  const [showStatus, setShowStatus] = useState(0);
  const [dropdownAction, setDropdownAction] = useState(false);
  const [contrastNav, setContrastNav] = useState(false);
  const [hasSlided, setHasSlided] = useState(false);
  const [player, setPlayer] = useState();
  const [dropdownProfileActive, setDropdownProfileActive] = useState(false);
  const [dropdownNotificationActive, setDropdownNotificationActive] = useState(
    false
  );
  const [ADM, setADM] = useState();
  const [newNotifications, setNewNotifications] = useState();
  const [notitificationList, setNotificationList] = useState();
  const { data, refetch } = useQuery(NotificationsResume, {
    variables: {
      id: cache && cache.user.id,
    },
  });
  const [readingNotification] = useMutation(ReadNotification);

  useEffect(() => {
    if (cache) {
      setPlayer(cache.user);
      setADM(cache.user.role === "USER" ? false : true);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (data) {
      setNotificationList(data.allNotifications.slice(0, 3));
      setNewNotifications(data.allNotificationsMeta.count);
    }
  }, [data]);

  useEffect(() => {
    getRouteToShowItem();
    if (logoContrastPurple) {
      window.addEventListener("scroll", changeNavbarBgContrast);
      setContrastNav(true);
    } else {
      window.addEventListener("scroll", changeNavbarBg);
    }
  }, [logoContrastPurple]);

  const clickNotification = (id, link) => {
    if (id) {
      readingNotification({
        variables: {
          id: id
        }
      }).then(() => {
        // console.log(res)
        setDropdownProfileActive(!dropdownNotificationActive);
        refetch()
        window.location.href = link;
      });
    }
  };

  const changeNavbarBgContrast = () => {
    let scrollPosition = window.scrollY;

    if (scrollPosition > 5) {
      setHasSlided(true);
      setContrastNav(false);
    }

    if (scrollPosition < 5) {
      setHasSlided(false);
      setContrastNav(true);
    }
  };

  const changeNavbarBg = () => {
    let scrollPosition = window.scrollY;

    if (scrollPosition > 5) {
      setHasSlided(true);
    }

    if (scrollPosition < 5) {
      setHasSlided(false);
    }
  };

  const getRouteToShowItem = () => {
    let route = window.location.pathname;
    switch (route) {
      case "/":
        setShowStatus(0);
        break;
      case "/quemSomos":
        setShowStatus(0);
        break;
      case "/termosUso":
        setShowStatus(0);
        break;
      case "/faq":
        setShowStatus(0);
        break;
      case "/login":
        setShowStatus(1);
        break;
      case "/cadastro":
        setShowStatus(1);
        break;
      default:
        setShowStatus(0);
    }
  };

  const openNotificationDropdown = (e) => {
    e.preventDefault();
    setDropdownNotificationActive(!dropdownNotificationActive);

    window.setTimeout(() => {
      if (dropdownNotificationActive) {
        setDropdownProfileActive(!dropdownNotificationActive);
      }
    }, 2000);
  };

  const openProfileDropdown = () => {
    setDropdownProfileActive(!dropdownProfileActive);

    window.setTimeout(() => {
      if (dropdownProfileActive) {
        setDropdownProfileActive(!dropdownProfileActive);
      }
    }, 5000);
  };

  const logout = (e) => {
    e.preventDefault();
    sessionStorage.clear();
    window.location.href = "/";
  };

  return (
    <nav
      className="navbar"
      style={
        hasSlided
          ? { backgroundColor: "white", boxShadow: "0 2px 5px #dadada" }
          : null
      }
    >
      <div>
        <div
          onClick={() => setDropdownAction(!dropdownAction)}
          style={contrastNav ? { color: "white" } : { color: "#65519d" }}
        >
          <FontAwesomeIcon icon={faBars} size={"2x"} />
        </div>
        <Link to="/">
          {contrastNav ? (
            <img alt={"Logo Oito Branco"} src={logoWhite} />
          ) : (
            <img alt={"Logo Oito Roxo"} src={logoPurple} />
          )}
        </Link>
        <ul className={contrastNav ? "whiteColor" : "orangeColor"}>
          {showStatus === 1 && (
            <NavLink exact to="/" activeClassName="selected">
              <li>Home</li>
            </NavLink>
          )}
          {showStatus === 0 && (
            <NavLink exact to="/anuncios" activeClassName="selected">
              <li>Encontrar um profissional</li>
            </NavLink>
          )}
          {!player && showStatus === 0 && (
            <NavLink exact to="/cadastro" activeClassName="selected">
              <li>Anunciar</li>
            </NavLink>
          )}
          {showStatus === 0 && (
            <NavLink exact to="/blog" activeClassName="selected">
              <li>Blog</li>
            </NavLink>
          )}
        </ul>
        {!player && (
          <div className="callact">
            <Link to={"/login"}>
              <Button name={"entrar"} />
            </Link>
          </div>
        )}
      </div>

      <div
        className={dropdownAction ? "dropdown active" : "dropdown"}
        onClick={() => setDropdownAction(!dropdownAction)}
      >
        <ul className="dropdown-links">
          {player ? (
            <div className="row middle-xs">
              <div className="ava">
                <img
                  src={player.photo ? player.photo : userLogo}
                  alt={player.name}
                />
              </div>
              <div className="name">
                <span>Oi, {player.name.split(" ")[0]}</span>
                <br />
                <NavLink exact to="/perfil" activeClassName="active-route">
                  Minha conta{" "}
                  <FontAwesomeIcon icon={faChevronRight} size="1x" />
                </NavLink>
              </div>
            </div>
          ) : (
            <>
              <NavLink exact to="/login" activeClassName="active-route">
                <li className="login">Entrar</li>
              </NavLink>
              <NavLink exact to="/cadastro" activeClassName="active-route">
                <li className="login">Cadastrar</li>
              </NavLink>
            </>
          )}

          <NavLink exact to="/" activeClassName="active-route">
            <li>Início</li>
          </NavLink>
          {ADM && (
            <NavLink exact to="/painel" activeClassName="active-route">
              <li>Painel</li>
            </NavLink>
          )}
          {player && (
            <NavLink
              exact
              to="/perfil/notifications"
              activeClassName="active-route"
            >
              <li>Notificações</li>
            </NavLink>
          )}
          <NavLink exact to="/anuncios" activeClassName="active-route">
            <li>Encontrar um profissional</li>
          </NavLink>
          <NavLink exact to="/blog" activeClassName="active-route">
            <li>Blog</li>
          </NavLink>
          {!player && (
            <NavLink exact to="/cadastro" activeClassName="active-route">
              <li>Anunciar</li>
            </NavLink>
          )}
          <NavLink exact to="/faq" activeClassName="active-route">
            <li>Perguntas frequentes</li>
          </NavLink>
          <NavLink exact to="/faleConosco" activeClassName="active-route">
            <li>Fale Conosco</li>
          </NavLink>
          {player && (
            <button onClick={(e) => logout(e)}>
              <li>Sair</li>
            </button>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <a
              href="https://www.instagram.com/oito.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <svg
                  style={{ width: "35px", height: "35px" }}
                  viewBox="0 0 30 30"
                >
                  <path
                    fill="#707070"
                    d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
                  />
                </svg>
              </div>
            </a>
            <a
              href="https://www.facebook.com/oitojobs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <svg
                  style={{ width: "35px", height: "35px" }}
                  viewBox="0 0 30 30"
                >
                  <path
                    fill="#707070"
                    d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z"
                  />
                </svg>
              </div>
            </a>
          </div>
        </ul>
      </div>

      {dropdownNotificationActive && (
        <div id={"NotificationDropdown"}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ flex: "0 0 33.33%" }}></div>
            <div style={{ flex: "0 0 33.33%", textAlign: "right" }}>
              <h4>Notificações</h4>
            </div>
            <div
              style={{ flex: "0 0 33.33%", textAlign: "center" }}
              onClick={(e) => openNotificationDropdown(e)}
            >
              <svg
                style={{ width: "24px", height: "24px" }}
                viewBox="0 0 24 24"
              >
                <path
                  fill="#5f5f5f"
                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                />
              </svg>
            </div>
          </div>
          <div className={"content"}>
            <small>Últimas notificações</small>
            <br />
            {data && notitificationList && notitificationList.length > 0 ? (
              notitificationList.map((not, i) => {
                const value = not.createdAt - 234798274;
                return (
                  <p
                    key={i}
                    style={
                      not.status
                        ? null
                        : { backgroundColor: "#f4f4f4", color: "#6550A1" }
                    }
                    onClick={() => clickNotification(not.id, not.link)}
                  >
                    {not.description} Data:
                    {dayjs(value).format("DD/MM/YYYY")}
                  </p>
                );
              })
            ) : (
              <span>Não há notificações</span>
            )}
          </div>
          <div className={"btnNot"}>
            <Link
              to={"/perfil/notifications"}
              onClick={() =>
                setDropdownProfileActive(!dropdownNotificationActive)
              }
            >
              Ver mais notificações
            </Link>
          </div>
        </div>
      )}

      {dropdownProfileActive && (
        <div id={"DropdownProfile"}>
          <ul>
            <NavLink to={"/perfil"} activeClassName="activedDropProfile">
              <li>Minha conta</li>
            </NavLink>
            {ADM && (
              <NavLink to="/painel" activeClassName="activedDropProfile">
                <li>Painel</li>
              </NavLink>
            )}
            <NavLink
              to={"/perfil/notifications"}
              onClick={(e) => openNotificationDropdown(e)}
              activeClassName="activedDropProfile"
            >
              <li>
                Notificações{" "}
                {newNotifications > 0 && (
                  <span className="badge list">{newNotifications}</span>
                )}
              </li>
            </NavLink>
            <Link to={"/"} onClick={(e) => logout(e)}>
              <li>Sair</li>
            </Link>
          </ul>
        </div>
      )}

      {player ? (
        <div>
          <div className={"userInfo"}>
            <div onClick={() => openProfileDropdown()} className={"name"}>
              {player.name.split(" ")[0]}
            </div>
            <div onClick={() => openProfileDropdown()}>
              <img
                src={player.photo ? player.photo : userLogo}
                alt={player.name}
              />
              {newNotifications > 0 && (
                <span className="badge">{newNotifications}</span>
              )}
            </div>
          </div>
        </div>
      ) : (
        showStatus === 0 && (
          <div>
            <Link to={"/cadastro"}>
              <Button
                style={
                  contrastNav
                    ? { background: "none", color: "white", boxShadow: "none" }
                    : {
                        background: "none",
                        color: "#ff8000",
                        boxShadow: "none",
                      }
                }
                name={"cadastrar"}
              />
            </Link>
            <Link to={"/login"}>
              <Button name={"entrar"} />
            </Link>
          </div>
        )
      )}
    </nav>
  );
};

export default Navbar;
