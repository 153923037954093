import React, { useState } from "react";
import "./input.scss";

// import info from '../../assets/info-circle-solid.svg'
import Tooltip from "../tooltip/tooltip";
import EyeOn from "../../assets/eye-outline.svg";
import EyeOff from "../../assets/eye-off-outline.svg";

import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import InputMask from "react-input-mask";
import Miniloading from "../miniloading/miniloading"

const Input = (props) => {
  const {
    tooltipText,
    // disabled,
    placeholder,
    name,
    type,
    // size,
    defaultValue,
    // inputStyle,
    // inputColor,
    autofocus,
    onInput,
    onFocus,
    onBlur,
    onKeyPress,
    onKeyUp,
    onKeyDown,
    onChange,
    maxlength,
    minlength,
    value,
    _onFocus,
    _onBlur,
    required,
    isPassword,
    money,
    mask,
    loading
  } = props;

  const [passwordType, setPasswordType] = useState(isPassword ? "password" : "text")

  const passwordChange = () => {
    if (isPassword) {
      // document.ele
      if (passwordType === "password") {
        setPasswordType("text");
      }

      if (passwordType === "text") {
        setPasswordType("password");
      }
    }
  }

  const defaultMaskOptions = {
    prefix: "R$ ",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ".",
    allowDecimal: true,
    decimalSymbol: ",",
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
    requireDecimal: true,
  }

  const decideInput = () => {

    if (money) {
      return <MaskedInput mask={currencyMask} {...props} />
    }

    if (mask) {
      return <InputMask
        mask={mask}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onInput={onInput}
        onFocus={onFocus || _onFocus}
        onBlur={onBlur || _onBlur}
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
      >
        {(inputProps) => (
          <input
            id={name}
            name={name}
            className="input"
            type={isPassword ? passwordType : type}
            {...inputProps}
            maxLength={maxlength}
            minLength={minlength}
            autoFocus={autofocus}
            required={required || true}
          />
        )}
      </InputMask>
    }

    return <input
      id={name}
      name={name}
      className="input"
      type={isPassword ? passwordType : type}
      defaultValue={defaultValue}
      value={value}
      maxLength={maxlength}
      minLength={minlength}
      autoFocus={autofocus}
      onInput={onInput}
      onFocus={onFocus || _onFocus}
      onBlur={onBlur || _onBlur}
      onKeyPress={onKeyPress}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      onChange={onChange}
      required={required || true}
    />
  }

  const currencyMask = createNumberMask(defaultMaskOptions);

  return (
    <div className="input-container">

      {decideInput()}

      <label className="label" htmlFor={name}>
        {placeholder}
      </label>

      {tooltipText && (
        <div text={tooltipText} className={"info"}>
          <Tooltip />
        </div>
      )}

      {isPassword && (
        <img
          onClick={passwordChange}
          src={passwordType === "password" ? EyeOn : EyeOff}
          className={"showPassword"}
          alt=""
        />
      )}

      {loading && <Miniloading />}
    </div>
  )
}

export default Input