import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import axios from 'axios'
import Input from '../../../components/newInput/input'
// import {FileInput} from '../../../components/fileInput/fileInput'
import Loading from '../../../components/loading/loading'
import Button from '../../../components/newButton/button'
import { UpdateAddress, UpdateDescription, UpdatePhone, VerifyEmail, UpdateEmail, PasswordChange, UpdatePhoto } from '../../../queries/player'
import Modal from '../../../components/Modal/modal'
import { endpontStorage } from "../../../queries/endpoint"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import PasswordMeter from '../../../components/PassworMeter/passwordmeter'
import ErrorM from '../../../components/ErrorMessange/errormenssage'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/lib/ReactCrop.scss'
import './dataUser.scss'
import LoadingProgress from '../../../components/loadingprogress/loadingprogress'
import { Report, Notify } from "notiflix"
import { useMutation, useLazyQuery } from "@apollo/client"

export default () => {
    const cache = JSON.parse(sessionStorage.getItem('OITO'))
    const [updatePhone, { loading: loadingUpdatePhone }] = useMutation(UpdatePhone)
    const [updateDescription, { loading: loadingUpdateDescription }] = useMutation(UpdateDescription)
    const [updateAddress, { loading: loadingUpdateAddress }] = useMutation(UpdateAddress)
    const [updateEmail, { loading: loadingUpdateEmail }] = useMutation(UpdateEmail)
    const [passwordChange, { loading: loadingPasswordChange }] = useMutation(PasswordChange)
    const [updatePhoto, { loading: loadingUpdatePhoto }] = useMutation(UpdatePhoto)
    const [findEmail, { data, loading: spinnerEmail }] = useLazyQuery(VerifyEmail)
    const [user, setUser] = useState()
    const [show, setShow] = useState(false)
    const [newPhone, setNewPhone] = useState()
    const [newDescription, setNewDescription] = useState()
    const [zipErr, setZipErr] = useState()
    const [findAddress, setFindAddress] = useState(false)
    const [zipcode, setZipcode] = useState()
    const [street, setStreet] = useState()
    const [neighborhood, setNeighborhood] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()
    const [number, setNumber] = useState()
    const [complement, setComplement] = useState()
    const [addressOk, setAddressOk] = useState(false)
    const [password, setPassword] = useState()
    const [passwordOk, setPasswordOk] = useState(false)
    const [passwordErr, setPasswordErr] = useState()
    const [email, setEmail] = useState()
    const [emailOk, setEmailOk] = useState(false)
    const [emailErr, setEmailErr] = useState()
    const [showModal, setShowModal] = useState(false)
    const [src, setSrc] = useState()
    const [crop, setCrop] = useState({
        unit: "%",
        width: 100,
        aspect: 1 / 1
    })
    const [imageRef, setImageRef] = useState()
    const [blob, setBlob] = useState()
    const [now, setNow] = useState()
    const [loadingprogress, setloadingprogress] = useState(false)

    useEffect(() => {
        if (cache) {

            if (cache.user.address && cache.user.birthday && cache.user.phoneverif) {

                setUser(cache.user)

                setEmail(cache.user.email)

                setNumber(cache.user.address.number)
                setComplement(cache.user.address.complement)
                setZipcode(cache.user.address.zipcode)
                setStreet(cache.user.address.street)
                setNeighborhood(cache.user.address.neighborhood)
                setCity(cache.user.address.city)
                setState(cache.user.address.state)

            } else if (cache.user.document === null || !cache.user.birthday === null || cache.user.address === null) {
                return Report.Failure('Você não completou seu cadastro!',
                    'Primeiro, complete seu cadastro.',
                    'Fechar',
                    () => window.location.href = '/perfil/completarcadastro')
            } else {
                return Report.Failure('Você não verificou seu celular!',
                    'Para criar um anúncio é necessario finalizar o cadastro.',
                    'Fechar',
                    () => window.location.href = '/verificartelefone')
            }
        } else {
            return Report.Failure('Você não está logado!',
                'Entre ou cadastre-se. É gratuito!',
                'Fechar',
                () => window.location.href = '/login')
        }
    }, [])// eslint-disable-line

    useEffect(() => {
        if (data && data.allUsers.length > 0) {
            if (data.allUsers[0].email !== cache.user.email) {
                setEmailErr(<ErrorM color="danger" content='Este e-mail já está cadastrado.' />)
                setEmailOk(false)
            } else {
                setEmailOk(false)
                setEmailErr()
            }
        } else if (data && data.allUsers.length === 0) {
            if (email !== cache.user.email) {
                setEmailErr(<ErrorM color="danger" content='Este e-mail já está cadastrado.' />)
                setEmailOk(false)
            }
            setEmailOk(true)
            setEmailErr()
        }
    }, [data])// eslint-disable-line

    const getZipcode = async e => {
        setZipErr()

        const input = e.target.value.replace(/\D/g, "");

        if (input.length < 8) return false;

        try {
            setFindAddress(true)

            const address = await axios.get(`https://viacep.com.br/ws/${input}/json/`, {})

            if (address.data && !address.data.erro) {
                setZipcode(address.data.cep)
                setStreet(address.data.logradouro)
                setNeighborhood(address.data.bairro)
                setCity(address.data.localidade)
                setState(address.data.uf)
                setNumber("")
                setComplement("")
                setFindAddress(false)
            } else {
                setFindAddress(false)
                setZipErr(<ErrorM content='CEP inválido.' />)
            }
        } catch (e) {
            console.log("# zipcode error", e)
            if (zipcode.length > 8)
                setFindAddress(false)
            setZipErr(<ErrorM content='CEP inválido.' />)
        }
    }; //_getZipcode

    const blurEmailPass = () => {

        setEmailErr()

        const emailValid = email.trim(" ")

        if (emailValid !== undefined && emailValid.length > 5 && emailValid !== user.email) {
            if (emailValid.includes('@') && emailValid.split("@")[1].includes('.')) {

                setEmail(emailValid)

                findEmail({
                    variables: {
                        email: emailValid
                    }
                })


            } else {
                setEmailErr(<ErrorM color="danger" content='E-mail inválido. Por favor, digite novamente. Ex.: jose@email.com' />)
                setEmailOk(false)
            }
        } else {
            setEmailOk(false)
            setEmailErr()
        }
    }

    const verifyValues = (e) => {

        if (e.target.name === "phone") {

            if (newPhone === "(__) _____-____" || newPhone.match("_")) {
                console.log("nao é valido")
                Notify.Failure("É obrigatório ter um número de celular válido.")
            }
        }


        if (number === "") {
            Notify.Failure("É obrigatório preencher o número do endereço.")
            setAddressOk(false)
        }

        if (street &&
            zipcode &&
            number &&
            neighborhood &&
            city &&
            state) {
            setAddressOk(true)
        }

    }

    const saveAddress = e => {
        e.preventDefault()

        updateAddress({
            variables: {
                street: street,
                zipcode: zipcode,
                number: number,
                complement: complement,
                neighborhood: neighborhood,
                city: city,
                state: state,
            }
        }).then(
            res => {
                if (res.error) Notify.Failure("Erro ao atualizar o endereço. Tente mais tarde.")
                if (res.data) {
                    const cache = JSON.parse(sessionStorage.getItem('OITO'))

                    console.log("atualizando o storage")

                    const userAuth = {
                        token: cache.token,
                        user: res.data.updateUser
                    }

                    sessionStorage.setItem('OITO', JSON.stringify(userAuth))

                    Notify.Success("Endereço atualizado com sucesso.")

                }
            }
        )
    } //_nextStep

    const saveDescription = e => {
        e.preventDefault()

        updateDescription({
            variables: {
                description: newDescription,
            }
        }).then(
            res => {
                if (res.error) Notify.Failure("Erro ao atualizar a descrição. Tente novamente mais tarde.")
                // console.log(res.data.updateUser)
                if (res.data.updateUser) {
                    
                    console.log("atualizando o storage")
                    const userAuth = {
                        token: cache.token,
                        user: res.data.updateUser
                    }

                    sessionStorage.setItem('OITO', JSON.stringify(userAuth))
                }

            }
        )
    } //_nextStep

    const savePhone = e => {
        e.preventDefault()

        updatePhone({
            variables: {
                phone: newPhone,
                phoneverif: false,
            }
        }).then(
            res => {
                if (res.error) Notify.Failure("Erro ao atualizar o telefone. Tente novamente mais tarde.")
                
                if (res.data) {
                    
                    console.log("atualizando o storage")
                    const userAuth = {
                        token: cache.token,
                        user: res.data.updateUser
                    }

                    sessionStorage.setItem('OITO', JSON.stringify(userAuth))
                    window.location.href = `/verificartelefone`
                }
            }
        )
    } //_nextStep

    const blurPassword = () => {
        setPasswordErr()
        setPasswordOk(false)

        var regex = /^(?=(?:.*?[0-9]){2})(?=(?:.*?[a-zA-Z]){2})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/;

        if (password) {
            if (password.length >= 8) {
                if (regex.exec(password)) {
                    setPasswordErr()
                    setPasswordOk(true)
                } else {
                    setPasswordErr(<ErrorM color="danger" content='A senha deve ter, no mínimo, 2 números e 2 letras.' />)
                    setPasswordOk(false)
                }
            } else {
                setPasswordErr(<ErrorM color="danger" content='A senha deve ter, no mínimo, 8 caracteres.' />)
                setPasswordOk(false)
            }
        }
    }

    const newPassword = () => {
        // this.setState({ loadingP: true })

        passwordChange({
            variables: {
                password
            }
        }).then(
            res => {

                setShow(false)

                if (res.data && res.data.updateUserPassword) {
                    return Report.Success(
                        "Senha alterada com sucesso.",
                        'Guarde bem sua nova senha, você irá usa-la para se conectar de novo.',
                        "Fechar"
                    )
                }

                return Report.Failure(
                    "Erro ao salvar a senha.",
                    'Aconteceu algo de errado ao salvar sua senha. Tente novamente mais tarde.',
                    "Fechar"
                )

            }
        )
    }

    const newEmail = (e) => {
        e.preventDefault()

        updateEmail({
            variables: {
                email
            }
        }).then(res => {
            if (res.error) Notify.Failure("Erro ao atualizar o email. Tente novamente mais tarde")

            if (res.data) {

                console.log("atualizando o storage")

                const userAuth = {
                    token: cache.token,
                    user: res.data.updateUser
                }

                sessionStorage.setItem('OITO', JSON.stringify(userAuth))

                setShow(false)
                Notify.Success("Email atualizado com sucesso.")
            }
        })
    }

    const onSelectFile = e => {

        if (e.target.files && e.target.files.length > 0) {

            if (e.target.files[0] > 5242880) {
                return Report.Failure(
                    'Imagem muito grande.',
                    'Tente diminuir o tamanho e/ou a qualidade ou escolha outra foto.',
                    "Fechar",
                    () => window.location.reload()
                )
            }

            if (e.target.files[0].type.split("/")[0] === "image") {
                switch (e.target.files[0].type.split("image/")[1]) {
                    case "jpg":
                    case "jpeg":
                    case "png":
                    case "giff":
                    case "bmp":
                    case "svg":
                        const reader = new FileReader();

                        reader.addEventListener("load", () =>
                            setSrc(reader.result)
                        );

                        reader.readAsDataURL(e.target.files[0]);
                        setShowModal(true)
                        break;
                    default:
                        return Report.Failure(
                            'Formato inválido',
                            'Selecione uma imagem válida.',
                            "Fechar",
                            () => window.location.reload()
                        )
                }
            } else {
                return Report.Failure(
                    'Formato inválido',
                    'Selecione uma imagem válida.',
                    "Fechar",
                    () => window.location.reload()
                )
            }
        }
    };

    const onImageLoaded = image => {
        setImageRef(image);
    };

    const onCropComplete = crop => {
        makeClientCrop(crop)
    };

    const onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        setCrop(crop)
    };

    const makeClientCrop = async (crop) => {
        if (imageRef && crop.width && crop.height) {
            await getCroppedImg(
                imageRef,
                crop,
                "newFile.jpeg"
            )
        }
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }

                blob.name = fileName;

                let fileUrl

                window.URL.revokeObjectURL(fileUrl)

                fileUrl = window.URL.createObjectURL(blob)

                resolve(fileUrl)

                setBlob(blob)
            }, "image/jpeg");
        });
    }

    const saveImg = async () => {

        if (!blob) {
            onCropComplete()
        } else {

            setloadingprogress(true)
            setShowModal(false)

            const file = blob
            const fileName = blob.name;   // Like : abc.jpeg
            const path_splitted = fileName.split('.')
            const extension = path_splitted.pop()
            const newName = cache.user.id + '.' + extension

            const myNewFile = new File([file], newName, { type: file.type })

            let data = new FormData();
            data.append('data', myNewFile)

            await axios.post(endpontStorage + 'upload', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${cache.token}`
                },
                onUploadProgress: ProgressEvent => {
                    setNow(parseInt(ProgressEvent.loaded / ProgressEvent.total * 100))
                }
            }).then(res => {

                updatePhoto({
                    variables: {
                        photo: res.data.data.url
                    }
                }).then(async response => {
                    //atualiza session storage

                    console.log("atualizando o storage")

                    const userAuth = {
                        token: cache.token,
                        user: response.data.updateUser
                    }

                    await sessionStorage.setItem('OITO', JSON.stringify(userAuth))

                    if (cache.user.photo) {
                        const file = cache.user.photo.split("https://oitofiles.blob.core.windows.net/")[1]

                        const fileId = file.split("/")[1]

                        await axios.delete(endpontStorage + `${fileId}`, {
                            headers: {
                                'Authorization': `Bearer ${cache.token}`
                            }
                        })
                            .then(() => console.log("foto antiga removida"))
                    }

                    setloadingprogress(false)
                    Report.Success(
                        "Foto atualizada com sucesso.",
                        "",
                        "Fechar",
                        () => window.location.reload()
                    )
                })
            }).catch((err) => {
                console.log(err)
                Report.Failure(
                    "Error ao salvar a foto",
                    'Algo de errado aconteceu. Por favor, tente novamente mais tarde.',
                    "Fechar",
                    () => window.location.reload()
                )
            })
        }
    }

    if (loadingUpdatePhone ||
        loadingUpdateDescription ||
        loadingUpdateAddress ||
        loadingUpdateEmail ||
        loadingPasswordChange ||
        loadingUpdatePhoto) return <Loading />

    return (
        <>

            <Helmet title='Oito - Atualizar perfil'>
                <meta name="title" content="Oito.app | Altere ou complete dados do seu perfil em Oito.app" />
                <meta name="description" content="Complete seu cadastro com informações pessoais e profissionais. Altere dados importantes, caso necessário, e mantenha seu perfil sempre atualizado." />
                <meta name="keywords" content="Dados, Profissionais, Clientes" />
                <meta name="robots" content="index, follow" />
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="Portuguese" />
                <meta name="revisit-after" content="1 days" />
            </Helmet>

            <Modal show={showModal} title={"Recorte sua foto"} onClose={() => setShowModal(!showModal)} >
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ maxWidth: 550 }}>
                            {src && (
                                <ReactCrop
                                    src={src}
                                    crop={crop}
                                    onImageLoaded={onImageLoaded}
                                    onComplete={onCropComplete}
                                    onChange={onCropChange}
                                />
                            )}
                        </div>
                    </div>
                    {blob && <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={() => saveImg()} name='Salvar'
                            type='button' style={{ marginTop: '15px' }} />
                    </div>}
                </div>
            </Modal>

            <Modal show={show} onClose={() => setShow(!show)} title='Alterar e-mail ou senha'>
                <p>Ao alterar e-mail ou senha suas credenciais para entrar na Oito também serão alteradas.</p>
                <p>Então, guarde-as com carinho.</p>

                <form id="newPassForm" onSubmit={(e) => newEmail(e)} style={{ marginTop: '15px' }}>
                    <Input name={'email'} type={'email'} placeholder={'E-mail'} onChange={(e) => setEmail(e.target.value)} value={email} onInput={blurEmailPass} loading={!!spinnerEmail} />
                    {emailErr}
                    {emailOk && <Button type="submit" name="Salvar e-mail" style={{ marginTop: '15px' }} />}
                </form>

                <form onSubmit={(e) => newPassword(e)} style={{ marginTop: '15px' }}>
                    <OverlayTrigger
                        placement='right'
                        overlay={<Tooltip id='password'>
                            Para criar uma senha segura você deve escolher, no mínimo, 8 caracteres, usando letras e números.
                      </Tooltip>} >
                        <Input name={'password'} type={'password'} placeholder={'Senha com mínimo de 8 caracteres.'} onChange={(e) => setPassword(e.target.value)} minlength={8} onInput={blurPassword} />
                    </OverlayTrigger>
                    <PasswordMeter password={password} />
                    {passwordErr}
                    {passwordOk && <Button type="submit" name="Salvar senha" style={{ marginTop: '15px' }} />}
                </form>
            </Modal>

            {loadingprogress && <LoadingProgress now={now} arq={1} arqtotal={1} />}

            <div className="col-xs-12" style={{ maxWidth: '980px' }}>
                <div className="form-register-box row center-xs">
                    <div className="form-title col-xs-12">
                        <h3>Oi, <span>{user && user.name}</span>. Atualize seus dados!</h3>

                    </div>

                    <div className="form-container col-xs-12">
                        <div className="row center-xs bottom-xs">
                            <div className="ButtonEdit col-xs-12">
                                <Button type="button" onClick={() => setShow(!show)} name="Alterar e-mail ou senha" />
                                <label>Trocar foto de perfil<input id="buttonUploadImg" style={{ display: 'none' }} label='Alterar imagem de perfil' type='file' accept='image/*' onChange={onSelectFile} /></label>
                            </div>

                            <form id="myform" className="col-xs-12" style={{ marginTop: '15px' }} onSubmit={(e) => savePhone(e)} >
                                <div className="row center-xs">
                                    <div className="col-xs-12 input-phone">
                                        <Input name="phone" label="Telefone" placeholder="Celular. Ex.: (xx) xxxxx-xxxx" type="text" onChange={(e) => setNewPhone(e.target.value)} onblur={verifyValues} value={newPhone || (user && user.phone)} mask="(99) 99999-9999" />
                                        <OverlayTrigger
                                            placement='right'
                                            overlay={<Tooltip id="pqphone">A Oito necessita de um número de telefone válido para facilitar o contato entre clientes e prestadores de serviço.</Tooltip>} >
                                            <i className="fas fa-info infoIcon"></i>
                                        </OverlayTrigger>
                                        {newPhone && <div className="col-xs-12">
                                            <Button type="submit" name='Salvar telefone' />
                                        </div>}
                                    </div>
                                </div>
                            </form>

                            <form className="col-xs-12" style={{ marginTop: '15px' }} onSubmit={(e) => saveDescription(e)} >
                                <div className="row center-xs">
                                    <div className="info-button col-xs-12">
                                        <Input name="description" label="Descrição" placeholder="Faça uma breve descrição sua." type="text" onChange={(e) => setNewDescription(e.target.value)} value={newDescription || (user && user.description)} maxlength={140} />
                                        <OverlayTrigger
                                            placement='right'
                                            overlay={<Tooltip id="pqdescription">Fale um pouco de você. É aqui que você conquista seus clientes.</Tooltip>} >
                                            <i className="fas fa-info infoIcon"></i>
                                        </OverlayTrigger>
                                    </div>

                                    {newDescription && <Button type="submit" name='Salvar descrição' />}
                                </div>
                            </form>

                            <form className="col-xs-12" style={{ marginTop: '15px' }} onSubmit={(e) => saveAddress(e)}>
                                <div className="address-form row center-xs">
                                    {/* colocar esse titulo numa linha sozinha */}

                                    <div className="col-xs-12">
                                        <h4>Endereço</h4>
                                        {zipErr}
                                    </div>
                                    <div className="col-xs-12 col-sm-4 cep-input">
                                        <Input onInput={(e) => getZipcode(e)}
                                            onChange={(e) => setZipcode(e.target.value)}
                                            mask="99999-999"
                                            name="zipcode" label="CEP" placeholder="CEP. Ex.: 00000-000" type="text" value={zipcode} />
                                        <OverlayTrigger
                                            placement='right'
                                            overlay={<Tooltip id="pqaddress">A Oito exige que você cadastre um endereço válido para manter a integridade da nossa plataforma.</Tooltip>} >
                                            <i className="fas fa-info"></i>
                                        </OverlayTrigger>
                                    </div>

                                    {findAddress && (
                                        <div className="loadingGetAddress">
                                            <i className="fas fa-spinner rotate-spin" />
                                        </div>
                                    )}

                                    <div className="col-xs-12">
                                        <Input onInput={(e) => setStreet(e.target.value)} onChange={(e) => verifyValues(e)}
                                            name="street" value={street} label="Endereço" placeholder="Rua, Avenida ou Logradouro." type="text" />
                                    </div>

                                    <div className="col-xs-12 col-sm-3">
                                        <Input onInput={(e) => setNumber(e.target.value)} onChange={(e) => verifyValues(e)}
                                            name="number" label="Número" placeholder="Número. Ex.: 00" type="text" value={number} />
                                    </div>

                                    <div className="col-xs-12 col-sm-4">
                                        <Input onInput={(e) => setComplement(e.target.value)} onChange={(e) => verifyValues(e)}
                                            name="complement" label="Complemento" placeholder="Complemento. Ex.: Apto 1101" type="text" value={complement} required="false" />
                                    </div>

                                    <div className="col-xs-12 col-sm-5">
                                        <Input onInput={(e) => setNeighborhood(e.target.value)} onChange={(e) => verifyValues(e)}
                                            name="neighborhood" value={neighborhood} label="Bairro" placeholder="Bairro" type="text" />
                                    </div>

                                    <div className="col-xs-12 col-sm-8">
                                        <Input onChange={(e) => verifyValues(e)}
                                            name="city" value={city} label="Cidade" placeholder="Cidade" type="text" />
                                    </div>

                                    <div className="col-xs-12 col-sm-4">
                                        <Input onChange={(e) => verifyValues(e)}
                                            name="state" value={state} label="Estado" placeholder="Estado" type="text" />
                                    </div>

                                    {addressOk && <div className="col-xs-12 register-two-buttons">
                                        <Button type="submit" name="Salvar endereço" />
                                    </div>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
