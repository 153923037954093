import React, { useState, useEffect } from "react"
import './announcement.scss'
import { Link } from 'react-router-dom'
import Navbar from '../../components/newNavbar/navbar'
import Loading from '../../components/loading/loading'
import CardFeaturedPro from '../../components/cardFeaturedPro/cardFeaturedPro'
import IconCtg from '../../components/iconCtg/iconCtg'
import { Categories } from '../../queries/category'
import Input from '../../components/newInput/input'
import Footer from '../../components/newFooter/footer'
import { useQuery } from "@apollo/client";
import ErrorScreen from '../../components/errorScreen/errorScreen'
import axios from "axios"
import ReactPaginate from 'react-paginate'
import 'react-paginate/demo/styles/style.css'
import { endpointRest } from '../../queries/endpoint'
import ErrorBroundray from '../../components/errorBoundary/errorBoundary'

const Announcement = () => {
  const [searchQuery, setSearchQuery] = useState()
  const [usersList, setUsersList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [pageSize] = useState(parseInt(window.innerWidth / 280))
  const { loading, data, error } = useQuery(Categories)

  const getProfissionais = (params) => {

    return new Promise((resolve, reject) => {
      axios.post(endpointRest + "users",
        params
      ).then(
        res => {

          const profissionais = res.data.data

          let totalPages = 1

          if ((res.data.totalFounded % res.data.pageSize) > 0) {
            totalPages = parseInt(res.data.totalFounded / res.data.pageSize) + 1
          } else {
            totalPages = parseInt(res.data.totalFounded / res.data.pageSize)
          }

          return resolve({
            profissionais,
            currentPage: res.data.currentPage,
            pageCount: totalPages,
            loading: false,
            total: res.data.totalFounded
          })
        })
    })
  }

  useEffect(() => {

    if (pageSize > 1) {

      const paramsUsers = {
        filter: {
          status: { "$ne": false },
          type: "PROFISSIONAL"
        },
        pageSize: pageSize.toString(),
        currentPage: currentPage.toString()
      }

      getProfissionais(paramsUsers).then(res => {
        setUsersList(res.profissionais)
        setCurrentPage(res.currentPage)
        setPageCount(res.pageCount)
      })
    }
  }, [currentPage, pageSize])

  const finder = (query) => {

    if (query !== undefined && query.length > 2) {
      const findQuery = encodeURI(query)

      window.location.href = "/anuncios/busca/" + findQuery
    }
  }

  if (error) return <ErrorScreen cod={error} />

  return (
    <div className={'Announcement col-xs-12'}>
      <div className="row center-xs">
        <form className="col-xs-12" style={{ maxWidth: '600px' }} method="post" onSubmit={(e) => {
          e.preventDefault()
          finder(searchQuery)
        }}>
          <Input placeholder={'Pesquisar...'} name={'search'} type="search" onInput={e => setSearchQuery(e.target.value)} />
        </form>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <h1>Categoria</h1>
        </div>
        <div className={'sideSlide col-xs-12 center-xs'}>

          {loading ?
            <Loading /> : data.allCategories.length ?
              data.allCategories.map((p, i) => {
                return <div key={i} className="catdrop">
                  <IconCtg icon={p.file} label={true} name={p.name} />
                  <div className="arrow" />
                  <div className={'filterList'}>
                    <h4>{p.name}</h4>
                    <ol>
                      {p.skills.length > 0 &&
                        p.skills.map((hab, i) => <ErrorBroundray key={i}><li onClick={() => window.location.href = "/anuncios/" + hab.id}>{hab.name}</li></ErrorBroundray>)}
                    </ol>
                  </div>
                </div>
              }) :
              <article className="msg-not-found"><h5>Infelizmente não encontramos nenhum profissional. <Link to="/cadastro">Cadastre-se</Link> para ser o primeiro!</h5></article>}
        
        </div>

        {pageSize > 1 && <div className="destaque">
          <h1>Destaques</h1>
          <div className="carsList">
            {usersList.map((player, i) =>
              player.anuncios.length > 0 && <ErrorBroundray key={i}><CardFeaturedPro name={player.name} avaliationStar={player.total} mainJob={player.anuncios} photo={player.photo} link={player._id} /></ErrorBroundray>
            )}
          </div>

          {usersList.length > 0 &&
            <ReactPaginate
              previousLabel={'Anteriores'}
              nextLabel={'Próximos'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              initialPage={currentPage - 1}
              onPageChange={(e) => setCurrentPage(e.selected + 1)}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />}
        </div>}
      </div>
      <ErrorBroundray><Navbar /></ErrorBroundray>

      <div style={{ marginTop: "50px" }}>
        <Footer />
      </div>
    </div>
  )
}

export default Announcement
