import React, { useState, useEffect, useLayoutEffect } from 'react'
import Helmet from 'react-helmet'
import './proposeDetail.scss'
// import { ButtonBack } from '../../../components/newButton/newButton'
import UserImg from "../../../assets/user_logo.svg"
import Loading from '../../../components/loading/loading'
import * as emailjs from 'emailjs-com'
import { gql, useMutation, useQuery } from "@apollo/client";
import { UpdatePropose, AcceptPropose, ChangeStatus } from '../../../queries/propose'
import { CreateNotification } from '../../../queries/notification'
import dayjs from 'dayjs'
import { clientSms } from '../../../queries/endpoint'
import Modal from '../../../components/Modal/modal'
import Input from "../../../components/newInput/input"
import Chat from '../../../components/chatWindow/chatWindow'
import Button from '../../../components/newButton/button'
import { Link } from 'react-router-dom'
import ErrorBoundary from '../../../components/errorBoundary/errorBoundary'
import Select from 'react-select'
import { Report, Notify } from "notiflix"
import DatePicker, { setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from 'date-fns/locale/pt-BR';
setDefaultLocale('pt-BR', ptBR)

const proposeQuery = gql`
query ProposeQuery($id: ID!){
    propose( id: $id ){
    id
    createdAt
    dateEnd
    dateEndF
    dateInit
    locationF
    dateInitF
    location
    description
    aceite
    anuncio {
        id
        skill {
        id
        name
        }
    }
    from{
        id
        name
        email
        photo
        type
        phone
        address {
        state
        city
        }
    }
    status
    timeEnd
    timeEndF
    timeInit
    timeInitF
    to{
        id
        name
        email
        photo
        phone
        type
        address {
        state
        city
        }
    }
    valueEnd
    valueInit
    ranks {
        from {
        id
        name
        }
    }
    }
}
`

export default () => {

    const cache = JSON.parse(sessionStorage.getItem('OITO'))
    const proposeId = window.location.pathname.split('/')[(window.location.pathname.split('/').length - 1)]
    const [saveNotification, { loading: loadingsaveNotification }] = useMutation(CreateNotification)
    const [acceptPropose, { loading: loadingacceptPropose }] = useMutation(AcceptPropose)
    const [updatePropose, { loading: loadingupdatePropose }] = useMutation(UpdatePropose)
    const [changeStatus, { loading: loadingchangeStatus }] = useMutation(ChangeStatus)
    const [showDetails, setShowDetails] = useState(false)
    const [otherUser, setOtherUser] = useState()
    const [user, setUser] = useState()
    const [tempoResposta, setTempoResposta] = useState()
    const [imPro, setImPro] = useState(false)
    const [blocked, setBlocked] = useState(false)
    const [ranked, setRanked] = useState(false)
    const [proposeStatus, setProposeStatus] = useState()
    const [noresponse, setNoresponse] = useState()
    const [valueEnd, setValueEnd] = useState()
    const [propose, setPropose] = useState()
    const [location, setLocation] = useState()
    const [description, setDescription] = useState()
    const tomorrow = new Date(dayjs(new Date()).add(1, 'day'))
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [selectOptions, setselectOptions] = useState()
    const [showConfirmation, setShowConfirmation] = useState(false)

    const { data, refetch, loading } = useQuery(proposeQuery, {
        variables: { id: proposeId }
    });

    const sendEmailNotification = (params) => {
        emailjs.send('noreply', 'default', params, "user_IagwdTtEr3AVRdLhxkyKc")
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text)
            }, function (error) {
                console.log('FAILED...', error);
            })
    }

    const sendSmsNotificaton = (phone, message) => {
        clientSms.sms.enviar(phone, message)
            .then(function (data) {
                // console.log(data)
                console.log("sms enviado")
            })
            .catch(function (error) {
                console.error('Erro: ', error)
            });
    }

    const createNotification = (data) => {
        saveNotification({
            variables: {
                userId: data.id,
                title: data.title,
                description: data.description,
                link: data.link
            }
        }).then(
            () => console.log("notificacao enviada")
        ).catch(
            err => console.log(err)
        )
    }

    const sendPropose = (e) => {
        e.preventDefault()

        const descricao = description !== propose.description ? propose.description + " --- " + description : propose.description

        updatePropose({
            variables: {
                id: proposeId,
                valueEnd: (valueEnd === "" || !valueEnd) ? "a combinar" : valueEnd,
                dateInitF: startDate.toISOString(),
                dateEndF: endDate.toISOString(),
                timeInitF: dayjs(startTime).toISOString(),
                timeEndF: dayjs(endTime).toISOString(),
                locationF: location,
                description: descricao
            }
        }).then(() => {
            setShowDetails(!showDetails)

            createNotification({
                id: user.id,
                title: "Proposta alterada!",
                description: "Aguarde o cliente aceitar sua proposta para fechar a negociação.",
                link: `/perfil/proposedetails/${proposeId}`,
            })

            createNotification({
                id: otherUser.id,
                title: "Proposta alterada!",
                description: "Entre e confira as alterações para aceitar e fechar a proposta.",
                link: `/perfil/proposedetails/${proposeId}`,
            })

            sendEmailNotification({
                "email": otherUser.email,
                "title": "Proposta alterada!",
                "name": otherUser.name,
                "message": "Entre e confira as alterações para aceitar e fechar a proposta."
            })

            sendSmsNotificaton(
                otherUser.phone.replace("+55", "").replace(/\D/g, ""),
                "Sua proposta foi alterada. Acesse Oito.app e verifique."
            )

            Report.Success(
                'Proposta alterada!',
                'Agora, aguarde o cliente aceitar as alterações.',
                'Fechar',
                () => refetch()
            )
        }).catch((err) => {
            console.log(err)
            Report.Failure(
                "Erro ao atualizar proposta.",
                "Algo aconteceu ao atualizar sua proposta. Tente novamente mais tarde.",
                "Fechar",
                () => window.location.reload()
            )
        })
    }

    const saveAcceptPropose = () => {
        acceptPropose({
            variables: {
                id: proposeId
            }
        }).then(
            res => {
                console.log(res)
                Notify.Success("Serviço aceito com sucesso.")
                setShowConfirmation(false)
                refetch()
            }
        ).catch((err) => {
            console.log(err)
            Report.Failure(
                "Erro ao acceitar proposta.",
                "Algo aconteceu ao atualizar a proposta. Tente novamente mais tarde.",
                "Fechar",
                () => window.location.reload()
            )
        })

    }

    const getTime = (data) => {
        if (data && !tempoResposta) {
            setTempoResposta(data)
        }
    }

    const formatTime = (time) => {
        if (dayjs(time).isValid()) {
            return dayjs(time).format("HH:mm")
        }

        return time
    }

    const formatDate = (date) => {
        if (dayjs(date).isValid()) {
            return dayjs(date).format("DD/MM/YYYY")
        }

        return date
    }

    useEffect(() => {
        if (!cache) {
            Report.Failure('Você não está logado!', 'Entre ou cadastre-se. É gratuito!', 'Fechar', () => window.location.href = '/login')
        } else {
            setUser(cache.user)
        }
    }, [])// eslint-disable-line

    useEffect(() => {

        if (proposeStatus && proposeStatus !== propose.status) {

            changeStatus({
                variables: {
                    id: proposeId,
                    status: proposeStatus
                }
            }).then(
                res => {

                    const otherUserphoneNumber = otherUser.phone.replace("+55", "").replace(/([\D])+/g, "")

                    switch (res.data.updatePropose.status) {

                        case "Serviço Finalizado com Sucesso":

                            createNotification({
                                id: user.id,
                                title: "Solicitação finalizada com Sucesso!",
                                description: `Avalie como foi negociar com ${otherUser.name}.`,
                                link: `/perfil/avaliacao/${proposeId}`
                            })

                            createNotification({
                                id: otherUser.id,
                                title: "Solicitação finalizada com Sucesso!",
                                description: "Entre e avalie como foi o serviço realizado.",
                                link: `/perfil/avaliacao/${proposeId}`
                            })

                            sendSmsNotificaton(otherUserphoneNumber, "Sua solicitação foi finalizada na Oito! Acesse oito.app e avalie o serviço realizado. Oito App")

                            sendEmailNotification({
                                "email": otherUser.email,
                                "title": "Solicitação finalizada!",
                                "name": otherUser.name,
                                "message": "Seu serviço foi finalizado. Entre e avalie como foi."
                            })

                            Report.Success(
                                'Solicitação finalizada com Sucesso!',
                                'Avalie como foi trabalhar para seu cliente.',
                                "Avaliar",
                                () => window.location.href = '/perfil/avaliacao/' + proposeId
                            )

                            break

                        case "Serviço Recusado":

                            createNotification({
                                id: otherUser.id,
                                title: "Solicitação recusada!",
                                description: "O profissional analisou a sua solicitação e recusou.",
                                link: `/perfil/proposedetails/${proposeId}`
                            })

                            sendSmsNotificaton(otherUserphoneNumber, "Sua solicitação foi recusada na Oito! Acesse Oito.app e procure outros profissionais para realizar seu serviço. Oito App")

                            sendEmailNotification({
                                "email": otherUser.email,
                                "title": "Solicitação recusada!",
                                "name": otherUser.name,
                                "message": "O profissional analisou a sua solicitação e recusou. Mas não se preocupe - é só voltar para a OITO e encontrar outro profissional disponível para fazer o trabalho."
                            })

                            Report.Success(
                                'Solicitação recusada!',
                                'Você recusou a solicitação. Não será mais possível alterá-la. Avalie como foi o contato com o cliente.',
                                "Avaliar",
                                () => window.location.href = '/perfil/avaliacao/' + proposeId + '?noresponse'
                            )

                            break

                        case "Serviço Aceito":
                            createNotification({
                                id: otherUser.id,
                                title: "Solicitação aceita!",
                                description: "Agora é só aguardar que o trabalho seja feito.",
                                link: `/perfil/proposedetails/${proposeId}`
                            })

                            sendSmsNotificaton(otherUserphoneNumber, "Sua solicitação foi aceita na Oito! Agora é só aguardar que o trabalho seja feito. Oito App")

                            //enviar email
                            sendEmailNotification({
                                "email": otherUser.email,
                                "title": "Solicitação aceita!",
                                "name": otherUser.name,
                                "message": "A sua solicitação foi analisada pelo profissional e ele fechou com você! Agora é só aguardar que o trabalho seja feito e avaliar o desempenho através da Oito."
                            })

                            Report.Success(
                                'Solicitação aceita!',
                                'Não se esqueça de realizar o serviço como combinado.',
                                "Fechar",
                                () => refetch()
                            )

                            break

                        case "Serviço Aberto para Negociação":
                            createNotification({
                                id: otherUser.id,
                                title: "Solicitação aberto para negociação!",
                                description: "Sua solicitação está aberta para negociação.",
                                link: `/perfil/proposedetails/${proposeId}`
                            })

                            sendSmsNotificaton(otherUserphoneNumber, "Sua solicitação está aberta para negociação na Oito! Acesse oito.app e feche negócio o quanto antes. Oito App")

                            sendEmailNotification({
                                "email": otherUser.email,
                                "title": "Solicitação aberta para negociação!",
                                "name": otherUser.name,
                                "message": "A sua solicitação foi analisada pelo profissional, foi aceita e agora está aberta para negociação. Entre em contato com o profissional através da Oito e acerte os detalhes para fechar o trabalho!"
                            })

                            Report.Success(
                                'Solicitação aberta para negociação!',
                                'Negocie com seu cliente as condições perfeitas para as duas partes.',
                                "Fechar",
                                () => refetch()
                            )

                            break

                        case "Serviço Cancelado":
                            createNotification({
                                id: otherUser.id,
                                title: "Serviço cancelado!",
                                description: "Por algum motivo sua solicitação foi cancelada. Avalie como foi a negociação.",
                                link: `/perfil/avaliacao/${proposeId}`
                            })

                            sendSmsNotificaton(otherUserphoneNumber, "Sua solicitação foi cancelada na Oito! Acesse oito.app e procure outros profissionais para realizar seu serviço. Oito App")

                            //enviar email
                            sendEmailNotification({
                                "email": otherUser.email,
                                "title": "Solicitação cancelada!",
                                "name": otherUser.name,
                                "message": "A sua solicitação foi cancelada! Abra outra pela Oito e continue procurando o profissional perfeito para o trabalho."
                            })

                            Report.Success(
                                'Solicitação cancelada!',
                                'Você cancelou a solicitação. Avalie como foi o contato com o cliente.',
                                "Avaliar",
                                () => window.location.href = '/perfil/avaliacao/' + proposeId + '?noresponse'
                            )

                            break

                        case "Serviço Não Realizado":
                            let form = {}

                            if (imPro) {
                                form.profId = user.id
                                form.profName = cache.user.name
                                form.profEmail = cache.user.email
                                form.profPhone = cache.user.phone
                                form.cliId = otherUser.id
                                form.cliName = otherUser.name
                                form.cliEmail = otherUser.email
                                form.cliPhone = otherUser.phone
                            } else {
                                form.cliId = user.id
                                form.cliName = cache.user.name
                                form.cliEmail = cache.user.email
                                form.cliPhone = cache.user.phone
                                form.profId = otherUser.id
                                form.profName = otherUser.name
                                form.profEmail = otherUser.email
                                form.profPhone = otherUser.phone
                            }

                            createNotification({
                                id: form.profId,
                                title: "Solicitação encerrada!",
                                description: `Você não respondeu e a sua solicitação foi encerrada. Avalie como foi negociar com ${form.cliName}.`,
                                link: `/perfil/avaliacao/${proposeId}?noresponse`
                            })

                            createNotification({
                                id: form.cliId,
                                title: "Solicitação encerrada!",
                                description: `Você não respondeu e a sua solicitação foi encerrada. Avalie como foi negociar com ${form.profName}.`,
                                link: `/perfil/avaliacao/${proposeId}?noresponse`
                            })

                            sendSmsNotificaton(otherUserphoneNumber, "Você não respondeu e a sua solicitação foi encerrada na Oito. Acesse oito.app e não deixe de responder para não ficar com avaliação baixa. Oito App")

                            //enviar email
                            sendEmailNotification({
                                "email": imPro ? form.cliEmail : form.profEmail,
                                "title": "Solicitação encerrada!",
                                "name": imPro ? form.cliName : form.profName,
                                "message": imPro ? "Você não respondeu o profissional e a sua solicitação foi encerrada. Você pode abrir outra pela Oito, mas fique de olho no contato para não perder mais uma oportunidade ou ficar com a avaliação baixa." : "Você não respondeu o cliente em tempo e a sua solicitação foi encerrada. Você pode receber outras solicitação pela Oito, mas fique de olho no contato para não perder mais uma oportunidade ou ficar com a avaliação baixa."
                            })

                            Report.Success(
                                'Solicitação encerrada!',
                                `Avalie o ${imPro ? "cliente" : "profissional"}.`,
                                "Avaliar",
                                () => window.location.href = '/perfil/avaliacao/' + proposeId + '?noresponse'
                            )

                            break

                        default:
                            console.log(res)
                    }

                    refetch()
                }
            ).catch(err => {
                console.log(err)
                Report.Failure(
                    "Erro ao atualizar o status da solicitação.",
                    "Algo aconteceu. Tente novamente mais tarde",
                    "Fechar",
                    () => window.location.reload()
                )
            })
        }
    }, [proposeStatus])// eslint-disable-line

    useLayoutEffect(() => {
        if (data) {
            setPropose(data.propose)

            setLocation(data.propose.location)
            setDescription(data.propose.description)
            setValueEnd(data.propose.valueInit)
            setStartDate(new Date(data.propose.dateInit))
            setEndDate(new Date(data.propose.dateEnd))

            if (data.propose.timeInit) {
                let tempoInit = new Date()
                tempoInit = tempoInit.setHours(parseInt(data.propose.timeInit.split(":")[0]))
                tempoInit = new Date(tempoInit)
                tempoInit = tempoInit.setMinutes(parseInt(data.propose.timeInit.split(":")[1]))
                setStartTime(tempoInit)
            }

            if (data.propose.timeEnd) {
                let tempoEnd = new Date()
                tempoEnd = tempoEnd.setHours(parseInt(data.propose.timeEnd.split(":")[0]))
                tempoEnd = new Date(tempoEnd)
                tempoEnd = tempoEnd.setMinutes(parseInt(data.propose.timeEnd.split(":")[1]))
                setEndTime(tempoEnd)
            }
        }
    }, [data])

    useLayoutEffect(() => {
        if (propose) {

            if (propose.from.id === user.id) {
                setOtherUser(propose.to)
            } else {
                setOtherUser(propose.from)
                setImPro(true)
            }

            if (propose.status === "Serviço Não Realizado") setNoresponse(true)

            if (propose.status === "Serviço Não Realizado" ||
                propose.status === "Serviço Recusado" ||
                propose.status === "Serviço Cancelado" ||
                propose.status === "Serviço Finalizado com Sucesso") {

                propose.ranks.map(user => (
                    (user.from.id === user.id) &&
                    !ranked && setRanked(true)
                ))

                if (!blocked) setBlocked(true)
            }

            let selectOptionsList = [
                { value: propose.status, label: propose.status },
                { value: "Serviço Não Realizado", label: "Serviço Não Realizado" },
            ]

            if (imPro) {


                if (propose.status === "Serviço Aberto para Negociação") {
                    selectOptionsList = [
                        ...new Set([...selectOptionsList, { value: "Serviço Recusado", label: "Serviço Recusado" }])
                    ]

                    selectOptionsList = [
                        ...new Set([...selectOptionsList, { value: "Serviço Aceito", label: "Serviço Aceito" }])
                    ]
                }

                if (propose.status === "Serviço Aberto para Negociação" || propose.status === "Serviço Aceito") {
                    selectOptionsList = [
                        ...new Set([...selectOptionsList, { value: "Serviço Cancelado", label: "Serviço Cancelado" }])
                    ]

                    selectOptionsList = [
                        ...new Set([...selectOptionsList, { value: "Serviço Finalizado com Sucesso", label: "Serviço Finalizado com Sucesso" }])
                    ]
                }
            }

            setselectOptions(selectOptionsList)

            if (!propose.aceite && (
                propose.valueEnd || propose.dateInitF || propose.dateEndF ||
                propose.timeInitF || propose.timeEndF || propose.locationF
            )) {
                setShowConfirmation(true)
            }

        }
    }, [propose, imPro])// eslint-disable-line

    const ProposeData = () => {
        return <div className="col-xs-12">
            <div className="row center-xs">
                <div className={'chat col-xs-12 col-sm-8'}>
                    <ErrorBoundary><Chat propose={propose} blocked={blocked} otherUser={otherUser} player={user} callbackFunctions={getTime.bind(this)} /></ErrorBoundary>
                </div>

                <div className={'info col-xs-12 col-sm-4'}>
                    <div className='header'>
                        <Link to={imPro ? `/profileClient/${otherUser.id}` : `/profile/${otherUser.id}`} >
                            <figure><img src={otherUser.photo ? otherUser.photo : UserImg} alt={otherUser.name} /></figure>
                            <span>{otherUser.name}</span>
                        </Link>
                    </div>

                    <div className={'content'}>

                        {blocked ?
                            !ranked &&
                            <p><Link to={!noresponse ? `/perfil/avaliacao/${proposeId}` : `/perfil/avaliacao/${proposeId}?noresponse`}>
                                <Button type="button" name={!noresponse ? "Avaliar serviço" : imPro ? "Avaliar cliente" : "Avaliar profissional"} />
                            </Link></p> :
                            <p>{(imPro && !propose.aceite) ?
                                <Button type="button" disabled={blocked} onClick={() => setShowDetails(!showDetails)} name="Alterar proposta" /> :
                                (!propose.aceite && propose.valueEnd) && <Button type="button" disabled={blocked} name="Aceitar proposta" />}
                            </p>}

                        <span style={{ display: "flex", alignItems: 'center', margin: '10px 0' }}>
                            <svg style={{ width: '24px', height: '24px', marginRight: '10px' }} viewBox="0 0 24 24">
                                <path fill="#5f5f5f" d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" />
                            </svg>

                            {propose.aceite ?
                                ((formatDate(propose.dateInitF) === formatDate(propose.dateEndF) || propose.dateEndF === null) ?
                                    `${formatDate(propose.dateInitF)}` :
                                    `${formatDate(propose.dateInitF)} - ${formatDate(propose.dateEndF)}`) :
                                ((formatDate(propose.dateInit) === formatDate(propose.dateEnd) || propose.dateEnd === null) ?
                                    `${formatDate(propose.dateInit)}` :
                                    `${formatDate(propose.dateInit)} - ${formatDate(propose.dateEnd)}`)
                            }

                            {((!propose.aceite && propose.valueEnd) && (formatDate(propose.dateInit) !== formatDate(propose.dateInitF) || formatDate(propose.dateEnd) !== formatDate(propose.dateEndF))) &&
                                <span>  / <span className='changed'>
                                    {(formatDate(propose.dateInitF) === formatDate(propose.dateEndF) || propose.dateEndF === null) ?
                                        `${formatDate(propose.dateInitF)}` :
                                        `${formatDate(propose.dateInitF)} - ${formatDate(propose.dateEndF)}`}</span></span>
                            }
                        </span>

                        <span style={{ display: "flex", alignItems: 'center', margin: '10px 0', color: '#13b323' }}>
                            <svg style={{ width: '24px', height: '24px', marginRight: '10px' }} viewBox="0 0 24 24">
                                <path fill="#13b323" d="M3,6H21V18H3V6M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M7,8A2,2 0 0,1 5,10V14A2,2 0 0,1 7,16H17A2,2 0 0,1 19,14V10A2,2 0 0,1 17,8H7Z" />
                            </svg>

                                            R$ {propose.aceite ? propose.valueEnd : (propose.valueInit === null ? "--" : propose.valueInit)}
                            {((!propose.aceite && propose.valueEnd) && (propose.valueInit !== propose.valueEnd)) &&
                                <span>  / <span className='changed'>R$ {propose.valueEnd}</span></span>
                            }
                        </span>

                        <span style={{ display: "flex", alignItems: 'center', margin: '10px 0' }}>
                            <svg style={{ width: '22px', height: '22px', marginRight: '10px' }} viewBox="0 0 24 24">
                                <path fill="#5f5f5f" d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
                            </svg>

                            {propose.aceite ? `${formatTime(propose.timeInitF)} - ${formatTime(propose.timeEndF)}` : propose.timeInit !== null && propose.timeEnd !== null && `${formatTime(propose.timeInit)} - ${formatTime(propose.timeEnd)}`}

                            {((!propose.aceite && propose.valueEnd) && (formatTime(propose.timeInit) !== formatTime(propose.timeInitF) || formatTime(propose.timeEnd) !== formatTime(propose.timeEndF)) && (propose.timeInit !== null && propose.timeEnd !== null)) &&
                                <span>  / <span className='changed'>{formatTime(propose.timeInitF)} - {formatTime(propose.timeEndF)}</span></span>
                            }
                        </span>

                        <span style={{ display: "flex", alignItems: 'center', margin: '10px 0' }}>
                            <svg style={{ width: '24px', height: '24px', marginRight: '10px' }} viewBox="0 0 24 24">
                                <path fill="#5f5f5f" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                            </svg>

                            {propose.aceite ? propose.valueEnd : (propose.location === null ? "--" : propose.location)}
                            {((!propose.aceite && propose.locationF) && (propose.locationF !== propose.location)) &&
                                <span>  / <span className='changed'>{propose.locationF}</span></span>}
                        </span>


                        <h3>Descrição</h3>
                        <p>{propose.description}</p>

                        {selectOptions && <div>
                            <Select options={selectOptions} onChange={(e) => setProposeStatus(e.value)} isDisabled={blocked} defaultValue={selectOptions[0]} />
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    }

    if (loadingsaveNotification ||
        loadingacceptPropose ||
        loadingupdatePropose ||
        loadingchangeStatus ||
        loading) return <Loading />

    return (
        <div className="col-xs-12 propose-detail " style={{ maxWidth: '980px' }}>
            <Helmet>
                {/* <title>Oito - Detalhe da Solicitação</title> */}
                <meta name="title" content="Oito.app | Confira detalhes das solicitações recebidas em Oito.app" />
                <meta name="description" content="Negocie diretamente com seu cliente ou prestador de serviços. Confira os detalhes das solicitações, entenda as condições e dê sequência à negociação" />
                <meta name="keywords" content="Negociação, Solicitações, Oportunidades" />
                <meta name="robots" content="index, follow" />
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="Portuguese" />
                <meta name="revisit-after" content="1 days" />
            </Helmet>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: "left" }}>
                    <h1 style={{ margin: '5px 0' }}>Chat</h1>
                    <span>Negocie diretamente com o {imPro ? "cliente" : "profissional"}</span>
                    {tempoResposta && <p style={{ textAlign: "right", }}><small>tempo médio de resposta: {parseInt(tempoResposta)} minutos</small></p>}
                </div>
                
                {(propose && otherUser) ? < ProposeData /> : <Loading />}

                <Modal show={showDetails} onClose={() => setShowDetails(!showDetails)} title='Detalhes'>
                    <p style={{ textAlign: "left" }}>Antes de fechar negócio, confirme os detalhes.</p>

                    <form id="formpropose" className="row middle-xs" onSubmit={(e) => sendPropose(e)}>
                        <div className="col-xs-12">
                            <Input name="valueEnd" label='Valor fechado' placeholder={"Valor fechado"} type="text" money={valueEnd !== "a combinar" && "true"} value={valueEnd} onChange={(e) => setValueEnd(e.target.value)} />
                        </div>

                        <div className="col-xs-12 start-xs">
                            Data:
                        </div>

                        <div className="col-xs-12 col-sm-6" style={{ marginTop: '25px' }}>

                            <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={tomorrow}
                                endDate={endDate}
                                minDate={tomorrow}
                                locale={ptBR}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Selecione uma data inicial"
                                className="input-date-picker"
                            />

                        </div>

                        <div className="col-xs-12 col-sm-6" style={{ marginTop: '25px' }}>
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={tomorrow}
                                endDate={endDate}
                                minDate={startDate || tomorrow}
                                locale={ptBR}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Selecione uma data final"
                                className="input-date-picker"
                            />
                        </div>

                        <div className="col-xs-12 start-xs">
                            Horário:
                        </div>

                        <div className="col-xs-12 col-sm-6" style={{ marginTop: '25px' }}>
                            <DatePicker
                                selected={startTime}
                                onChange={date => setStartTime(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                placeholderText="Selecione uma hora inicial"
                                className="input-date-picker"
                            />

                        </div>

                        <div className="col-xs-12 col-sm-6" style={{ marginTop: '25px' }}>
                            <DatePicker
                                selected={endTime}
                                onChange={date => setEndTime(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                placeholderText="Selecione uma hora final"
                                className="input-date-picker"
                            />
                            {/* <Input icon="far fa-clock" name="timeEnd" label="Horário de encerramento" placeholder="Horário de encerramento. Ex. 23:00" type="text" onInput={this.setInput} onKeyUp={this.timeValue} value={this.form.timeEnd} maxlength={6} /> */}
                        </div>

                        <div className="col-xs-12">
                            <Input name="location" placeholder="Local. Ex.: Cidade - Estado" type="text" onChange={(e) => setLocation(e.target.value)} value={location} maxlength={100} />
                        </div>

                        <div className="col-xs-12">
                            <Input name="description" placeholder="Digite aqui informações adicionais" type="text" onChange={(e) => setDescription(e.target.value)} value={description} maxlength={254} />
                        </div>

                        <div className="col-xs-12">

                            <Button
                                name='Enviar solicitação'
                                type='submit'
                            // disabled={!dateok}
                            />
                        </div>
                    </form>
                </Modal>

                {propose && !propose.aceite && <Modal show={showConfirmation} onClose={() => setShowConfirmation(!showConfirmation)} title='Detalhes da proposta alterados'>
                    <p>Sua solicitação foi alterada pelo profissional.</p>
                    <p>Confira as alterações e, se estiver de acordo, clique em "Aceitar".</p>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Proposto</th>
                                <th scope="col">Solicitado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Valor</th>
                                <td className='tabletext'>{propose.valueInit}</td>
                                <td className={propose.valueInit === propose.valueEnd ? 'tabletext' : 'changed'}>
                                    {propose.valueEnd}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Data</th>
                                <td className='tabletext'>
                                    {formatDate(propose.dateInit) === formatDate(propose.dateEnd) ?
                                        formatDate(propose.dateInit) : formatDate(propose.dateInit) + " - "
                                        + formatDate(propose.dateEnd)}</td>
                                <td className={(formatDate(propose.dateInit) === formatDate(propose.dateInitF) &&
                                    formatDate(propose.dateEnd) === formatDate(propose.dateEndF)) ? 'tabletext' : 'changed'}>
                                    {formatDate(propose.dateInitF) === formatDate(propose.dateEndF) ?
                                        formatDate(propose.dateInitF) :
                                        formatDate(propose.dateInitF) + " - " + formatDate(propose.dateEndF)}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Horário</th>
                                <td className='tabletext'>
                                    {formatTime(propose.timeInit)} - {formatTime(propose.timeEnd)}</td>
                                <td className={(formatTime(propose.timeInit) === formatTime(propose.timeInitF) &&
                                    formatTime(propose.timeEnd) === formatTime(propose.timeEndF)) ? 'tabletext' : 'changed'}>
                                    {formatTime(propose.timeInitF)} - {formatTime(propose.timeEndF)}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Local</th>
                                <td className='tabletext'>{propose.location === null ? "" : propose.location}</td>
                                <td className={(propose.location === propose.locationF) ? 'tabletext' : 'changed'}>
                                    {propose.locationF === null ? "" : propose.locationF}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="aceiteActions">
                        <Button type="button" onClick={() => setShowConfirmation(!showConfirmation)} style={{ backgroundColor: "transparent", color: "#ff8000", marginRight: "20px" }} name="Continuar a negociar" />
                        <Button type="button" onClick={() => saveAcceptPropose()} style={{ backgroundColor: "green", color: "white", fontWeight: "600" }} name="Aceito" />
                    </div>
                </Modal>}
            </div>
        </div >
    )
}