import React, { useState, useEffect } from "react"
import './calendar.scss'
import { Link } from 'react-router-dom'
import { ProposesCalendar } from '../../queries/propose'
import MiniLoading from '../../components/miniloading/miniloading'
import { useQuery } from "@apollo/client"

const Calendar = () => {
    const cache = JSON.parse(sessionStorage.getItem('OITO'))
    const { data, loading } = useQuery(ProposesCalendar, {
        variables: {
            id: cache && cache.user.id
        }
    })
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
    const [days, setDays] = useState([])
    const [monthName, setMonthName] = useState()
    const [daySelect, setDaySelect] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
    const [events, setEvents] = useState([])

    useEffect(() => {
        if (data) {
            setEvents(data.from.concat(data.to))
        }
    }, [data])

    const backOneMonth = () => {
        let month = currentMonth,
            nameMonth;

        if (month === 0) {
            month = 11;

            return backOneYear(month)
        } else {
            month = month - 1
        }

        nameMonth = new Date(2019, month + 1, 0).toLocaleString('pt-br', { month: 'long' });
        setCurrentMonth(month)
        setMonthName(nameMonth)
    };

    const forwardOneMonth = () => {
        let month = currentMonth,
            nameMonth;

        if (month === 11) {
            month = 0;

            return forwardOneYear(month)
        } else {
            month = month + 1
        }

        nameMonth = new Date(2019, month + 1, 0).toLocaleString('pt-br', { month: 'long' });
        setCurrentMonth(month)
        setMonthName(nameMonth)
    };

    const backOneYear = (month) => {
        setCurrentYear(currentYear - 1)
        setCurrentMonth(month)
    };

    const forwardOneYear = (month) => {
        setCurrentYear(currentYear + 1);
        setCurrentMonth(month)
    };

    const listEvents = (list) => {
        let arrayDays = list

        if (events && events.length > 0) {
            events.map((obj) => {
                const day = new Date(obj.dateInitF ? obj.dateInitF : obj.dateInit).getTime();
                const month = new Date(obj.dateInitF ? obj.dateInitF : obj.dateInit).getMonth() +1;
                
                if (month === currentMonth + 1) {
                    
                    arrayDays.map(checkedDay => {

                        if (new Date(checkedDay.date).getTime() === day) {
                            
                            checkedDay.hasEvent = true;
                            checkedDay.url = '/perfil/proposedetails/' + obj.id;
                            checkedDay.titulo = obj.anuncio ? obj.anuncio.skill.name : obj.skill;
                        }

                        return null
                    })
                    
                }

                return null
            })
        }
        setDays(arrayDays)
    }

    useEffect(() => {
        const month = currentMonth
        const year = currentYear
        let newCurrentMonth = month + 1;

        let baseDate = new Date(year, newCurrentMonth, 0),
            firstDay = new Date(year, month, 1).getDay(),
            lastDay = baseDate.getDate(),
            nameMonth = new Date(year, month, 1).toLocaleString('pt-br', { month: 'long' }),
            allDays = [],
            day, extraDays;

        // console.log(firstDay);

        for (day = 0; day < lastDay; day++) {
            let createdDay = day + 1,
                date = { date: new Date(year, month, createdDay), selected: false, hasEvent: false };

            allDays.push(date);
        }

        for (extraDays = 0; extraDays < firstDay; extraDays++) {
            let extraDate = { date: '' };

            allDays.unshift(extraDate);
        }

        setMonthName(nameMonth)
        listEvents(allDays)

    }, [currentYear, currentMonth, events]) // eslint-disable-line


    return (
        <div className={'calendar'}>
            <div className={'header'}>
                
                <div className={'btnArrow'} onClick={backOneMonth}>
                    <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                        <path fill="#5f5f5f" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                    </svg>
                </div>

                <div><h4>{monthName} - {currentYear}</h4></div>

                <div className={'btnArrow'} onClick={forwardOneMonth}>
                    <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                        <path fill="#5f5f5f" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                    </svg>
                </div>

                {loading && <MiniLoading />}
            </div>

            <div className={'days'}>
                <div><span>Dom</span></div>
                <div><span>Seg</span></div>
                <div><span>Ter</span></div>
                <div><span>Qua</span></div>
                <div><span>Qui</span></div>
                <div><span>Sex</span></div>
                <div><span>Sab</span></div>
            </div>

            <div className={'list'}>

                {days.map((obj, i) =>
                    <div key={i} onClick={() => setDaySelect(obj.date)} className={new Date(daySelect).getTime() === new Date(obj.date).getTime() ? 'active' : ""}>
                        
                        <span>{obj.date && new Date(obj.date).getDate()}</span>

                        {obj.hasEvent &&
                            <div className={'event'}>
                                <Link to={obj.url}><span>{obj.titulo}</span></Link>
                            </div>}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Calendar