import React, { Fragment, useState, useEffect } from "react"
import './announcementSearch.scss'

import Navbar from '../../components/newNavbar/navbar'
import Loading from '../../components/loading/loading'
import CardFeatured from '../../components/cardFeatured/cardFeatured'
import CardFeaturedPro from '../../components/cardFeaturedPro/cardFeaturedPro'
import Input from '../../components/newInput/input'
import Footer from '../../components/newFooter/footer'
import Back from '../../components/backButton/backButton'
import { endpointRest } from '../../queries/endpoint'
import ErrorBroundray from '../../components/errorBoundary/errorBoundary'
import ReactPaginate from 'react-paginate'
import 'react-paginate/demo/styles/style.css'
import axios from "axios"
import ChipWord from '../../components/chipWord/chipWord'

const AnnouncementSearch = () => {

  const [loading, setLoading] = useState(true)
  const [anunciosList, setAnunciosList] = useState([])
  const [usersList, setUsersList] = useState([])
  const [query, setQuery] = useState(decodeURI(window.location.pathname.split('busca/')[(window.location.pathname.split('busca/').length - 1)].replace('+', '/')))
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [pageSize] = useState(parseInt(window.innerWidth / 280))
  const [currentPageUser, setCurrentPageUser] = useState(1)
  const [pageCountUser, setPageCountUser] = useState(1)
  const [totalAnuncios, setTotalAnuncios] = useState(0)
  const [totalProfissional, setTotalProfissional] = useState(0)
  const [skillsList, setSkillsList] = useState([])
  const [totalSkills, setTotalSkills] = useState(0)

  const getAnuncios = (params) => {

    return new Promise((resolve, reject) => {
      axios.post(endpointRest + "advertisements",
        params
      ).then(
        res => {

          const anuncios = res.data.data

          let totalPages = 1

          if ((res.data.totalFounded % res.data.pageSize) > 0) {
            totalPages = parseInt(res.data.totalFounded / res.data.pageSize) + 1
          } else {
            totalPages = parseInt(res.data.totalFounded / res.data.pageSize)
          }

          if (anuncios && anuncios.length > 0) {
            anuncios.map(item => {

              if (item.user && item.user.ranks) {

                let count = 0

                item.user.ranks.map(med =>
                  med.typeTo === "PROFISSIONAL" ?
                    count = count + med.media : count
                )

                return item.total = (count / (item.user.ranks.length))
              }

              return null
            })
          }

          return resolve({
            anuncios,
            currentPage: res.data.currentPage,
            pageCount: totalPages,
            loading: false,
            total: res.data.totalFounded
          })

        })
    })


  }

  const getProfissionais = (params) => {

    return new Promise((resolve, reject) => {
      axios.post(endpointRest + "users",
        params
      ).then(
        res => {

          const profissionais = res.data.data

          let totalPages = 1

          if ((res.data.totalFounded % res.data.pageSize) > 0) {
            totalPages = parseInt(res.data.totalFounded / res.data.pageSize) + 1
          } else {
            totalPages = parseInt(res.data.totalFounded / res.data.pageSize)
          }

          return resolve({
            profissionais,
            currentPage: res.data.currentPage,
            pageCount: totalPages,
            loading: false,
            total: res.data.totalFounded
          })

        })
    })


  }

  const getAbility = (params) => {

    return new Promise((resolve, reject) => {
      axios.post(endpointRest + "skills",
        params
      ).then(
        res => {

          let totalPages = 1

          if ((res.data.totalFounded % res.data.pageSize) > 0) {
            totalPages = parseInt(res.data.totalFounded / res.data.pageSize) + 1
          } else {
            totalPages = parseInt(res.data.totalFounded / res.data.pageSize)
          }

          return resolve({
            skills: res.data.data,
            currentPage: res.data.currentPage,
            pageCount: totalPages,
            loading: false,
            total: res.data.totalFounded
          })

        })
    })


  }

  useEffect(() => {
    const LastURL = JSON.parse(sessionStorage.getItem('LastURL'));
    if (LastURL) {
      window.location.href = LastURL.perfil
    }
  }, [])

  useEffect(() => {
    if (query !== undefined && query.length > 2) {

      const paramsAbility = {
        filter: {
          name: { "$regex": ".*" + query + ".*", "$options": "i" },
        },
        pageSize: null,
        currentPage: null
      }

      getAbility(paramsAbility).then(res => {
        setSkillsList(res.skills)
        setTotalSkills(res.total)
      })

      const paramsDescription = {
        filter: {
          description: { "$regex": ".*" + query + ".*", "$options": "i" },
          active: { "$ne": false },
        },
        pageSize: pageSize.toString(),
        currentPage: currentPage.toString()
      }

      getAnuncios(paramsDescription).then(res => {
        setAnunciosList(res.anuncios)
        setCurrentPage(res.currentPage)
        setPageCount(res.pageCount)
        setLoading(res.loading)
        setTotalAnuncios(res.total)
      })

      const paramsUsers = {
        filter: {
          name: { "$regex": ".*" + query + ".*", "$options": "i" },
          status: { "$ne": false },
          type: "PROFISSIONAL"
        },
        pageSize: pageSize.toString(),
        currentPage: currentPageUser.toString()
      }

      getProfissionais(paramsUsers).then(res => {
        setUsersList(res.profissionais)
        setCurrentPageUser(res.currentPage)
        setPageCountUser(res.pageCount)
        setLoading(res.loading)
        setTotalProfissional(res.total)
      })

    }
  }, [query, currentPage, currentPageUser, pageSize])

  return (
    <Fragment>
      <div className={'AnnouncementService col-xs-12'}>
        <div className="row center-xs">
          <div className="col-xs-12" style={{ maxWidth: '600px' }}><Input placeholder={'Pesquisar...'} name={'search'} onInput={e => setQuery(e.target.value)} /></div>
        </div>

        {loading ? <Loading /> : null}

        <div className="row start-xs">
          <Back />

        </div>

        <div className="row">
          <div className="col-xs-12">
            <h3>{totalSkills} Serviços encontrados com {query}</h3>
          </div>

          <div className="col-xs-12">

            <div className={'sideSlide col-xs-12'}>
              <div className="row around-xs center-xs">
                {skillsList.length > 0 ? skillsList.map((skill, i) =>
                  <ErrorBroundray key={i}><ChipWord name={skill.name} onClick={() => window.location.href = `/anuncios/${skill._id}`} /></ErrorBroundray>
                ) : <h4>Nenhum anúncio encontrado para está pesquisa.</h4>}

              </div>
            </div>
          </div>

          <div className="col-xs-12">
            <h3>{totalAnuncios} Anúncios encontrados com {query}</h3>
          </div>

          <div className="col-xs-12">

            <div className={'sideSlide col-xs-12'}>
              <div className="row around-xs center-xs">
                {anunciosList.length > 0 ? anunciosList.map((anun, i) =>
                  <ErrorBroundray key={i}>
                    <CardFeatured description={anun.description} avaliationStar={anun.total} mainJob={anun} salary={anun.price} photo={anun.files && anun.files.length > 0 ? anun.files : anun.user.photo} link={anun.userId + '?'} />
                  </ErrorBroundray>
                ) : <h4>Nenhum anúncio encontrado para está pesquisa.</h4>}

              </div>
            </div>

            {anunciosList.length > 0 &&
              <div className="col-xs-12">
                <ReactPaginate
                  previousLabel={'Anteriores'}
                  nextLabel={'Próximos'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  initialPage={currentPage - 1}
                  onPageChange={(e) => setCurrentPage(e.selected + 1)}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            }
          </div>

          <div className="col-xs-12">
            <h3>{totalProfissional} Profissionais encontrados com {query}</h3>
          </div>

          <div className="col-xs-12">

            <div className={'sideSlide col-xs-12'}>
              <div className="row around-xs center-xs">
                {usersList.length > 0 && usersList.map((player, i) => (
                  <ErrorBroundray key={i}>
                    <CardFeaturedPro name={player.name} photo={player.photo} link={player._id} mainJob={player.anuncios} />
                    </ErrorBroundray>
                ))}

              </div>
            </div>

            {usersList.length > 0 &&
              <div className="col-xs-12">
                <ReactPaginate
                  previousLabel={'Anteriores'}
                  nextLabel={'Próximos'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCountUser}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  initialPage={currentPageUser - 1}
                  onPageChange={(e) => setCurrentPageUser(e.selected + 1)}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            }
          </div>
        </div>
      </div>

      <ErrorBroundray><Navbar /></ErrorBroundray>
      <div style={{ marginTop: "50px" }}>
        <Footer />
      </div>
    </Fragment >
  )
}

export default AnnouncementSearch
