import React, { Component } from "react";
import { Link } from 'react-router-dom'
import './activityCard.scss'

// import IconCtg from "../iconCtg/iconCtg"
export default class ActivityCard extends Component {
    render() {
        const {
            catg,
            date,
            status,
            link,
        } = this.props;
        return (
            <Link to={{ pathname: '/perfil/proposedetails/' + link }} className="col-xs-12">
                <div className={'activityCard col-xs-12'}>
                    <div className={'activityInfo row between-xs'}>
                        <div className='col-xs-8 col-sm-5' style={{ textAlign: 'left'}}>
                            {/* <IconCtg /> */}
                            <h1>{catg}</h1>
                        </div>
                        <div className='col-xs-4 col-sm-2'><span>{date}</span></div>
                        <div className='col-xs-12 col-sm-5' style={{ textAlign: 'right'}}><span>{status}</span></div>
                    </div>
                </div>
            </Link>
        )
    }
}
