import React from "react"
import './cardFeatured.scss'

import { Link } from 'react-router-dom'
import AvaliationStar from '../avaliationStars/avaliationStars'
// import '../../../node_modules/react-id-swiper/lib/utils/ per/lib/styles/scss/swiper.scss'
import Swiper from 'react-id-swiper'
import 'swiper/swiper.scss'

import userLogo from '../../assets/user_logo.svg'

export default class CardFeatured extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const {
            description, photo, mainJob, salary, avaliationStar, link
        } = this.props;

        const params = {
            autoplay: {
                delay: 1500,
                disableOnInteraction: false
            },
        }

        return (
            <Link to={"/profile/" + link} >
                <div className={'cardFeatured'}>
                    <div className={'imgContainer'}>
                        {photo ? typeof photo === "object" ?
                            <Swiper {...params}>
                                {photo.map((url, i) => (
                                    <div key={i}><img src={url} alt={description} className="imgslider" /></div>
                                ))}
                            </Swiper>
                            :
                            <img src={photo} alt={description} /> : //se nao tem slider tem foto de perfil
                            <img src={userLogo} alt={description} /> //se nao tem nada
                        }

                    </div>

                    <div className={'starscard'}>
                        <AvaliationStar avaliation={avaliationStar ? avaliationStar : 0} />
                    </div>

                    <div className={'contentAnun'}>
                        <h1>{description ? description.length > 75 ? description.substr(0, 75) + "..." : description : 'Anuncio'}</h1>
                        <div>{mainJob ? mainJob.user.anuncios.map((s, i) => s.active ? <span className="tag" key={i}>{s.skill.name}</span> : null) : null}</div>
                        {/* {mainJob.player.anuncioses.map(s => console.log(s.skills.name))} */}
                        {/* {console.log(mainJob.player.anuncioses.map(s => console.log(s.skills.name)))} */}
                        {/* {console.log(salary)} */}
                        <span>R$ {salary ? salary : 'Custo/Hora'}</span>
                    </div>
                </div>
            </Link>
        )
    }
}
